var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g-error-base", {
    attrs: {
      color: "error",
      icon: "mdi-alert-circle-outline",
      title: _vm.title,
      subtitle: _vm.subtitle,
      link: false,
      "link-text": _vm.$t("general.go_back"),
      "link-icon": "mdi-chevron-left",
    },
    on: { "click:link": _vm.onGoBack },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }