var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g-context-card", {
    attrs: { title: _vm.$t("general.bookmarks") },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _vm.bookmarks.length
              ? _c(
                  "v-list",
                  { attrs: { nav: "", dense: "" } },
                  [
                    _c(
                      "v-list-item-group",
                      [
                        _vm._l(_vm.bookmarks, function (bookmark, i) {
                          return [
                            i !== 0
                              ? _c("v-divider", {
                                  key: "divider-" + i,
                                  staticClass: "mb-1",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-list-item",
                              {
                                key: "item-" + i,
                                staticClass: "d-flex align-center",
                                attrs: {
                                  to: bookmark.path,
                                  exact: "",
                                  disabled: _vm.loading,
                                },
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(bookmark.icon) +
                                          "\n              "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        "\n                #" +
                                          _vm._s(i + 1) +
                                          " " +
                                          _vm._s(bookmark.title) +
                                          "\n              "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-subtitle",
                                      {
                                        staticClass:
                                          "text-decoration-underline",
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(bookmark.path) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-action",
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            icon: "",
                                                            disabled:
                                                              _vm.loading,
                                                            loading:
                                                              _vm.removingId ===
                                                              bookmark.id,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.onClickDelete(
                                                                bookmark.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "\n                      mdi-delete-outline\n                    "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("general.delete")) +
                                              "\n                "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "text--secondary text-center py-2",
                    staticStyle: { opacity: "0.7" },
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("general.empty")) + "\n    "
                    ),
                  ]
                ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }