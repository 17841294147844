<template>
  <v-alert :type="type" outlined icon="mdi-alert-octagon-outline" elevation="1" class="v-alert-shadow ma-0 mt-2">
    <div class="body-1">
      {{ bodyText }}

      <slot name="body" />
    </div>

    <v-btn
      v-if="hasAction"
      :color="type"
      outlined
      small
      @click="onClickBtn"
    >
      <v-icon v-if="actionIcon">
        {{ actionIcon }}
      </v-icon>
      {{ actionText }}
    </v-btn>
  </v-alert>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'info'
    },

    bodyText: {
      type: String,
      default: null
    },

    hasAction: {
      type: Boolean,
      default: false
    },

    actionText: {
      type: String,
      default: null
    },

    actionIcon: {
      type: String,
      default: null
    }
  },

  setup (props, { emit }) {
    return {
      onClickBtn
    };

    function onClickBtn () {
      emit('click');
    }
  }
});
</script>
<style scoped>
.v-alert-shadow {
  border: none !important;
}

.v-alert-shadow::before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0.12;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
