<template>
  <pre>{{ jsonString }}</pre>
</template>
<script>
import { defineComponent, toRefs } from '@vue/composition-api';

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    },

    field: {
      type: String,
      required: true
    },

    okey: {
      type: String,
      required: true
    },

    fltr: {
      type: Array,
      default: () => []
    }
  },

  setup (props) {
    const { item, field, okey, fltr } = toRefs(props);

    let jsonString = '';

    if (!item.value[field.value]) {
      return {
        jsonString
      };
    }
    try {
      const jsonObj = JSON.parse(item.value[field.value])[okey.value];

      Object.keys(jsonObj).forEach((k) => {
        if (!fltr.value.includes(k)) {
          delete jsonObj[k];
        }
      });

      const stringParts = [];
      if (Object.keys(jsonObj).length > 0) {
        Object.keys(jsonObj).forEach((fk) => {
          Object.keys(jsonObj[fk]).forEach((k) => {
            stringParts.push(`${k}: ${JSON.stringify(jsonObj[fk][k], null, 2)}`);
          });
        });
        jsonString = stringParts.join('\n');
      } else {
        jsonString = '';
      }
    } catch (ex) {
      jsonString = 'render error';
    }

    return {
      jsonString
    };
  }
});
</script>
