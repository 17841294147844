var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    {
      staticClass: "bg-shadow",
      staticStyle: { border: "none !important" },
      attrs: { outlined: "", label: _vm.label, color: _vm.color },
    },
    [
      _vm.icon
        ? _c("v-icon", { attrs: { left: "" } }, [
            _vm._v("\n    " + _vm._s(_vm.icon) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticStyle: { opacity: "0.7" } }, [
        _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }