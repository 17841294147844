var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-content",
    {
      attrs: {
        entity: _vm.entity,
        "has-save": "",
        scrollable: "",
        "disable-save": !_vm.dirty,
      },
      on: { "click:save": _vm.onSave },
    },
    _vm._l(_vm.cards, function (card, i) {
      return _c(
        "v-card",
        { key: i, attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            [
              _vm._v("\n      " + _vm._s(card.title) + "\n\n      "),
              _c("v-spacer"),
              _vm._v(" "),
              card.autosync
                ? _c("v-icon", [_vm._v("\n        mdi-sync\n      ")])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mb-4 mx-4" }),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                card.component,
                _vm._g(
                  _vm._b(
                    { ref: "componentRef", refInFor: true, tag: "component" },
                    "component",
                    card.params,
                    false
                  ),
                  card.events
                )
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }