<template>
  <v-navigation-drawer
    v-model="show"
    :width="width"
    :right="!mobile"
    absolute
    fixed
    temporary
    stateless
    @input="onClose"
  >
    <v-card flat class="px-4">
      <v-card-title class="mb-2">
        <!-- close button -->
        <v-btn large class="" icon @click="onBeforeClickClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <!-- go back button -->
        <v-btn v-if="hasPrevious" large class="" icon @click="onBeforeClickPrevious">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="text-h5 mx-2">
          {{ title }}
        </span>

        <v-spacer />

        <v-btn
          v-if="multistep && steps > titles.length && !finalStep"
          class="bg-shadow"
          :disabled="hasForm && !valid"
          large
          text
          rounded
          color="primary"
          @click="onBeforeClickNext"
        >
          {{ $t('general.next') }}
          <v-icon right>
            mdi-chevron-right
          </v-icon>
        </v-btn>

        <v-btn
          v-else-if="hasSave"
          class="bg-shadow"
          :disabled="hasForm && (!valid || (!dirty && !multistep) || (!dirty && !dirtyOverride && multistep))"
          large
          text
          rounded
          color="primary"
          @click="onBeforeClickSave"
        >
          <v-icon left>
            mdi-content-save-outline
          </v-icon>
          {{ $t('general.save') }}
        </v-btn>

        <v-btn
          v-else-if="hasDelete"
          class="bg-shadow"
          :disabled="hasForm && (!valid || !dirty)"
          large
          text
          rounded
          color="error"
          @click="onBeforeClickSave"
        >
          <v-icon left>
            mdi-delete-outline
          </v-icon>
          {{ $t('general.delete') }}
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-if="multistep" style="padding-left: 70px;" class="d-flex justify-x-between">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"> {{ subtitle }} ({{ $t('general.step') }} {{ titles.length }}) </span>
          </template>
          {{ $t('general.form_title_step') }}
        </v-tooltip>
      </v-card-subtitle>

      <!-- <v-stepper :value="currIdx" flat dense alt-labels class="grey lighten-4 mx-4">
        <v-stepper-header>
          <template v-for="(item, i) in titles">
            <v-divider v-if="i !== 0" :key="`div-${i}`" />

            <v-stepper-step
              :key="`step-${i}`"
              :complete="e1 > 1"
              :step="i + 1"
            >
              {{ item }}
            </v-stepper-step>
          </template>
        </v-stepper-header>
      </v-stepper> -->

      <v-card-text>
        <component
          :is="component"
          v-if="component"
          ref="container"
          v-bind="params"
          :valid.sync="valid"
          :dirty.sync="dirty"
          :error="error"
        />
      </v-card-text>
    </v-card>
    <!-- </v-dialog> -->
  </v-navigation-drawer>
</template>
<script>
import { useContext } from '@nuxtjs/composition-api';
import { defineComponent, ref, watch, computed, onUnmounted } from '@vue/composition-api';
import useModalForm from '@/composables/useModalForm';
import { useLoadingOverlay } from '@/composables/use-loading-overlay';
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  setup () {
    const { $eventBus, $vuetify } = useContext();
    const { getShow, setRef, currentConfig } = useModalForm();

    const i18n = useI18n();
    const { showLoading, hideLoading } = useLoadingOverlay();

    const container = ref(null);

    const show = getShow();
    const valid = ref(false);
    const dirty = ref(false);
    const dirtyOverride = ref(false); // for multistep forms. Will contain dirtyness for previous steps
    const mobile = computed(() => $vuetify.breakpoint.mobile);
    const width = ref('40%');
    const title = ref('');
    const subtitle = ref('');
    const error = ref(null);
    const component = ref(null);
    const params = ref({});
    let caller = null;
    const history = ref([]);
    const multistep = ref(false);
    const steps = ref(-1);
    const finalStep = ref(false);
    let handler = null;
    let prevHandler = null;
    let nextHandler = null;

    const hasSave = ref(false);
    const hasDelete = ref(false);
    const hasForm = ref(false);
    const hasPrevious = ref(false);

    const titles = computed(() => {
      const prevTitles = history.value.map(h => h.title);

      prevTitles.push(title.value);

      return prevTitles;
    });

    const currIdx = computed(() => titles.value.length);

    $eventBus.$on('modal:form', (payload) => {
      // If we are on a view, save it for routing
      if (component.value) {
        history.value.push({
          caller,
          title: title.value,
          subtitle: subtitle.value,
          component: component.value,
          params: params.value,
          hasSave: hasSave.value,
          hasDelete: hasDelete.value,
          hasForm: hasForm.value,
          multistep: multistep.value,
          steps: steps.value,
          finalStep: finalStep.value,
          dirtyOverride: dirtyOverride.value,
          handler,
          prevHandler,
          nextHandler
        });

        hasPrevious.value = !!prevHandler;
      }

      loadView(payload);

      show.value = true;
    });

    watch(currentConfig, (config) => {
      // If we are on a view, save it for routing
      if (component.value) {
        history.value.push({
          caller,
          title: title.value,
          subtitle: subtitle.value,
          component: component.value,
          params: params.value,
          hasSave: hasSave.value,
          hasDelete: hasDelete.value,
          hasForm: hasForm.value,
          multistep: multistep.value,
          steps: steps.value,
          finalStep: finalStep.value,
          dirtyOverride: dirtyOverride.value,
          handler,
          prevHandler,
          nextHandler
        });

        hasPrevious.value = !!prevHandler;
      }

      loadView(config);

      show.value = true;
    });

    watch(
      mobile,
      (value) => {
        width.value = value ? '100%' : '40%';
      },
      {
        immediate: true
      }
    );

    watch(container, setRef, { immediate: true });

    // If we start a multistep form, reset history
    watch(multistep, (value, oldValue) => {
      if (value !== oldValue) {
        history.value = [];
      }
    });

    onUnmounted(() => {
      const { $eventBus } = useContext();

      $eventBus.$off('modal:form');
    });

    return {
      steps,
      multistep,
      finalStep,
      show,
      valid,
      dirty,
      dirtyOverride,
      width,
      title,
      subtitle,
      error,
      component,
      params,
      container,
      mobile,
      hasSave,
      hasDelete,
      hasForm,
      history,
      titles,
      currIdx,
      hasPrevious,
      // headers,
      // rows,
      // pkey,
      onBeforeClickNext,
      onBeforeClickSave,
      onBeforeClickClose,
      onBeforeClickPrevious,
      onClose
    };

    function loadView (payload) {
      const {
        caller: ca,
        component: c,
        params: p,
        title: t,
        subtitle: st,
        handler: h,
        prevHandler: ph,
        nextHandler: nh,
        hasSave: hs,
        hasForm: hf,
        hasDelete: hd,
        multistep: ms,
        steps: s,
        finalStep: fs,
        dirtyOverride: dov
      } = payload;

      caller = ca;
      title.value = t;
      subtitle.value = st;
      component.value = c;
      params.value = p;
      params.value.noGutters = true; // TODO: remove this
      handler = h;
      prevHandler = ph;
      nextHandler = nh;
      multistep.value = !!ms;
      steps.value = s;
      finalStep.value = fs;
      dirtyOverride.value = !!dov;

      hasSave.value = c === 'g-form-base' || !!hs;
      hasForm.value = c === 'g-form-base' || !!hf;
      hasDelete.value = !!hd;
      hasPrevious.value = !!prevHandler;

      error.value = null;
    }

    async function onBeforeClickNext () {
      let res;

      showLoading();

      let result = null;

      // if the component has submit, execute it
      if (container.value?.submit) {
        result = container.value.submit();
      }

      // if an handler was passed, execute it
      if (nextHandler) {
        res = await nextHandler({
          input: params.value,
          output: result,
          dirty: dirty.value
        });

        error.value = res;
      }

      hideLoading();

      if (!res) {
        onClickNext();
      }
    }

    async function onBeforeClickSave () {
      let res;

      showLoading();

      let result = null;

      // if the component has submit, execute it
      if (container.value?.submit) {
        result = container.value.submit();
      }

      // if an handler was passed, execute it
      if (handler) {
        res = await handler({
          input: params.value,
          output: result,
          dirty: dirty.value
        });

        error.value = res;
      }

      hideLoading();

      if (!res) {
        onClickSave();
      }
    }

    function onClickNext () {
    }

    function onClickSave () {
      show.value = false;

      cleanup();
    }

    function onBeforeClickClose () {
      // is dirty if !multistep and is a dirty form or
      // multistep and is dirtyoverride. Dirtyness of multisteps
      // is set outside of this component (composables/userModalForm:openMultistepForm)
      const isDirty =
        (!multistep.value && hasForm.value && hasSave.value && dirty.value) ||
        (multistep.value && (dirtyOverride.value || dirty.value));

      if (isDirty) {
        $eventBus.$emit('overlay', {
          title: i18n.t('general.unsaved_changes_title'),
          text: i18n.t('general.unsaved_changes_text'),
          icon: 'mdi-alert-octagon-outline',
          btns: [
            {
              text: i18n.t('general.cancel'),
              outlined: true
            },
            {
              text: i18n.t('general.exit'),
              color: 'error',
              handler: () => {
                onClickClose();
              }
            }
          ]
        });
      } else {
        onClickClose();
      }
    }

    function onBeforeClickPrevious () {
      if (prevHandler) {
        prevHandler();

        onClickPrevious();
      }
    }

    function onClickClose () {
      show.value = false;
    }

    function onClickPrevious () {
      if (history.value.length) {
        const view = history.value.pop();

        loadView(view);
      }
    }

    function onClose (value) {
      if (!value) { cleanup(); }
    }

    function cleanup () {
      title.value = '';
      subtitle.value = '';
      component.value = null;
      params.value = {};
      history.value = [];
      multistep.value = false;
      steps.value = -1;
      finalStep.value = false;
      dirtyOverride.value = false;
      handler = null;
      nextHandler = null;
      prevHandler = null;
      hasPrevious.value = null;
    }
  }
});
</script>
