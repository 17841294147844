var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-content",
    { attrs: { entity: _vm.entity, scrollable: "" } },
    [
      _c("g-form-wizard", {
        ref: "wizard",
        attrs: { forms: _vm.forms, record: _vm.record },
        on: { submit: _vm.onSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }