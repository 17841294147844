const DEFAULT_MUTATIONS = Object.freeze(['create', 'update', 'delete']);

const map = new Map();
const irel = {}; // inverse relationships

// Plugin to register all imported stores into map
function registrationPlugin ({ store }) {
  map.set(store.$id, store);

  // inverse relationships have to be registered outside the store
  if (store.dependencies && Array.isArray(store.dependencies)) {
    store.dependencies.forEach((dep) => {
      if (!irel[dep]) {
        irel[dep] = [];
      }

      irel[dep].push(store.$id);
    });
  }

  store.$onAction(({
    name, // name of the action
    store, // store instance, same as `someStore`
    args, // array of parameters passed to the action
    after, // hook after the action returns or resolves
    onError // hook if the action throws or rejects
  }) => {
    after(() => {
      if (DEFAULT_MUTATIONS.includes(name) || store.mutations?.includes(name)) {
        const storeId = store.$id;
        const autoRefresh = store.autoRefresh;

        if (autoRefresh) {
          refresh(storeId);
        }

        const relationships = store.relationships;

        if (relationships && relationships.length) {
          relationships.forEach(id => refresh(id));
        }

        const inverseRel = irel[storeId];

        if (inverseRel && inverseRel.length) {
          inverseRel.forEach(id => refresh(id));
        }
      }
    });
  }, true);
}

function setDirty (id) {
  const store = map.get(id);

  if (store) {
    store.readState = null;
  }
}

function refresh (id) {
  const store = map.get(id);

  if (store && store.refresh) {
    store.refresh();
  }
}

// Plugin to expose $stores to the context
export default ({ $pinia }, inject) => {
  $pinia.use(registrationPlugin);

  inject('stores', {
    setDirty
  });
};
