var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("g-field-filter-label", {
        attrs: {
          label: _vm.label,
          operator: _vm.innerOperator,
          operators: _vm.operators,
        },
        on: {
          "update:operator": function ($event) {
            _vm.innerOperator = $event
          },
          "click:remove": _vm.onRemove,
        },
      }),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "auto",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            readonly: "",
                            outlined: "",
                            dense: "",
                            "error-messages": _vm.errorMessages,
                          },
                          model: {
                            value: _vm.formatted,
                            callback: function ($$v) {
                              _vm.formatted = $$v
                            },
                            expression: "formatted",
                          },
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _vm._v(" "),
          _c("v-date-picker", {
            attrs: {
              min: _vm.min,
              max: _vm.max,
              "no-title": "",
              "show-adjacent-months": "",
            },
            on: { input: _vm.onInput },
            model: {
              value: _vm.innerValue,
              callback: function ($$v) {
                _vm.innerValue = $$v
              },
              expression: "innerValue",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }