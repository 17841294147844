<template>
  <v-card-text>
    {{ $t('general.confirm') }}:

    <div class="d-flex">
      <v-list two-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle class="text--primary">
              {{ $t('general.id') }}:
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary">
              {{ $t('general.name') }}:
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list two-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ record.id }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-card-text>
</template>
<script>
import { computed, defineComponent, toRef } from '@vue/composition-api';

export default defineComponent({
  props: {
    record: {
      type: Object,
      required: true
    }
  },

  setup (props) {
    const record = toRef(props, 'record');

    const name = computed(() => {
      return record.value.name;
    });

    return {
      name
    };
  }
});
</script>
