var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass:
        "pt-2 d-flex flex-column justify-center align-center transparent",
      attrs: { flat: "", width: "100%" },
    },
    [
      _vm.carousel
        ? _c(
            "v-carousel",
            { attrs: { height: _vm.size * 2, "hide-delimiters": "" } },
            [
              !_vm.photos.length
                ? _c(
                    "v-carousel-item",
                    { staticClass: "g-empty-carousel-text" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "title font-weight-bold",
                          staticStyle: { color: "rgba(0,0,0,0.3) !important" },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("general.empty")) +
                              "\n      "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._l(_vm.photos, function (item, i) {
                    return _c("v-carousel-item", {
                      key: i,
                      attrs: {
                        src: item.foto,
                        "reverse-transition": "fade-transition",
                        transition: "fade-transition",
                      },
                    })
                  }),
            ],
            2
          )
        : _c(
            "v-avatar",
            {
              staticClass: "my-2",
              staticStyle: { border: "2px solid var(--v-primary-lighten2)" },
              attrs: { size: _vm.size },
            },
            [
              _vm.src
                ? _c("v-img", { attrs: { width: _vm.size, src: _vm.innerSrc } })
                : _c(
                    "v-icon",
                    {
                      staticStyle: { "font-size": "4rem" },
                      attrs: { color: "primary" },
                    },
                    [_vm._v("\n      " + _vm._s(_vm.defaultIcon) + "\n    ")]
                  ),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "v-list",
        {
          staticClass: "font-weight-medium transparent",
          attrs: { width: "100%", dense: "" },
        },
        [
          _c("v-list-item", { staticClass: "text-h6 primary--text" }, [
            _c(
              "div",
              { staticClass: "text-center", staticStyle: { width: "100%" } },
              [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
            ),
          ]),
          _vm._v(" "),
          _vm.secTitle && !_vm.secTitleLnk
            ? _c("v-list-item", { staticClass: "text-h7 primary--text" }, [
                _c(
                  "div",
                  {
                    staticClass: "text-center",
                    staticStyle: { width: "100%" },
                  },
                  [_vm._v("\n        " + _vm._s(_vm.secTitle) + "\n      ")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.secTitleLnk
            ? _c("v-list-item", { staticClass: "text-h7 primary--text" }, [
                _c(
                  "div",
                  {
                    staticClass: "text-center",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c(
                      "a",
                      { attrs: { href: _vm.secTitleLnk, target: "_blank" } },
                      [_vm._v(_vm._s(_vm.secTitle))]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.subtitle
            ? _c(
                "v-list-item",
                { staticClass: "primary--text text-subtitle-2 pt-3" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "text-center",
                      staticStyle: { width: "100%" },
                    },
                    [
                      _c(
                        "v-chip",
                        { staticClass: "px-4", attrs: { color: "primary" } },
                        [
                          _vm._v(
                            "\n          " + _vm._s(_vm.subtitle) + "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }