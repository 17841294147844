var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card-text", [
    _vm._v("\n  " + _vm._s(_vm.$t("general.confirm")) + ":\n\n  "),
    _c(
      "div",
      { staticClass: "d-flex" },
      [
        _c(
          "v-list",
          { attrs: { "two-line": "" } },
          [
            _c(
              "v-list-item",
              [
                _c(
                  "v-list-item-content",
                  [
                    _c(
                      "v-list-item-subtitle",
                      { staticClass: "text--primary" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.id")) +
                            ":\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-list-item-subtitle",
                      { staticClass: "text--primary" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.name")) +
                            ":\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-list",
          { attrs: { "two-line": "" } },
          [
            _c(
              "v-list-item",
              [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-subtitle", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.record.id) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("v-list-item-subtitle", [
                      _vm._v(
                        "\n            " + _vm._s(_vm.name) + "\n          "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }