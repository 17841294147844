var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-sheet",
    {
      attrs: { inset: "", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { rounded: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "d-flex align-center pa-4" },
            [
              _vm._v("\n      " + _vm._s(_vm.text) + "\n\n      "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "success" },
                  on: { click: _vm.onClickAccept },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.confirm")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }