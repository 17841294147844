<template>
  <div class="d-flex align-center pb-2">
    <h3 class="font-weight-medium">
      {{ label }}
    </h3>

    <v-menu
      offset-y
      left
    >
      <template #activator="{ attrs, on, value: show }">
        <v-btn
          class="px-1 mx-1"
          style="text-transform: none !important;"
          small
          text
          v-bind="attrs"
          v-on="on"
        >
          <h3
            class="primary--text"
          >
            {{ humanOperator }}
          </h3>

          <v-icon color="primary">
            {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          v-for="(op, i) in operators"
          :key="i"
          @click="innerOperator = op.value"
        >
          <v-list-item-title> {{ op.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-spacer />

    <v-btn class="mx-2" small icon @click.stop="onClickRemove">
      <v-icon>
        mdi-close
      </v-icon>
    </v-btn>
  </div>
</template>
<script>
import { computed, defineComponent, toRef } from '@vue/composition-api';
import { useSync } from '@/composables/useSync';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: null
    },

    operator: {
      type: String,
      default: null
    },

    operators: {
      type: Array,
      default: () => []
    }
  },

  setup (props, { emit }) {
    const operators = toRef(props, 'operators');

    const innerOperator = useSync(props, 'operator', emit);

    const humanOperator = computed(() => {
      const op = operators.value.find(o => o.value === innerOperator.value);

      if (!op) {
        return innerOperator.value;
      } else {
        return op.name;
      }
    });

    return {
      innerOperator,
      humanOperator,

      onClickRemove,
      onChangeOperator
    };

    function onClickRemove () {
      emit('click:remove');
    }

    function onChangeOperator (value) {
      emit('change:operator', value);
    }
  }
});
</script>
