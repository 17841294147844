var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "client-only",
        [
          !_vm.ready
            ? [_vm._v("\n      Loading...\n    ")]
            : [
                _c("g-drawer-navigation"),
                _vm._v(" "),
                _c(
                  "v-main",
                  [
                    _c(
                      "v-container",
                      { staticClass: "pa-0 fill-height", attrs: { fluid: "" } },
                      [_c("Nuxt")],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("g-drawer-modal"),
                _vm._v(" "),
                _c("g-drawer-context"),
                _vm._v(" "),
                _c("g-toolbar-mobile"),
                _vm._v(" "),
                _c("g-overlay-base"),
                _vm._v(" "),
                _c("g-overlay-loading"),
                _vm._v(" "),
                _c("g-toast-base"),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }