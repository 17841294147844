<template>
  <v-list class="pa-0">
    <template v-for="(item, i) in items">
      <v-divider v-if="i > 0" :key="`divider-${i}`" />

      <v-list-item
        :key="i"
        :style="item.disabled ? 'cursor: not-allowed !important; opacity: 0.4;' : ''"
        @click="onClickItem(item)"
      >
        <v-list-item-icon>
          <v-icon :color="item.color || defaultColor" style="opacity: 0.84">
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title :class="`${item.color || defaultColor}--text`">
            {{ item.text }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="item.subtext"
            :class="item.color ? `${item.color}--text` : ''"
            :style="`text-align: justify; overflow: visible !important;white-space: unset !important;${item.color ? 'opacity: 0.85' : ''}`"
          >
            {{ item.subtext }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon v-if="item.events && item.events.click">
          <v-icon :color="item.color || defaultColor" style="opacity: 0.84">
            mdi-chevron-right
          </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </template>
  </v-list>
</template>

</template>
<script>
import { defineComponent } from '@vue/composition-api';
import useToast from '@/composables/use-toast';
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  setup () {
    const i18n = useI18n();
    const toast = useToast();

    const defaultColor = 'primary';

    return {
      defaultColor,
      onClickItem,
      onClickDisabled
    };

    function onClickItem (item) {
      if (item.disabled) {
        onClickDisabled();
      } else {
        item.events.click();
      }
    }

    function onClickDisabled () {
      toast.warn(i18n.t('general.option_disabled'));
    }
  }
});
</script>
<style>
.v-card-item {
  transition: opacity .2s ease-in-out;
}

.v-card-item:not(.on-hover) {
  opacity: 0.86;
 }
</style>
