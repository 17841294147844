var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "bg-shadow",
      attrs: { small: "", color: _vm.color, text: "", rounded: "" },
      on: { click: _vm.onClick },
    },
    [
      _vm.icon
        ? _c("v-icon", { attrs: { left: !!_vm.text } }, [
            _vm._v("\n    " + _vm._s(_vm.icon) + "\n  "),
          ])
        : _vm._e(),
      _vm._v("\n  " + _vm._s(_vm.text) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }