import _ from 'lodash';
import { CREATE_ACTION, DELETE_ACTION, GET_ACTION, READ_ACTION, UPDATE_ACTION } from '../composables/use-stores';
import { create, read, update, del, get } from './proxy';

// default state
export function state (collection, primaryKey, relationships = [], dependencies = [], mutations = []) {
  return {
    loading: false,
    row: null,
    total: 0,
    error: null,
    primaryKey,
    fields: [],
    relationships: Object.freeze(relationships),
    dependencies: Object.freeze(dependencies),
    mutations: Object.freeze(mutations),
    api: Object.freeze({
      get: `/api/${collection}`,
      put: `/api/${collection}`,
      patch: `/api/${collection}`,
      delete: `/api/${collection}`
    }),
    readState: '',
    lastQuery: null,
    autoRefresh: false,
    controller: null
  };
}

// default getters
export function getters () {
  return {
  };
}

// util to generate all available actions
function generateActions () {
  return {
    async get () {
      this.loading = true;

      const { rows, total, error } = await get(this);

      const row = total === 1 ? rows[0] : null;

      this.error = error;
      this.row = row;
      this.total = total;

      this.loading = false;

      return {
        row,
        total,
        error
      };
    },

    async read (params, force = false) {
      if (this.loading && this.controller) {
        this.controller.abort();
      }

      this.loading = true;

      const controller = new AbortController();

      this.controller = controller;

      const res = await read(this, params, { force, signal: controller.signal });

      const { rows, total, queryState, refresh, status } = res;
      let { error } = res;

      if (refresh && status !== 0) {
        this.readState = queryState;
        this.lastQuery = _.cloneDeep(params);

        this.row = total ? rows[0] : null;
        this.total = total;

        if (total > 1 && !error) {
          error = 'Inconsistent state';
        }

        this.error = error;
      }

      // fixes a bug where loading flag is set to false
      // when queries are canceled
      if (status !== 0) {
        this.loading = false;
      }

      return {
        row: this.row,
        total,
        error
      };
    },

    async create (values) {
      this.loading = true;

      const result = await create(this, values);

      this.error = result.error;

      this.loading = false;

      return result;
    },

    async update (values, where) {
      this.loading = true;

      if (!where && this.primaryKey) {
        where = {};

        where[this.primaryKey] = this.row[this.primaryKey];
      }

      const { data, error } = await update(this, values, where);

      this.error = error;

      this.loading = false;

      return { data, error };
    },

    async delete (where) {
      this.loading = true;

      if (!where && this.primaryKey) {
        where = {};

        where[this.primaryKey] = this.row[this.primaryKey];
      }

      const { data, error } = await del(this, where);

      this.error = error;

      this.loading = false;

      return { data, error };
    },

    async readProxy (params, force = true) {
      return await read(this, params, force);
    },

    async createProxy (values) {
      return await create(this, values);
    },

    async updateProxy (values, where) {
      return await update(this, values, where);
    },

    async deleteProxy (where) {
      return await del(this, where);
    },

    async refresh () {
      const params = this.lastQuery || {};

      return await this.read(params, true);
    },

    clean () {
      this.error = null;
      this.row = null;
      this.total = 0;
    }
  };
}

// default actions
export function actions (allowed) {
  if (!allowed || !Array.isArray(allowed)) {
    return {};
  }

  const allActions = generateActions();

  const addedActions = {
    pivot: allActions.pivot,
    refresh: allActions.refresh,
    clean: allActions.clean
  };

  for (let i = 0; i < allowed.length; i++) {
    const action = allowed[i];

    switch (action) {
      case GET_ACTION:
        addedActions.get = allActions.get;
        break;
      case CREATE_ACTION:
        addedActions.create = allActions.create;
        addedActions.createProxy = allActions.createProxy;
        break;
      case READ_ACTION:
        addedActions.read = allActions.read;
        addedActions.readProxy = allActions.readProxy;
        break;
      case UPDATE_ACTION:
        addedActions.update = allActions.update;
        addedActions.updateProxy = allActions.updateProxy;
        break;
      case DELETE_ACTION:
        addedActions.delete = allActions.delete;
        addedActions.deleteProxy = allActions.deleteProxy;
        break;
      default:
        break;
    }
  }

  return addedActions;
}

export default {
  state,
  getters,
  actions
};
