<template>
  <div
    class="primary--text d-flex flex-column justify-center mx-4 py-2"
  >
    <div class="v-card__title px-2 pt-1 pb-2 text-h6 font-weight-bold">
      {{ PLATFORM_TITLE }}
    </div>

    <div v-if="PLATFORM_SUBTITLE" class="v-card__subtitle px-2 py-1 font-weight-medium subtitle-2 text--secondary" style="opacity: 0.8">
      {{ PLATFORM_SUBTITLE }}
    </div>
  </div>
</template>
<script>
import { useContext } from '@nuxtjs/composition-api';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup () {
    const { $config } = useContext();

    const { PLATFORM_TITLE, PLATFORM_SUBTITLE } = $config;

    return {
      PLATFORM_TITLE,
      PLATFORM_SUBTITLE
    };
  }
});
</script>
