<template>
  <g-content
    :entity="entity"
    has-save
    scrollable
    :disable-save="!dirty"
    @click:save="onSave"
  >
    <v-card v-for="(card, i) in cards" :key="i" flat>
      <v-card-title>
        <!-- <v-icon left> {{ card.icon }}</v-icon> -->
        {{ card.title }}

        <v-spacer />

        <v-icon v-if="card.autosync">
          mdi-sync
        </v-icon>
      </v-card-title>
      <v-divider class="mb-4 mx-4" />

      <v-card-text>
        <component
          :is="card.component"
          ref="componentRef"
          v-bind="card.params"
          v-on="card.events"
        />
      </v-card-text>
    </v-card>
  </g-content>
</template>

<script>
import {
  defineComponent,
  ref,
  toRefs,
  watch
} from '@vue/composition-api';
import { usePresentationState } from '@/stores/system/presentation-state';

export default defineComponent({
  props: {
    entity: {
      type: String,
      required: true
    },

    caller: {
      type: String,
      required: true
    },

    cards: {
      type: Array,
      default: () => []
    }
  },

  emits: [
    'save'
  ],

  setup (props, { emit }) {
    const stateStore = usePresentationState();

    const { cards } = toRefs(props);

    const componentRef = ref();
    const dirty = ref(false);
    const dirtyStates = ref({});

    stateStore.initState(props.caller, { type: 'data' });

    watch(cards, function (values) {
      values.forEach(registerCard);
    }, { immediate: true });

    return {
      dirty,
      componentRef,

      onSave
    };

    function registerCard (card, i) {
      if (card.component === 'g-form-base') {
        dirtyStates.value[i] = false;

        if (!card.events) { card.events = {}; }

        card.events['update:dirty'] = (value) => {
          dirtyStates.value[i] = value;

          refreshDirtyState();
        };
      }
    }

    function refreshDirtyState () {
      let isDirty = false;

      for (const key in dirtyStates.value) {
        if (dirtyStates.value[key]) {
          isDirty = true;
          break;
        }
      }

      dirty.value = isDirty;
    }

    function onSave () {
      const payload = {};

      for (const index in dirtyStates.value) {
        if (dirtyStates.value[index]) {
          const component = componentRef.value[index];

          const pristine = component.record;
          const values = component.submit();

          payload[index] = {
            input: pristine,
            output: values
          };
        }
      }

      emit('save', payload);
    }
  }
});
</script>
