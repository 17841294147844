<template>
  <v-breadcrumbs v-show="items.length > 0" :items="items">
    <template #divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>

    <template #item="{ item }">
      <v-breadcrumbs-item
        :class="{
          'primary--text breadcrumb-link font-weight-medium': true,
          'disabled': item.disabled
        }"
        @click="onClickLink(item)"
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
<script>
import { useRoute, useRouter } from '@nuxtjs/composition-api';
import { computed, defineComponent } from '@vue/composition-api';
import { useRoutes } from '@/stores/auth/routes';

export default defineComponent({
  setup () {
    const router = useRouter();
    const route = useRoute();
    const routesStore = useRoutes();

    const items = computed(() => {
      const parts = route.value.path.split('/')
        .filter(p => p !== '');

      let composite = '';

      // TODO: remove the need to use client/
      if (parts.length > 0 && parts[0] === 'client') {
        parts.splice(0, 1);

        composite = '/client';
      }

      const len = parts.length;

      return parts
        .map((part, i) => {
          composite = `${composite}/${part}`;

          // finds the corresponding route. Accounts for wild cards
          const matchingRoute = findRoute(composite);

          return {
            text: matchingRoute ? matchingRoute.title : part,
            disabled: i + 1 === len,
            to: composite + '/'
          };
        });
    });

    return {
      items,

      onClickLink
    };

    function findRoute (route) {
      return routesStore.findRoute(route);
    }

    function onClickLink (item) {
      if (!item.disabled) {
        router.push(item.to);
      }
    }
  }
});
</script>
<style scoped>
.breadcrumb-link {
  opacity: 0.7;
}

.breadcrumb-link:hover:not(.disabled) {
  cursor: pointer;
  /* text-decoration: underline; */
  opacity: 1.0;
}

.breadcrumb-link.disabled {
  cursor: not-allowed;
}
</style>
