var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-content",
    {
      attrs: { entity: _vm.entity, scrollable: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "header-tools",
            fn: function (headerParams) {
              return [_vm._t("header-tools", null, null, headerParams)]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _c("g-table-timeline", {
        attrs: {
          "title-key": _vm.titleKey,
          "time-key": _vm.timeKey,
          headers: _vm.headers,
          rows: _vm.items,
          total: _vm.total,
          error: _vm.error,
          options: _vm.tableState.options,
          dense: _vm.tableState.dense,
          striped: _vm.tableState.striped,
          loading: _vm.loading,
        },
        on: {
          "update:options": function ($event) {
            return _vm.$set(_vm.tableState, "options", $event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "after-body",
              fn: function (ref) {
                var item = ref.item
                return [_vm._t("after-body", null, { item: item })]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }