<template>
  <g-context-card :title="$t('general.help')">
    <template #default>
      <div v-if="helpText" class="text-justify" v-html="helpText" />
      <div v-else class="text--secondary text-center py-2" style="opacity: 0.7">
        {{ $t('general.empty') }}
      </div>
    </template>
  </g-context-card>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api';
import { useRoute } from '@nuxtjs/composition-api';
import { useRoutes } from '@/stores/auth/routes';

export default defineComponent({
  props: {
    toolId: {
      type: String,
      required: true
    }
  },

  setup () {
    const route = useRoute();
    const routesStore = useRoutes();

    const helpText = computed(() => {
      const curr_route = routesStore.findRoute(route.value.path);
      if (curr_route && curr_route.description) {
        return curr_route.description.replace(/http[s]*:\/\//g, '');
      }
      return null;
    });

    return {
      helpText
    };
  }
});
</script>

<style scoped>
.text-justify {
    text-align: justify;
    text-justify: inter-word;
}
</style>
