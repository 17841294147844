var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g-context-card", {
    attrs: { title: _vm.$t("general.presentation") },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "py-2" },
              [
                _c("v-select", {
                  attrs: {
                    multiple: "",
                    outlined: "",
                    dense: "",
                    label: _vm.$t("general.active_fields"),
                    items: _vm.currentFields,
                  },
                  model: {
                    value: _vm.visibleFields,
                    callback: function ($$v) {
                      _vm.visibleFields = $$v
                    },
                    expression: "visibleFields",
                  },
                }),
                _vm._v(" "),
                _c("v-select", {
                  attrs: {
                    label: _vm.$t("general.sort_type"),
                    outlined: "",
                    dense: "",
                    items: [
                      {
                        text: _vm.$t("general.sort_type_singular"),
                        value: false,
                      },
                      {
                        text: _vm.$t("general.sort_type_multiple"),
                        value: true,
                      },
                    ],
                  },
                  model: {
                    value: _vm.currentMultiSort,
                    callback: function ($$v) {
                      _vm.currentMultiSort = $$v
                    },
                    expression: "currentMultiSort",
                  },
                }),
                _vm._v(" "),
                _c("v-select", {
                  attrs: {
                    outlined: "",
                    dense: "",
                    label: _vm.$t("general.items_per_page"),
                    items: [10, 25, 50],
                  },
                  model: {
                    value: _vm.currentOptions.itemsPerPage,
                    callback: function ($$v) {
                      _vm.$set(_vm.currentOptions, "itemsPerPage", $$v)
                    },
                    expression: "currentOptions.itemsPerPage",
                  },
                }),
                _vm._v(" "),
                _c("v-select", {
                  attrs: {
                    outlined: "",
                    dense: "",
                    label: _vm.$t("general.row_height"),
                    items: [
                      {
                        text: _vm.$t("general.row_height_default"),
                        value: false,
                      },
                      { text: _vm.$t("general.row_height_dense"), value: true },
                    ],
                  },
                  model: {
                    value: _vm.currentRowHeight,
                    callback: function ($$v) {
                      _vm.currentRowHeight = $$v
                    },
                    expression: "currentRowHeight",
                  },
                }),
                _vm._v(" "),
                _c("v-select", {
                  attrs: {
                    outlined: "",
                    dense: "",
                    label: _vm.$t("general.row_style"),
                    items: [
                      {
                        text: _vm.$t("general.row_style_default"),
                        value: false,
                      },
                      {
                        text: _vm.$t("general.row_style_striped"),
                        value: true,
                      },
                    ],
                  },
                  model: {
                    value: _vm.currentRowStyle,
                    callback: function ($$v) {
                      _vm.currentRowStyle = $$v
                    },
                    expression: "currentRowStyle",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }