var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-iterator", {
    attrs: {
      "fixed-header": "",
      "show-select": _vm.showSelect,
      "single-select": "",
      "multi-sort": _vm.multiSort,
      "checkbox-color": "primary",
      headers: _vm.iHeaders,
      items: _vm.rows,
      "selectable-key": "id",
      "item-key": _vm.itemKey,
      "server-items-length": _vm.total ? _vm.total : _vm.rows.length,
      options: _vm.iOptions,
      loading: _vm.loading,
      "footer-props": {
        "items-per-page-options": [10, 25, 50],
      },
      "hide-default-footer": _vm.hideFooter,
      dense: _vm.dense,
    },
    on: {
      "update:headers": function ($event) {
        _vm.iHeaders = $event
      },
      "update:options": function ($event) {
        _vm.iOptions = $event
      },
      "click:row": _vm.onClickRow,
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              { staticStyle: { position: "sticky", top: "0" } },
              [
                _c("v-divider"),
                _vm._v(" "),
                _c("v-progress-linear", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { indeterminate: "" },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "loading",
        fn: function () {
          return [
            _c("div", { staticClass: "pa-4 text--secondary text-center" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("general.loading_items")) +
                  "...\n    "
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "no-data",
        fn: function () {
          return [
            !_vm.error
              ? _c("div", { staticClass: "pa-4 text--secondary text-center" }, [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("general.no_data")) + "\n    "
                  ),
                ])
              : _c(
                  "div",
                  { staticClass: "pa-4", staticStyle: { opacity: "0.8" } },
                  [
                    _c("v-icon", { attrs: { left: "", color: "error" } }, [
                      _vm._v("\n        mdi-alert-circle-outline\n      "),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "error--text" }, [
                      _vm._v("\n        " + _vm._s(_vm.error) + "\n      "),
                    ]),
                  ],
                  1
                ),
          ]
        },
        proxy: true,
      },
      {
        key: "no-results",
        fn: function () {
          return [
            _c("div", { staticClass: "pa-4 text--secondary text-center" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("general.no_results")) + "\n    "
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function (ref) {
          var items = ref.items
          var isSelected = ref.isSelected
          var select = ref.select
          return [
            _c(
              "div",
              { staticClass: "v-data-table__wrapper pa-4" },
              [
                _c(
                  "v-row",
                  { attrs: { dense: _vm.dense, "align-content": "start" } },
                  [
                    _vm._l(items, function (item, i) {
                      return _c(
                        "v-col",
                        {
                          key: i,
                          staticClass: "d-flex child-flex",
                          attrs: {
                            lg: _vm.cols.lg,
                            md: _vm.cols.md,
                            sm: _vm.cols.sm,
                            cols: _vm.cols.xs,
                          },
                        },
                        [
                          _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var hover = ref.hover
                                    return [
                                      _c(
                                        "v-card",
                                        {
                                          class: { "on-hover": hover },
                                          attrs: {
                                            flat: "",
                                            elevation: hover ? 4 : 2,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onClickRow(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-img",
                                            {
                                              staticClass: "white--text",
                                              attrs: {
                                                src: _vm.thumbKey
                                                  ? item[_vm.thumbKey]
                                                  : item[_vm.imageKey],
                                                gradient:
                                                  "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
                                                "aspect-ratio": "1",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "placeholder",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass:
                                                              "fill-height ma-0",
                                                            attrs: {
                                                              align: "center",
                                                              justify: "center",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-progress-circular",
                                                              {
                                                                attrs: {
                                                                  indeterminate:
                                                                    "",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm._v(" "),
                                              isSelected(item)
                                                ? _c(
                                                    "v-overlay",
                                                    {
                                                      attrs: {
                                                        absolute: "",
                                                        color: "#036358",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-title",
                                                        {
                                                          staticClass:
                                                            "font-weight-medium",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "general.selected"
                                                                )
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column",
                                                  staticStyle: {
                                                    height: "100%",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      _vm.showSelect
                                                        ? _c("v-checkbox", {
                                                            staticClass: "ma-3",
                                                            attrs: {
                                                              large: "",
                                                              dark: "",
                                                              "hide-details":
                                                                "",
                                                              "input-value":
                                                                isSelected(
                                                                  item
                                                                ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                val
                                                              ) {
                                                                return select(
                                                                  item,
                                                                  val
                                                                )
                                                              },
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("v-spacer"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            icon: "",
                                                            dark: "",
                                                            href: item[
                                                              _vm.imageKey
                                                            ],
                                                            download: "",
                                                          },
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-download"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            icon: "",
                                                            dark: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.onMagnify(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-arrow-expand"
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-spacer"),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.iHeaders.length
                                            ? _c(
                                                "v-list",
                                                { attrs: { dense: "" } },
                                                _vm._l(
                                                  _vm.iHeaders,
                                                  function (field, index) {
                                                    return _c(
                                                      "v-list-item",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "v-list-item-content",
                                                          {
                                                            staticClass:
                                                              "align-end",
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      field.text
                                                                    ) +
                                                                    "\n                    "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-list-item-subtitle",
                                                              [
                                                                !field.renderer
                                                                  ? [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            item[
                                                                              field
                                                                                .value
                                                                            ]
                                                                          ) +
                                                                          "\n                      "
                                                                      ),
                                                                    ]
                                                                  : _c(
                                                                      field
                                                                        .renderer
                                                                        .component,
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                item: item,
                                                                                field:
                                                                                  field
                                                                                    .renderer
                                                                                    .value,
                                                                              },
                                                                          },
                                                                          "component",
                                                                          field
                                                                            .renderer
                                                                            .params,
                                                                          false
                                                                        ),
                                                                        field
                                                                          .renderer
                                                                          .events
                                                                      )
                                                                    ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c("v-spacer"),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "" },
                    on: { "click:outside": _vm.onMinify },
                    model: {
                      value: _vm.magnify,
                      callback: function ($$v) {
                        _vm.magnify = $$v
                      },
                      expression: "magnify",
                    },
                  },
                  [
                    _vm.magnifiedRow
                      ? _c(
                          "v-img",
                          {
                            staticClass: "white--text",
                            attrs: {
                              src: _vm.magnifiedRow[_vm.imageKey],
                              gradient:
                                "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
                              "max-height": "90vh",
                              contain: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "placeholder",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "fill-height ma-0",
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            attrs: { indeterminate: "" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex flex-column",
                                staticStyle: { height: "100%" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-end" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "ma-4",
                                        attrs: {
                                          "x-large": "",
                                          icon: "",
                                          dark: "",
                                        },
                                        on: { click: _vm.onMinify },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-close")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-spacer"),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.iSelection,
      callback: function ($$v) {
        _vm.iSelection = $$v
      },
      expression: "iSelection",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }