var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      ref: "card",
      class: {
        "scrollable-content": _vm.scrollable,
        "fullscreen-content": !_vm.scrollable,
        "px-4": true,
      },
      attrs: { flat: "", tile: "", height: "100vh", width: "100%" },
      on: { scroll: _vm.onScroll },
    },
    [
      _vm.innerCanRead
        ? [
            _c(
              "div",
              { class: { "sticky-toolbar": _vm.scrollable } },
              [
                _c(
                  "v-toolbar",
                  { staticClass: "pr-3", attrs: { height: "88", flat: "" } },
                  [
                    _vm.depth > 1
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ml-1",
                                              attrs: { icon: "" },
                                              on: {
                                                click: _vm.onClickPrevious,
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "\n                mdi-chevron-left\n              "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1533017118
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("general.go_back"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        class: {
                          "bg-shadow": true,
                          "ml-1": _vm.depth <= 1,
                          "ml-2": _vm.depth > 1,
                        },
                        attrs: { icon: "", color: "primary" },
                      },
                      [
                        _c("v-icon", [
                          _vm._v(
                            "\n            " + _vm._s(_vm.icon) + "\n          "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-toolbar-title",
                      {
                        class: {
                          "font-weight-medium": true,
                          "ml-4": _vm.depth > 1,
                          "mr-3": true,
                        },
                      },
                      [
                        _c(
                          "span",
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.innerTitle) +
                                "\n\n            "
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "2px",
                                                    overflow: "auto !important",
                                                  },
                                                  attrs: {
                                                    loading:
                                                      _vm.loadingBookmarks,
                                                    color: "primary",
                                                    icon: "",
                                                    plain: !_vm.bookmarked,
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: _vm.onClickBookmark,
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    height: "21px",
                                                    "font-size": "21px",
                                                    width: "21px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.bookmarked
                                                          ? "mdi-bookmark-check-outline"
                                                          : "mdi-bookmark-outline"
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2961764332
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.bookmarked
                                        ? _vm.$t("general.remove_bookmark")
                                        : _vm.$t("general.add_bookmark")
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("g-breadcrumbs", { staticClass: "pa-0" }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _vm._t("header-tools", null, {
                      canCreate: _vm.innerCanCreate,
                      canRead: _vm.innerCanRead,
                      canUpdate: _vm.innerCanUpdate,
                      canDelete: _vm.innerCanDelete,
                    }),
                    _vm._v(" "),
                    _vm.hasExport
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "bg-shadow ml-2",
                                              attrs: {
                                                disable: _vm.disableExport,
                                                icon: "",
                                                color: "info",
                                              },
                                              on: { click: _vm.onClickExport },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-file-excel"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3242348112
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("general.export"))),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasCreate && _vm.innerCanCreate
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "bg-shadow ml-2",
                                              attrs: {
                                                disable: _vm.disableCreate,
                                                icon: "",
                                                color: "success",
                                              },
                                              on: { click: _vm.onClickCreate },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("mdi-plus")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              165016027
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.createNewRecordLabel
                                    ? _vm.createNewRecordLabel
                                    : _vm.$t("general.create_new_record")
                                ) + "\n          "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasUpdate && _vm.innerCanUpdate
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "bg-shadow ml-2",
                                              attrs: {
                                                disabled: _vm.disableUpdate,
                                                icon: "",
                                                color: "warning",
                                              },
                                              on: { click: _vm.onClickUpdate },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-pencil-outline"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              519255004
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.updateSelectedRecordLabel
                                    ? _vm.updateSelectedRecordLabel
                                    : _vm.$t("general.update_selected_record")
                                ) + "\n          "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasDelete && _vm.innerCanDelete
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "bg-shadow ml-2",
                                              attrs: {
                                                disabled: _vm.disableDelete,
                                                icon: "",
                                                color: "error",
                                              },
                                              on: { click: _vm.onClickDelete },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-delete-outline"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1373163946
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.deleteSelectedRecordLabel
                                    ? _vm.deleteSelectedRecordLabel
                                    : _vm.$t("general.delete_selected_record")
                                ) + "\n          "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasArchive
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "bg-shadow ml-2",
                                              attrs: {
                                                disabled: _vm.disableArchive,
                                                icon: "",
                                                color: "error",
                                              },
                                              on: { click: _vm.onClickArchive },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("mdi-archive")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1138097724
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.archiveSelectedRecordLabel
                                    ? _vm.archiveSelectedRecordLabel
                                    : _vm.$t("general.archive_selected_record")
                                ) + "\n          "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "v-fade-transition",
                  [_vm.scrolled ? _c("v-divider") : _vm._e()],
                  1
                ),
                _vm._v(" "),
                _c("v-fade-transition", [
                  !_vm.scrolled
                    ? _c(
                        "div",
                        {
                          staticClass: "px-5 pb-2 white",
                          staticStyle: { height: "34px" },
                        },
                        [
                          _vm.userMembership
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "bg-shadow",
                                                    staticStyle: {
                                                      border: "none",
                                                    },
                                                    attrs: {
                                                      small: "",
                                                      color: "secondary",
                                                      outlined: "",
                                                      disabled:
                                                        !_vm.innerCanRead,
                                                    },
                                                  },
                                                  "v-chip",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      left: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  mdi-account-group-outline\n                "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n\n                " +
                                                    _vm._s(_vm.userMembership) +
                                                    "\n              "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1065222592
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", { staticClass: "caption" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("general.active_user_group")
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "bg-shadow",
                                                staticStyle: { border: "none" },
                                                attrs: {
                                                  small: "",
                                                  color: "success",
                                                  outlined: "",
                                                  disabled: !_vm.innerCanRead,
                                                },
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", left: "" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  mdi-book-open\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.reading")
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2851268317
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", { staticClass: "caption" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "general.your_profile_has_permission_to_read"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "bg-shadow ml-1",
                                                staticStyle: { border: "none" },
                                                attrs: {
                                                  small: "",
                                                  color: "success",
                                                  outlined: "",
                                                  disabled: !_vm.innerCanCreate,
                                                },
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", left: "" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  mdi-plus\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.creation")
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2937051527
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", { staticClass: "caption" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "general.your_profile_has_permission_to_create"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "bg-shadow ml-1",
                                                staticStyle: { border: "none" },
                                                attrs: {
                                                  small: "",
                                                  color: "success",
                                                  outlined: "",
                                                  disabled: !_vm.innerCanUpdate,
                                                },
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", left: "" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  mdi-pencil-outline\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.writing")
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2287843305
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", { staticClass: "caption" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "general.your_profile_has_permission_to_write"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "bg-shadow ml-1",
                                                staticStyle: { border: "none" },
                                                attrs: {
                                                  small: "",
                                                  color: "success",
                                                  outlined: "",
                                                  disabled: !_vm.innerCanDelete,
                                                },
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", left: "" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  mdi-delete-outline\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("general.deletion")
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3045221801
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", { staticClass: "caption" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "general.your_profile_has_permission_to_delete"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "px-4" },
              [
                _vm._t("headline", null, {
                  canCreate: _vm.innerCanCreate,
                  canRead: _vm.innerCanRead,
                  canUpdate: _vm.innerCanUpdate,
                  canDelete: _vm.innerCanDelete,
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "px-4" }, [_vm._t("default")], 2),
          ]
        : [_c("g-error-c401")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }