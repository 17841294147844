var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g-context-card", {
    attrs: { title: _vm.$t("general.filters") },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "py-2" },
              [
                _c("g-form-base", {
                  ref: "form",
                  attrs: {
                    valid: _vm.valid,
                    fields: _vm.fields,
                    record: _vm.record,
                    "auto-focus": "",
                  },
                  on: {
                    "update:valid": function ($event) {
                      _vm.valid = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "", left: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var attrs = ref.attrs
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticStyle: {
                                      "text-transform": "none !important",
                                    },
                                    attrs: { text: "" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { color: "primary" } }, [
                                  _vm._v(
                                    "\n              mdi-plus\n            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "h4",
                                  { staticClass: "ml-2 primary--text" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.add_filter")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "v-list",
                      { attrs: { dense: "" } },
                      [
                        !_vm.unfilteredFields.length
                          ? _c("v-list-item", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("general.no_fields_available")
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.unfilteredFields, function (field, i) {
                          return _c(
                            "v-list-item",
                            {
                              key: i,
                              on: {
                                click: function ($event) {
                                  return _vm.onAddFilter(field)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(" " + _vm._s(field.text)),
                              ]),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }