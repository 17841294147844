var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("g-field-filter-label", {
        attrs: {
          label: _vm.label,
          operator: _vm.innerOperator,
          operators: _vm.operators,
        },
        on: {
          "update:operator": function ($event) {
            _vm.innerOperator = $event
          },
          "click:remove": _vm.onRemove,
        },
      }),
      _vm._v(" "),
      _c("v-select", {
        attrs: {
          dense: "",
          outlined: "",
          label: _vm.$t("general.value"),
          items: _vm.items,
          "error-messages": _vm.errorMessages,
        },
        on: { input: _vm.onInput },
        model: {
          value: _vm.innerValue,
          callback: function ($$v) {
            _vm.innerValue = $$v
          },
          expression: "innerValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }