<template>
  <span>
    {{ formatted }}
  </span>
</template>
<script>
import { computed, defineComponent, toRefs } from '@vue/composition-api';
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  props: {
    field: {
      type: String,
      required: true
    },

    item: {
      type: Object,
      required: true
    }
  },

  setup (props) {
    const i18n = useI18n();
    const { field, item } = toRefs(props);

    const formatted = computed(() => {
      return i18n.n(item.value[field.value], 'currency');
    });

    return {
      formatted
    };
  }
});
</script>
