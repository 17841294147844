import axios from 'axios';

export async function useFetch (url, opts) {
  const method = opts?.method?.toLowerCase();

  let fn;

  switch (method) {
    case 'post':
      fn = axios.post;
      break;
    case 'put':
      fn = axios.put;
      break;
    case 'patch':
      fn = axios.patch;
      break;
    case 'delete':
      fn = axios.delete;
      break;
    case 'get':
    default:
      fn = axios.get;
      break;
  }

  const payload = opts?.body;
  const headers = opts?.headers;
  const signal = opts?.signal;

  const params = {};

  if (headers) { params.headers = headers; }
  if (signal) { params.signal = signal; }

  let data = null;
  let error = null;
  let status = 0;

  try {
    const response = await fn(url, payload, params);

    data = response.data;
    status = response.status;
  } catch (e) {
    data = [];
    error = e;

    // request has no status if cancelled
    if (e.code !== 'ERR_CANCELED') {
      status = e.response.status;
    }
  }

  return {
    status,
    error,
    data
  };
}

export default useFetch;
