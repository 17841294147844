<template>
  <div>
    <g-field-filter-label
      :label="label"
      :operator.sync="innerOperator"
      :operators="operators"
      @click:remove="onRemove"
    />

    <v-autocomplete
      v-model="innerValue"
      class="v-search-field"
      :loading="loading"
      :search-input.sync="search"
      dense
      outlined
      :label="$t('general.value')"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :placeholder="innerPlaceholder"
      return-object
      no-filter
      :error-messages="errorMessages"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
    />
  </div>
</template>
<script>
import { computed, defineComponent, ref, toRef, toRefs, watch } from '@vue/composition-api';
import { useI18n } from '~/composables/useI18n';
import { useSync } from '@/composables/useSync';
import { useStore } from '@/composables/use-stores';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },

    outlined: {
      type: Boolean,
      default: false
    },

    value: {
      type: String,
      default: null
    },

    storeid: {
      type: String,
      default: null
    },

    storeroute: {
      type: String,
      default: null
    },

    operator: {
      type: String,
      default: null
    },

    itemText: {
      type: String,
      default: 'text'
    },

    itemValue: {
      type: String,
      default: 'value'
    },

    placeholder: {
      type: String,
      default: null
    },

    minLength: {
      type: Number,
      default: 8
    },

    delay: {
      type: Number,
      default: 300
    },

    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const i18n = useI18n();

    const { delay, placeholder, itemText, itemValue } = toRefs(props);
    const operator = toRef(props, 'operator');
    const innerStore = useStore(props.storeid, props.storeroute)();

    const innerValue = useSync(props, 'value', emit);
    const innerOperator = ref('eq');

    const items = ref([]);
    const focused = ref(false);
    const search = ref(null);
    const loading = ref(false);
    const firstLoad = ref(true);
    const prevent = ref(false);
    let timeout = null;

    initvalue();

    const innerPlaceholder = computed(() => {
      return placeholder.value ? placeholder.value : i18n.t('general.type_to_search');
    });

    // hack to pass the operator out of the form
    if (operator.value) {
      innerOperator.value = operator.value;
    }

    const operators = computed(() => [
      {
        name: i18n.t('general.equal_to'),
        value: 'eq'
      },
      {
        name: i18n.t('general.not_equal_to'),
        value: 'ne'
      }
    ]);

    watch(innerOperator, onChangeOperator, { immediate: true });

    watch(search, (value) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      // prevent if empty
      if (value === null || !value.length || !innerStore) {
        return;
      }

      // prevents duplicate queries when selecting
      if (prevent.value) {
        prevent.value = false;

        return;
      }

      timeout = setTimeout(async () => {
        loading.value = true;

        const { rows } = await innerStore.read({
          filters: [
            {
              field: itemText.value,
              operator: 'iLike',
              value
            }
          ],
          itemsPerPage: 10
        });

        items.value = rows;

        loading.value = false;

        if (firstLoad.value) {
          firstLoad.value = false;
        }
      }, delay.value);
    });

    return {
      innerPlaceholder,

      search,
      loading,
      firstLoad,
      innerOperator,
      innerValue,
      operators,

      focused,
      items,

      onInput,
      onBlur,
      onFocus,
      onRemove
    };

    async function initvalue () {
      if (innerValue.value) {
        const { rows } = await innerStore.read({
          filters: [
            {
              field: itemValue.value,
              operator: 'eq',
              value: innerValue.value
            }
          ],
          itemsPerPage: 10
        });

        items.value = rows;

        emit('change:value', {
          value: innerValue.value,
          delayed: false
        });

        focused.value = false;
      }
    }

    function onInput (obj) {
      // first event is required for the form + validations
      prevent.value = true;

      const value = obj ? obj[itemValue.value] : null;

      innerValue.value = value;

      emit('input', value);

      emit('change:value', {
        value,
        delayed: false
      });
    }

    function onBlur (value) {
      emit('blur', value);

      focused.value = false;
    }

    function onFocus (value) {
      emit('focus', value);

      focused.value = true;
    }

    function onRemove () {
      emit('remove');
    }

    function onChangeOperator (value) {
      emit('change:operator', value);
    }
  }
});
</script>
<style>
.v-search-field .v-input__append-inner {
  margin-top: 14px !important;
  opacity: 0.6;
}

.v-search-field .v-input__icon.v-input__icon--clear {
  margin-top: 2px !important;
}

.v-search-field.v-input--is-focused .v-input__append-inner {
  opacity: 0.8 !important;
}
</style>
