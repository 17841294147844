<template>
  <div class="d-flex flex-column justify-center align-center" style="height: 100%;">
    <v-btn
      icon
      class="bg-shadow"
      width="80"
      height="80"
      :ripple="false"
      @click.prevent
    >
      <v-icon color="error" style="font-size: 56px;">
        mdi-alert-circle-outline
      </v-icon>
    </v-btn>

    <h1 class="mt-4">
      {{ title }}
    </h1>

    <h4 class="font-weight-medium mt-3 text--secondary">
      {{ subtitle }}
    </h4>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  setup () {
    const i18n = useI18n();

    const title = ref(i18n.t('general.unexpected_error_title'));
    const subtitle = ref(i18n.t('general.unexpected_error_subtitle'));

    return {
      title,
      subtitle
    };
  }
});
</script>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
