<template>
  <v-app style="width: 100%">
    <g-error-base
      v-if="not404"
      :title="title"
      :subtitle="subtitle"
      color="error"
      icon="mdi-alert-circle-outline"
      link
      :link-text="linkText"
      link-icon="mdi-chevron-left"
      @click:link="onClickLink"
    />

    <g-error-base
      v-else
      :title="$t('page_404.oops')"
      :subtitle="$t('page_404.page_not_found')"
      color="primary"
      icon="mdi-sign-direction-remove"
      icon-size="44"
      link
      :link-text="linkText"
      link-icon="mdi-home-outline"
      @click:link="onClickLink"
    />
  </v-app>
</template>

<script>
import { defineComponent, toRef } from '@vue/composition-api';
import { useRouter } from '@nuxtjs/composition-api';
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  props: {
    error: {
      type: Object,
      default: null
    }
  },

  setup (props) {
    const i18n = useI18n();
    const router = useRouter();

    const error = toRef(props, 'error');

    const not404 = error.value && error.value.statusCode !== 404;

    const title = i18n.t('general.unexpected_error_title');
    const subtitle = i18n.t('general.unexpected_error_subtitle');
    const linkText = i18n.t('general.go_back');

    return {
      title,
      subtitle,
      linkText,
      not404,

      onClickLink
    };

    function onClickLink () {
      router.go(-1);
    }
  }
});
</script>
