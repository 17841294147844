function gfield (component, field, params, rules, events, ignore = false) {
  const required = rules ? rules.includes('required') : false;

  if (required && params.label) {
    params.class = 'required-field';
  }

  if (component === 'v-select' || component === 'v-combobox' || component === 'g-search-field') {
    params['menu-props'] = {
      transition: 'slide-y-transition',
      'offset-y': true
    };
    params.clearable = true;
  }

  const norender = params.norender ? params.norender : { value: false };

  return {
    component,
    params,
    field,
    rules,
    events,
    ignore,
    norender
  };
}

function gtextfield (field, label, rules = 'required', ignore = false) {
  return gfield('v-text-field', field, {
    label,
    clearable: true
  }, rules, null, ignore);
}

function gselect (field, fieldParams, extraParams = {}, events = {}, ignore = false) {
  const { rules, store, query } = extraParams;

  if (store) {
    fieldParams.loading = store.loading;
    fieldParams.error = store.error;
    fieldParams.items = store.rows;

    if (query) {
      // TODO: this works for a fresh form
      //       but will not show the value
      //       on editing mode
      // events.focus = () => store.read(query);
    }
  }

  return gfield('v-select', field, fieldParams, rules, events, ignore);
}

export function useFields () {
  return {
    gfield,
    gtextfield,
    gselect
  };
}
