<template>
  <g-error-base
    color="error"
    icon="mdi-alert-circle-outline"
    :title="title"
    :subtitle="subtitle"
    :link="false"
    :link-text="$t('general.go_back')"
    link-icon="mdi-chevron-left"
    @click:link="onGoBack"
  />
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import { useRouter } from '@nuxtjs/composition-api';
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  setup () {
    const i18n = useI18n();
    const router = useRouter();

    const title = ref(i18n.t('general.unauthorized'));
    const subtitle = ref(i18n.t('general.unauthorized_error_message'));

    return {
      title,
      subtitle,

      onGoBack
    };

    function onGoBack () {
      router.go(-1);
    }
  }
});
</script>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
