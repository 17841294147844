<template>
  <v-app>
    <client-only>
      <template v-if="!ready">
        Loading...
      </template>

      <template v-else>
        <g-drawer-navigation />

        <v-main>
          <v-container fluid class="pa-0 fill-height">
            <Nuxt />
          </v-container>
        </v-main>

        <g-drawer-modal />

        <g-drawer-context />

        <g-toolbar-mobile />

        <g-overlay-base />

        <g-overlay-loading />

        <g-toast-base />
      </template>
    </client-only>
  </v-app>
</template>
<script>
import { computed } from 'vue-demi';
import { useApp } from '@/stores/system/app';

export default {
  setup () {
    const appStore = useApp();

    const ready = computed(() => appStore.ready);

    return {
      ready
    };
  }
};
</script>
