import Vue from 'vue';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { max, required, email, min, digits, integer, double } from 'vee-validate/dist/rules';

Vue.component('VValidationProvider', ValidationProvider);
Vue.component('VValidationObserver', ValidationObserver);

export default ({ i18n }) => {
  extend('required', {
    ...required,
    message: (field, values) => i18n.t('validation.required')
  });

  extend('min', {
    ...min,
    message: (field, values) => i18n.t('validation.string_min_len', values)
  });

  extend('max', {
    ...max,
    message: (field, values) => i18n.t('validation.string_max_len', values)
  });

  extend('email', {
    ...email,
    message: (field, values) => i18n.t('validation.invalid_email')
  });

  extend('matchwith', {
    params: ['target'],
    validate (value, { target }) { return value === target; },
    message: (field, values) => i18n.t('validation.field_not_match')
  });

  extend('notmatchwith', {
    params: ['target'],
    validate (value, { target }) {
      return value !== target;
    },
    message: (field, values) => i18n.t('validation.field_cannot_match')
  });

  extend('has-uppercase', {
    validate (value) { return value.match(/[A-Z]/g); },
    message: (field, values) => i18n.t('validation.require_uppercase_letter')
  });

  extend('has-lowercase', {
    validate (value) { return value.match(/[a-z]/g); },
    message: (field, values) => i18n.t('validation.require_lowercase_letter')
  });

  extend('has-number', {
    validate (value) { return value.match(/[0-9]/g); },
    message: (field, values) => i18n.t('validation.require_one_number')
  });

  extend('digits', {
    ...digits,
    message: (field, values) => i18n.t('validation.number_len', values)
  });

  extend('integer', {
    ...integer,
    message: (field, values) => i18n.t('validation.require_valid_integer')
  });

  extend('double', {
    ...double,
    message: (field, values) => i18n.t('validation.require_valid_floating')
  });

  extend('required-if-false', {
    params: ['target'],
    validate (value, { target }) {
      return target === false;
    },
    message: (field, values) => i18n.t('validation.required')
  });

  extend('required-if-true', {
    params: ['target'],
    validate (value, { target }) {
      return target === true;
    },
    message: (field, values) => i18n.t('validation.required')
  });

  extend('req-list', {
    // https://vee-validate.logaretm.com/v2/guide/custom-rules.html#require-like-rules
    computesRequired: true,
    params: ['operator', 'n'],
    validate (value, { operator, n }) {
      if (!Array.isArray(value)) {
        return false;
      }

      const len = isNaN(n) || n === null ? 0 : parseInt(n);

      let valid = false;

      switch (operator) {
        case 'lteq':
          valid = value.length <= len;
          break;
        case 'lt':
          valid = value.length < len;
          break;
        case 'gteq':
          valid = value.length >= len;
          break;
        case 'gt':
          valid = value.length > len;
          break;
        case 'eq':
        default:
          valid = value.length === len;
          break;
      }

      return valid;
    },
    message: (field, { operator, n }, data) => {
      const len = isNaN(n) || n === null ? 0 : parseInt(n);

      let opStr = '';

      switch (operator) {
        case 'lteq':
          opStr = i18n.t('validation.lteq');
          break;
        case 'lt':
          opStr = i18n.t('validation.lt');
          break;
        case 'gteq':
          opStr = i18n.t('validation.gteq');
          break;
        case 'gt':
          opStr = i18n.t('validation.gt');
          break;
        case 'eq':
        default:
          opStr = i18n.t('validation.eq');
          break;
      }

      return i18n.t('validation.number_of_items', {
        operator: opStr.toLowerCase(),
        length: len
      });
    }
  });

  extend('no-list-duplicates', {
    // https://vee-validate.logaretm.com/v2/guide/custom-rules.html#require-like-rules
    // computesRequired: true,
    params: ['field'],
    validate (value, { field }) {
      if (!Array.isArray(value)) {
        return false;
      }

      const dictionary = {};
      let valid = true;

      for (let i = 0; i < value.length; i++) {
        const el = value[i];

        const val = el[field];

        if (dictionary[val]) {
          valid = false;
          break;
        }

        dictionary[val] = 1;
      }

      return valid;
    },
    message: (field, { operator, n }, data) => {
      return i18n.t('validation.duplicate_items');
    }
  });
  extend('min-date', {
    params: ['date'],
    validate (value, { date }) {
      const validMin = value ? value >= date : true;
      return validMin;
    },
    message: (field, { date }, data) => {
      return i18n.t('validation.min_date', {
        date: new Date(date).toLocaleDateString().slice(0, 10)
      });
    }
  });
  extend('max-date', {
    params: ['date'],
    validate (value, { date }) {
      const validMax = value ? value <= date : true;
      return validMax;
    },
    message: (field, { date }, data) => {
      return i18n.t('validation.max_date', {
        date: new Date(date).toLocaleDateString().slice(0, 10)
      });
    }
  });
};
