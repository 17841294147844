var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mobile
    ? _c(
        "v-bottom-navigation",
        { attrs: { absolute: "", color: "primary" } },
        [
          _c(
            "v-btn",
            { on: { click: _vm.onOpenNavigation } },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("general.menu")))]),
              _vm._v(" "),
              _c("v-icon", [_vm._v("mdi-menu")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            { on: { click: _vm.onOpenTools } },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("general.tools")))]),
              _vm._v(" "),
              _c("v-icon", [_vm._v("mdi-wrench")]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { to: "/user/profile" } },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("general.profile")))]),
              _vm._v(" "),
              _c("v-icon", [_vm._v("mdi-account")]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }