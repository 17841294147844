
import { useUser } from '@/stores/auth/user';
import { useRoutes } from '@/stores/auth/routes';

export default ({ route, redirect, $config }) => {
  console.log('[Router] ', route.fullPath);

  const { HOME_ROUTE } = $config;

  const userStore = useUser();
  const routesStore = useRoutes();

  if (route.path === '/404') {
    return;
  }

  if (routesStore.isPublicRoute(route.path)) {
    if (!userStore.authenticated) {
      return;
    } else {
      return redirect(HOME_ROUTE);
    }
  }

  const redirectTo = route.fullPath === '/' ? HOME_ROUTE : route.fullPath;

  if (process.client) {
    // User has access
    const routeExists = routesStore.checkAccess(route.path);

    if (routeExists) {
      if (!userStore.authenticated) {
        return redirect(`/login?redirect=${encodeURIComponent(redirectTo)}`);
      }
      // to.fullPath;
      if (route.path === '/admin/') {
        return redirect('/admin/users');
      }
    } else {
      // Route not found
      return redirect('/404');
    }
  } else {
    return redirect(`/login?redirect=${encodeURIComponent(redirectTo)}`);
  }
};
