var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-overlay",
    {
      attrs: { absolute: "", "z-index": "150" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { light: "", "max-width": "400px", "min-width": "200px" } },
        [
          _c("v-card-title", { staticClass: "d-flex justify-center" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.title || _vm.$t("general.loading")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-progress-linear", {
                attrs: {
                  indeterminate: "",
                  color: "primary",
                  height: "8",
                  rounded: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }