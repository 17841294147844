<template>
  <v-chip
    outlined
    :label="label"
    :color="color"
    class="bg-shadow"
    style="border: none !important"
  >
    <v-icon v-if="icon" left>
      {{ icon }}
    </v-icon>
    <span style="opacity: 0.7">
      {{ text }}
    </span>
  </v-chip>
</template>
<script>
import { computed, defineComponent, toRefs } from '@vue/composition-api';

export default defineComponent({
  props: {
    field: {
      type: String,
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    iconFn: {
      type: Function,
      default: () => null
    },

    colorFn: {
      type: Function,
      default: () => 'primary'
    },

    textFn: {
      type: Function,
      default: () => null
    },

    outlined: {
      type: Boolean,
      default: false
    },

    label: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { item, field, colorFn, iconFn, textFn } = toRefs(props);

    const innerValue = computed(() => item.value[field.value]);

    const icon = computed(() => iconFn.value(innerValue.value, item.value, field.value));
    const color = computed(() => colorFn.value(innerValue.value, item.value, field.value));
    const text = computed(() => textFn.value(innerValue.value, item.value, field.value));

    return {
      icon,
      color,
      text
    };
  }
});
</script>
<style>
.v-chip.bg-shadow::before {
  opacity: 0.12 !important;
}
</style>
