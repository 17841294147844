<template>
  <v-navigation-drawer
    v-model="showMobile"
    :right="!mobile"
    :permanent="!mobile"
    :fixed="mobile"
    :absolute="mobile"
    :width="width"
    :class="{ 'grey--background lighten-5': !dark }"
    style="z-index: 4"
    app
  >
    <div
      class="d-flex fill-height"
    >
      <!-- <v-navigation-drawer
        mini-variant
        right
        :permanent="showMobile"
        style="min-width: 56px;"
        :class="{ 'grey--background lighten-5': !dark }"
      > -->
      <v-list
        dense
        nav
        width="54"
      >
        <v-list-item-group
          v-model="selected"
          :mandatory="mobile"
          color="primary"
          class="d-flex flex-column"
          style="height: 100%"
        >
          <template v-for="(tool, i ) in tools">
            <v-tooltip
              :key="i"
              left
            >
              <template #activator="{ on, attrs }">
                <v-list-item
                  v-show="allowed.includes(tool.id)"
                  :style="!tool.spacer ? 'max-height: 48px' : ''"
                  :disabled="tool.spacer"
                  :value="i"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-action>
                    <v-badge
                      v-if="badges[tool.id]"
                      :content="badges[tool.id]"
                      color="primary"
                      overlap
                    >
                      <v-icon>
                        {{ tool.icon }}
                      </v-icon>
                    </v-badge>

                    <v-icon v-else>
                      {{ tool.icon }}
                    </v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <!-- <v-list-item-title> {{ tool.title }}</v-list-item-title> -->
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span>{{ tool.tooltip }}</span>
            </v-tooltip>
          </template>
        </v-list-item-group>
      </v-list>
      <!-- </v-navigation-drawer> -->

      <!-- <v-expand-x-transition> -->
      <div v-show="show || mobile" :style="mobile ? 'flex: 1;' : 'width: 300px;'">
        <component
          :is="cmp"
          :class="{ 'grey--background lighten-5': !dark }"
          :tool-id="toolId"
        />
      </div>
      <!-- </v-expand-x-transition> -->
    </div>
  </v-navigation-drawer>
</template>
<script>
import { useContext } from '@nuxtjs/composition-api';
import { computed, defineComponent, onUnmounted, ref, watch } from '@vue/composition-api';
import { usePresentationState } from '@/stores/system/presentation-state';
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  setup () {
    const { $vuetify, $eventBus } = useContext();
    const i18n = useI18n();
    const stateStore = usePresentationState();

    const show = ref(false);
    const cmp = ref(null);
    const selected = ref(null);
    const toolId = ref(null);
    const badges = ref({});

    const tools = ref([
      {
        id: 'presentation',
        icon: 'mdi-presentation',
        cmp: 'g-context-presentation',
        tooltip: i18n.t('general.presentation')
      },
      {
        id: 'filters',
        icon: 'mdi-filter-variant',
        cmp: 'g-context-filters',
        tooltip: i18n.t('general.filters')
      },
      {
        id: 'export',
        icon: 'mdi-file-excel-outline',
        cmp: 'g-context-export',
        tooltip: i18n.t('general.export_data')
      },
      {
        id: 'spacer',
        spacer: true
      },
      {
        id: 'groups',
        icon: 'mdi-account-group-outline',
        cmp: 'g-context-groups',
        tooltip: i18n.t('general.user_groups')
      },
      {
        id: 'bookmarks',
        icon: 'mdi-bookmark-multiple-outline',
        cmp: 'g-context-bookmark',
        tooltip: i18n.t('general.bookmarks')
      },
      {
        id: 'settings',
        icon: 'mdi-cog-outline',
        cmp: 'g-context-settings',
        tooltip: i18n.t('general.settings')
      },
      {
        id: 'help',
        icon: 'mdi-help-circle-outline',
        cmp: 'g-context-help',
        tooltip: i18n.t('general.help')
      }
    ]);

    const allowed = ref([]);

    const mobile = computed(() => $vuetify.breakpoint.mobile);
    const showMobile = ref(false);
    const width = computed(() => {
      if (mobile.value) {
        return '400';
      }

      return show.value ? '354' : '54';
    });
    const dark = computed(() => $vuetify.theme.dark);

    $eventBus.$on('open:tools', () => {
      show.value = true;
      selected.value = 0;
      showMobile.value = !showMobile.value;
    });

    $eventBus.$on('close:tools', () => {
      show.value = false;
      showMobile.value = false;
      if (selected.value) {
        selected.value = null;
      }
    });

    watch(mobile, (newMobile, oldMobile) => {
      if (newMobile && oldMobile !== newMobile) {
        showMobile.value = false;
        show.value = false;
        selected.value = null;
      }
    });

    watch(selected, (newIdx, oldIdx) => {
      if (typeof newIdx !== 'number') {
        show.value = false;

        cmp.value = null;

        toolId.value = null;
      } else if (newIdx !== oldIdx) {
        cmp.value = tools.value[newIdx].cmp;

        toolId.value = tools.value[newIdx].id;

        show.value = true;
      }

      // On change selected, save to state
      stateStore.activeState.activeTool = newIdx;
    });

    watch(() => stateStore.activeState, (state) => {
      const newType = state?.type;
      const activeTool = state?.activeTool;

      switch (newType) {
        case 'gallery':
          allowed.value = [
            'settings',
            'groups',
            'presentation',
            'filters',
            // 'export',
            'bookmarks',
            'help',
            'spacer'
          ];
          break;
        case 'tabular':
          allowed.value = [
            'settings',
            'groups',
            'presentation',
            'filters',
            'export',
            'bookmarks',
            'help',
            'spacer'
          ];
          break;
        default:
          allowed.value = [
            'settings',
            'groups',
            'bookmarks',
            'help',
            'spacer'
          ];
          break;
      }

      if (show.value) {
        if (!tools.value.length) {
          // close window to avoid showing destroyed panels
          selected.value = null;
        } else {
          // set selected to the stored active or the previously selection
          // fallback to the first visible tool
          selected.value = safellyActivate(activeTool) || safellyActivate(selected.value) || findFirstVisible();

          // safellyActive && findFirstVisible return idx + 1 to simplify the || chain condition
          if (selected.value > 0) {
            selected.value--;
          } else {
            selected.value = null;
            show.value = false;
          }
        }
      }
    }, { immediate: true });

    watch(() => stateStore.activeState, (value) => {
      badges.value = { ...value.badges };
    }, { deep: true });

    onUnmounted(() => {
      const { $eventBus } = useContext();

      $eventBus.$off('open:tools');
      $eventBus.$off('close:tools');
    });

    return {
      show,
      showMobile,
      selected,
      allowed,
      width,
      cmp,
      toolId,
      tools,
      mobile,
      dark,
      badges,

      toggleDrawer
    };

    function toggleDrawer (newCmp) {
      if (!show.value) {
        show.value = true;
        cmp.value = newCmp;
      } else if (newCmp !== cmp.value) {
        cmp.value = newCmp;
      } else {
        show.value = false;
      }
    }

    function safellyActivate (idx) {
      if (idx === undefined || idx === null) {
        return 0;
      }

      const tool = tools.value[idx];

      return allowed.value.includes(tool.id) ? idx + 1 : 0;
    }

    function findFirstVisible () {
      let idx = -1;

      for (let i = 0; i < tools.value.length; i++) {
        const tool = tools.value[i];

        if (tool.id === 'spacer') {
          continue;
        }

        if (allowed.value.includes(tool.id)) {
          idx = i;
          break;
        }
      }

      return idx + 1;
    }
  }
});
</script>
<style scoped>
.grey--background.lighten-5 {
  background: #FAFAFA;
}
</style>
