var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column justify-center align-center",
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "bg-shadow",
          attrs: { icon: "", width: "80", height: "80", ripple: false },
          on: {
            click: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "v-icon",
            {
              style: "font-size: " + _vm.iconSize + "px;",
              attrs: { color: _vm.color },
            },
            [_vm._v("\n      " + _vm._s(_vm.icon) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("h1", { class: "mt-4 " + _vm.color + "--text" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
      ]),
      _vm._v(" "),
      _c("h4", { staticClass: "font-weight-medium mt-3 text--secondary" }, [
        _vm._v("\n    " + _vm._s(_vm.subtitle) + "\n  "),
      ]),
      _vm._v(" "),
      _vm.link
        ? _c(
            "v-btn",
            {
              staticClass: "mt-5",
              attrs: { color: "primary" },
              on: { click: _vm.onClickLink },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("\n      " + _vm._s(_vm.linkIcon) + "\n    "),
              ]),
              _vm._v("\n    " + _vm._s(_vm.linkText) + "\n  "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }