<template>
  <v-combobox
    v-model="vModel"
    :value="vModel"
    :items="items"
    :clearable="clearable"
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    :readonly="readonly"
    :disabled="disabled"
    :multiple="multiple"
    :chips="chips"
    :small-chips="smallChips"
    :hint="hint"
    outlined
    :persistent-hint="persistentHint"
    :error-messages="errorMessages"
    @input="onInput"
  />
</template>
<script>
import { defineComponent, ref, toRef } from '@vue/composition-api';
import { useSync } from '@/composables/useSync';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },

    value: {
      default: null
    },

    modelValue: {
      default: null
    },

    items: {
      type: Array,
      default: () => []
    },

    itemText: {
      type: String,
      default: 'text'
    },

    itemValue: {
      type: String,
      default: 'value'
    },

    readonly: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    multiple: {
      type: Boolean,
      default: false
    },

    chips: {
      type: Boolean,
      default: false
    },

    smallChips: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: true
    },

    hint: {
      type: String,
      default: null
    },

    persistentHint: {
      type: Boolean,
      default: false
    },

    errorMessages: {
      type: Array,
      default: () => []
    }
  },

  setup (props, { emit }) {
    const innerValue = useSync(props, 'value', emit);
    const innerModelValue = useSync(props, 'modelValue', emit);
    const innerErrorMessages = useSync(props, 'errorMessages', emit);

    const innerItemValue = toRef(props, 'itemValue');
    const inneritemText = toRef(props, 'itemText');

    const vModel = ref(null);

    return {
      innerValue,
      innerModelValue,
      vModel,
      innerErrorMessages,

      innerItemValue,
      inneritemText,

      onInput
    };

    function onInput (obj) {
      if (!vModel.value) {
        vModel.value = [];
      }

      if (obj.length && obj.length > 0) {
        for (let i = 0; i < obj.length; i++) {
          if (obj[i] && typeof obj[i] !== 'object') {
            const aux = obj[i];

            obj[i] = {};
            obj[i][inneritemText.value] = `s_${i} ( ${aux} )`;
            obj[i][`raw${innerItemValue.value}`] = aux;
            obj[i][innerItemValue.value] = aux + i;
          } else if (!obj[i][`raw${innerItemValue.value}`]) {
            obj[i][`raw${innerItemValue.value}`] = obj[i][innerItemValue.value];
            obj[i][innerItemValue.value] = obj[i][innerItemValue.value] + i;
          }
        }
      }

      emit('input', obj);
    }
  }

});
</script>
<style>
</style>
