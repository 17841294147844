<template>
  <v-list-item-content
    style="display: block;"
    @click="onClick"
  >
    <v-list-item-title>
      {{ item['Entity.name'] }}
    </v-list-item-title>
    <v-list-item-subtitle>
      {{ $t('general.action') }}: {{ item.action }} | {{ $t('general.possession') }}: {{ item.possession }}
    </v-list-item-subtitle>
  </v-list-item-content>
</template>
<script>
import { defineComponent, toRef } from '@vue/composition-api';

export default defineComponent({
  props: {
    item: {
      default: () => { return {}; }
    }
  },

  setup (props) {
    const item = toRef(props, 'item');

    return {
      onClick
    };

    function onClick (event) {
      if (item.value.tem_alerta) {
        // event.stopPropagation();
      }
    }
  }
});
</script>
