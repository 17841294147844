import { useStore, GET_ONLY } from '@/composables/use-stores';

const id = 'auth-permission';
const route = 'user/active-permission';

export const usePermissions = useStore(id, route, {
  actions: GET_ONLY,
  extend: {
    can (action, entity) {
      return this.rows.findIndex(r => r.action === action && r.entity === entity) > -1;
    }
  }
});
