var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "primary--text d-flex flex-column justify-center mx-4 py-2",
    },
    [
      _c(
        "div",
        {
          staticClass: "v-card__title px-2 pt-1 pb-2 text-h6 font-weight-bold",
        },
        [_vm._v("\n    " + _vm._s(_vm.PLATFORM_TITLE) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.PLATFORM_SUBTITLE
        ? _c(
            "div",
            {
              staticClass:
                "v-card__subtitle px-2 py-1 font-weight-medium subtitle-2 text--secondary",
              staticStyle: { opacity: "0.8" },
            },
            [_vm._v("\n    " + _vm._s(_vm.PLATFORM_SUBTITLE) + "\n  ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }