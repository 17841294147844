var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-content",
    {
      ref: "contentRef",
      attrs: {
        entity: _vm.entity,
        title: _vm.title,
        "has-update": _vm.allowUpdate,
        "has-delete": _vm.allowDelete,
        "has-archive": _vm.allowArchive,
        "has-export": _vm.allowExport,
        "disable-update": _vm.loading || _vm.notFound,
        "disable-delete": _vm.loading || _vm.notFound,
        "disallow-create": _vm.disallowCreate,
        "disallow-update": _vm.disallowUpdate,
        "disallow-delete": _vm.disallowDelete,
        scrollable: "",
      },
      on: {
        "click:update": _vm.onClickUpdate,
        "click:delete": _vm.onClickDelete,
        "click:export": _vm.onClickExport,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "header-tools",
            fn: function (headerParams) {
              return [_vm._t("header-tools", null, null, headerParams)]
            },
          },
          !_vm.loading && !_vm.notFound
            ? {
                key: "headline",
                fn: function (headlineParams) {
                  return [_vm._t("headline", null, null, headlineParams)]
                },
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center flex-column",
              staticStyle: { height: "calc(100vh - 176px)" },
            },
            [
              _c(
                "v-card",
                { attrs: { width: "300px", flat: "" } },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "d-flex justify-center text-center",
                      staticStyle: { "word-break": "keep-all" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.loading_details")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c("v-progress-linear", {
                        attrs: {
                          indeterminate: "",
                          color: "primary",
                          height: "8",
                          rounded: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.notFound
        ? _c(
            "div",
            {
              staticClass: "d-flex justify-center align-center flex-column",
              staticStyle: { height: "calc(100vh - 176px)" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "bg-shadow",
                  attrs: { icon: "", width: "70", height: "70", ripple: false },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticStyle: { "font-size": "46px" },
                      attrs: { color: "error" },
                    },
                    [_vm._v("\n        mdi-alert-circle-outline\n      ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("h2", { staticClass: "mt-4" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("general.not_found")) + "\n    "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-fade-transition",
        [
          !_vm.loading && !_vm.notFound
            ? _c(
                "v-row",
                { staticClass: "py-4" },
                [
                  _c(
                    "template",
                    { slot: "components" },
                    [_vm._t("components")],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { xs: "12", sm: "12", md: "6", lg: "4" } },
                    [
                      _vm.summary
                        ? _c(
                            "v-card",
                            {
                              attrs: {
                                color: !_vm.dark ? "grey lighten-4" : "",
                              },
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "g-avatar-profile",
                                    _vm._b(
                                      {},
                                      "g-avatar-profile",
                                      _vm.summary.avatar,
                                      false
                                    )
                                  ),
                                  _vm._v(" "),
                                  _c("g-card-short-detail", {
                                    attrs: { items: _vm.summary.items },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.links.length
                        ? _c(
                            "v-card",
                            { staticClass: "mt-6", attrs: { rounded: "" } },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mx-4",
                                      attrs: { color: "primary", left: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n              mdi-link\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("general.quick_access")) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-list",
                                    { attrs: { rounded: "" } },
                                    _vm._l(_vm.links, function (item, i) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: i,
                                          staticClass: "my-2",
                                          attrs: { to: item.url, exact: "" },
                                          on: {
                                            click: function ($event) {
                                              item.j = 1
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "mr-4 pa-3 transparent",
                                              attrs: { rounded: "", flat: "" },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(item.icon) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text--secondary subtitle-1",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item.text) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "subtitle-1 text-decoration-underline",
                                              staticStyle: {
                                                "text-underline-offset": "4px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(item.value) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { xs: "12", sm: "12", md: "6", lg: "8" } },
                    [
                      _c(
                        "v-row",
                        _vm._l(
                          _vm.cards.filter(function (c) {
                            return !c.hide
                          }),
                          function (card, i) {
                            return _c(
                              "v-col",
                              { key: i, attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-card-title",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mx-3",
                                            attrs: {
                                              left: "",
                                              color: "primary",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(card.icon) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(card.title) +
                                            "\n\n                "
                                        ),
                                        _c("v-spacer"),
                                        _vm._v(" "),
                                        card.tools
                                          ? _vm._l(
                                              card.tools,
                                              function (tool, toolI) {
                                                return _c(
                                                  "v-btn",
                                                  {
                                                    key: toolI,
                                                    attrs: {
                                                      color: tool.color,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: tool.text,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(tool.icon) +
                                                            "\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(tool.text) +
                                                        "\n                  "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          card.component,
                                          _vm._g(
                                            _vm._b(
                                              {
                                                ref: "componentRef",
                                                refInFor: true,
                                                tag: "component",
                                              },
                                              "component",
                                              card.params,
                                              false
                                            ),
                                            card.events
                                          )
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }