var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-combobox", {
    attrs: {
      value: _vm.vModel,
      items: _vm.items,
      clearable: _vm.clearable,
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      label: _vm.label,
      readonly: _vm.readonly,
      disabled: _vm.disabled,
      multiple: _vm.multiple,
      chips: _vm.chips,
      "small-chips": _vm.smallChips,
      hint: _vm.hint,
      outlined: "",
      "persistent-hint": _vm.persistentHint,
      "error-messages": _vm.errorMessages,
    },
    on: { input: _vm.onInput },
    model: {
      value: _vm.vModel,
      callback: function ($$v) {
        _vm.vModel = $$v
      },
      expression: "vModel",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }