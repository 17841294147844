var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      staticStyle: { bottom: "20px", "z-index": "100 !important" },
      attrs: {
        app: "",
        absolute: "",
        bottom: "",
        outlined: "",
        text: "",
        timeout: _vm.timeout,
        type: _vm.message.type,
        color: _vm.message.color,
      },
      model: {
        value: _vm.message.show,
        callback: function ($$v) {
          _vm.$set(_vm.message, "show", $$v)
        },
        expression: "message.show",
      },
    },
    [
      _c("v-icon", { attrs: { left: "", color: _vm.message.color } }, [
        _vm._v("\n    " + _vm._s(_vm.message.icon) + "\n  "),
      ]),
      _vm._v("\n  " + _vm._s(_vm.message.text) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }