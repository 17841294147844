import { defineStore } from 'pinia';
import _ from 'lodash';
import { useFetch } from '@/composables/useFetch';

function matchRoute (current_route, { route, route_len, parent_route }) {
  // if the route supports slugs
  if (route.match(/:id\w*/g)) {
    const value = route.endsWith('/') ? route.slice(0, route.length - 1) : route;
    const regexStr = _.escape('^' + value.replace(/:id\w*/g, '[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}') + '/?$');

    const regex = new RegExp(regexStr);

    const matches = regex.exec(current_route);

    return matches && matches.length === 1;
  } else {
    return route === current_route || route + '/' === current_route;
  }
}

export const useRoutes = defineStore('routes', {
  state: () => ({
    loading: false,
    data: [],
    refresh: null
  }),

  getters: {
    routes: state => state.data,

    tree: (state) => {
      const routes = _.cloneDeep(state.data);

      if (!routes) {
        return [];
      }

      const parents = routes.filter(route => !route.parent_id);

      return parents
        .filter((parent) => {
          return parent.visible;
        })
        .map((parent) => {
          const childs = routes
            .filter(route => route.parent_id === parent.id && route.visible)
            .map((route) => {
              route.route = parent.route + route.route;

              return route;
            });

          parent.childs = childs;

          return parent;
        });
    },

    find (state) { return (field, value) => state.availableRoutes.find(row => row[field] === value); },

    findRoute (state) { return value => state.availableRoutes.find(row => matchRoute(value, row)); },

    availableRoutes: (state) => {
      const routes = _.cloneDeep(state.data);

      if (!routes) {
        return [];
      }

      // find childs of a node
      function findChilds (node) {
        return routes.filter(route => route.parent_id === node.id);
      }

      // recurse nodes to build the full route path
      function recurseChilds (nodes, depth) {
        nodes.forEach((node) => {
          const childs = findChilds(node);

          childs.forEach((child) => {
            child.parent_route = node.route; // base path
            child.route = node.route + child.route; // full path
            child.route_len = child.route.split('/').length; // full path length
            child.depth = depth;
          });

          recurseChilds(childs, depth + 1);
        });
      }

      // start with the parents
      const parents = routes.filter(route => !route.parent_id);

      recurseChilds(parents, 1);

      return routes;
    },

    // return true if route does not require a user logged in
    isPublicRoute: () => {
      return (path) => {
        return path === '/login' ||
          path === '/register' ||
          path === '/recover' ||
          path.startsWith('/register/confirmation') ||
          path.startsWith('/recover/change');
      };
    }
  },

  actions: {
    async read () {
      this.loading = true;

      const opts = {};

      const { data, refresh } = await useFetch('/api/auth/routes', opts);

      this.data = data;
      this.refresh = refresh;

      this.loading = false;
    },

    checkAccess: function (route) {
      return !!this.findRoute(route);
    },

    clean () {
      this.data = [];
    }
  }
});
