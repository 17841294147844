<template>
  <v-card
    flat
    class="scrollable-content"
    @scroll="onScroll"
  >
    <v-toolbar flat class="sticky-toolbar grey lighten-5">
      <v-toolbar-title>
        <span class="text-h5">{{ title }}</span>
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-fade-transition>
      <v-divider v-if="scrolled" class="sticky-divider" />
    </v-fade-transition>

    <v-card-text class="py-0">
      <slot />
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  setup () {
    const scrolled = ref(false);

    return {
      scrolled,
      onScroll
    };

    function onScroll ({ target: { scrollTop } }) {
      if (scrollTop > 0) {
        scrolled.value = true;
      } else {
        scrolled.value = false;
      }
    }
  }
});
</script>
<style scoped>
.scrollable-content {
  height: 100vh;
  overflow-y: scroll;
}

.sticky-toolbar {
  position: sticky;
  top: 0;
  z-index: 3;
}

.sticky-divider {
  position: sticky;
  top: 64px;
  z-index: 3;
}
</style>
