import { defineStore } from 'pinia';
import model from '@/stores/model';
import singleton from '@/stores/singleton_model';

export const GET_ACTION = 'get';
export const CREATE_ACTION = 'create';
export const READ_ACTION = 'read';
export const UPDATE_ACTION = 'update';
export const DELETE_ACTION = 'delete';
export const ALL_ACTIONS = Object.freeze([CREATE_ACTION, READ_ACTION, UPDATE_ACTION, DELETE_ACTION]);
export const READ_ONLY = Object.freeze([READ_ACTION]);
export const WRITE_ONLY = Object.freeze([CREATE_ACTION]);
export const EDIT_ONLY = Object.freeze([UPDATE_ACTION]);
export const GET_ONLY = Object.freeze([GET_ACTION]);

/**
 * @param {string} id
 * @param {string} route
 * @param {object} extra = { actions, extend, relationships, mutations }
 * @returns pinia store
 */
export function useSingleton (id, route, extra = {}) {
  const fields = Object.freeze({});
  const actions = extra.actions || ALL_ACTIONS;
  const extendActions = extra.extend || {};
  const relationships = extra.relationships || [];
  const dependencies = extra.dependencies || [];
  const mutations = extra.mutations || [];

  return defineStore(id, {
    state: () => ({
      ...singleton.state(
        route,
        fields,
        relationships,
        dependencies,
        mutations
      )
    }),

    getters: {
      ...singleton.getters()
    },

    actions: {
      ...extendActions,
      ...singleton.actions(actions)
    }
  });
}

/**
 * @param {string} id
 * @param {string} route
 * @param {object} extra = { actions, extend, relationships, mutations }
 * @returns pinia store
 */
export function useStore (id, route, extra = {}) {
  const fields = Object.freeze({});
  const actions = extra.actions || ALL_ACTIONS;
  const extendActions = extra.extend || {};
  const relationships = extra.relationships || [];
  const dependencies = extra.dependencies || [];
  const mutations = extra.mutations || [];

  return defineStore(id, {
    state: () => ({
      ...model.state(
        route,
        fields,
        relationships,
        dependencies,
        mutations
      )
    }),

    getters: {
      ...model.getters()
    },

    actions: {
      ...extendActions,
      ...model.actions(actions)
    }
  });
}
