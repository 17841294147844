import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0b6957b8 = () => interopDefault(import('../app/pages/404.vue' /* webpackChunkName: "pages/404" */))
const _7860b92e = () => interopDefault(import('../app/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2c041d67 = () => interopDefault(import('../app/pages/recover/index.vue' /* webpackChunkName: "pages/recover/index" */))
const _26fdad16 = () => interopDefault(import('../app/pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _483b643e = () => interopDefault(import('../app/pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _2b7e8d83 = () => interopDefault(import('../app/pages/admin/authority.vue' /* webpackChunkName: "pages/admin/authority" */))
const _35912c72 = () => interopDefault(import('../app/pages/admin/groups/index.vue' /* webpackChunkName: "pages/admin/groups/index" */))
const _1a6b4577 = () => interopDefault(import('../app/pages/admin/journal.vue' /* webpackChunkName: "pages/admin/journal" */))
const _15d59bfd = () => interopDefault(import('../app/pages/admin/roles.vue' /* webpackChunkName: "pages/admin/roles" */))
const _37457a8d = () => interopDefault(import('../app/pages/admin/sessions.vue' /* webpackChunkName: "pages/admin/sessions" */))
const _5277e36a = () => interopDefault(import('../app/pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _206b5c95 = () => interopDefault(import('../app/pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _89af6d9c = () => interopDefault(import('../app/pages/admin/groups/_id/index.vue' /* webpackChunkName: "pages/admin/groups/_id/index" */))
const _15070bb6 = () => interopDefault(import('../app/pages/admin/users/_id/index.vue' /* webpackChunkName: "pages/admin/users/_id/index" */))
const _160b004e = () => interopDefault(import('../app/pages/recover/change/_token.vue' /* webpackChunkName: "pages/recover/change/_token" */))
const _31d73258 = () => interopDefault(import('../app/pages/register/confirmation/_token.vue' /* webpackChunkName: "pages/register/confirmation/_token" */))
const _07f03cf8 = () => interopDefault(import('../app/pages/admin/users/_id/memberships/index.vue' /* webpackChunkName: "pages/admin/users/_id/memberships/index" */))
const _371bba74 = () => interopDefault(import('../app/pages/admin/users/_id/memberships/_idm/index.vue' /* webpackChunkName: "pages/admin/users/_id/memberships/_idm/index" */))
const _0bafff5c = () => interopDefault(import('../app/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _30c60340 = () => interopDefault(import('../app/modules/ui/pages/report/index.vue' /* webpackChunkName: "app/modules/ui/pages/report/index" */))
const _75489008 = () => interopDefault(import('../app/modules/ui/pages/seafarers/index.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/index" */))
const _f3ccdddc = () => interopDefault(import('../app/modules/ui/pages/users/index.vue' /* webpackChunkName: "app/modules/ui/pages/users/index" */))
const _15f3e334 = () => interopDefault(import('../app/modules/ui/pages/brands/hull-brand/index.vue' /* webpackChunkName: "app/modules/ui/pages/brands/hull-brand/index" */))
const _247082f8 = () => interopDefault(import('../app/modules/ui/pages/brands/motor-brand/index.vue' /* webpackChunkName: "app/modules/ui/pages/brands/motor-brand/index" */))
const _e07e3016 = () => interopDefault(import('../app/modules/ui/pages/config/calendar/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/calendar/index" */))
const _2b075954 = () => interopDefault(import('../app/modules/ui/pages/config/equipment-category/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/equipment-category/index" */))
const _0e282d1e = () => interopDefault(import('../app/modules/ui/pages/config/escalation-type/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/escalation-type/index" */))
const _a5d0abd2 = () => interopDefault(import('../app/modules/ui/pages/config/license-groups/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/license-groups/index" */))
const _855f6258 = () => interopDefault(import('../app/modules/ui/pages/config/lists/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/lists/index" */))
const _1297ae1f = () => interopDefault(import('../app/modules/ui/pages/config/operation-areas/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/operation-areas/index" */))
const _0c3daced = () => interopDefault(import('../app/modules/ui/pages/config/record-status/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/record-status/index" */))
const _75dc61b6 = () => interopDefault(import('../app/modules/ui/pages/config/rules/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/rules/index" */))
const _27779f78 = () => interopDefault(import('../app/modules/ui/pages/config/templates/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/templates/index" */))
const _fa80733a = () => interopDefault(import('../app/modules/ui/pages/config/vessel-activities/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/vessel-activities/index" */))
const _3d8b8122 = () => interopDefault(import('../app/modules/ui/pages/geography/captaincies/index.vue' /* webpackChunkName: "app/modules/ui/pages/geography/captaincies/index" */))
const _a534f732 = () => interopDefault(import('../app/modules/ui/pages/geography/counties/index.vue' /* webpackChunkName: "app/modules/ui/pages/geography/counties/index" */))
const _4a9d656b = () => interopDefault(import('../app/modules/ui/pages/geography/countries/index.vue' /* webpackChunkName: "app/modules/ui/pages/geography/countries/index" */))
const _5cc7901c = () => interopDefault(import('../app/modules/ui/pages/geography/districts/index.vue' /* webpackChunkName: "app/modules/ui/pages/geography/districts/index" */))
const _3c179d6a = () => interopDefault(import('../app/modules/ui/pages/geography/places/index.vue' /* webpackChunkName: "app/modules/ui/pages/geography/places/index" */))
const _6c905349 = () => interopDefault(import('../app/modules/ui/pages/geography/ports/index.vue' /* webpackChunkName: "app/modules/ui/pages/geography/ports/index" */))
const _c2c7a8be = () => interopDefault(import('../app/modules/ui/pages/geography/positions/index.vue' /* webpackChunkName: "app/modules/ui/pages/geography/positions/index" */))
const _76ca930a = () => interopDefault(import('../app/modules/ui/pages/geography/postal/index.vue' /* webpackChunkName: "app/modules/ui/pages/geography/postal/index" */))
const _263bf259 = () => interopDefault(import('../app/modules/ui/pages/processes/list.vue' /* webpackChunkName: "app/modules/ui/pages/processes/list" */))
const _0bf83035 = () => interopDefault(import('../app/modules/ui/pages/processes/processes/index.vue' /* webpackChunkName: "app/modules/ui/pages/processes/processes/index" */))
const _396ec270 = () => interopDefault(import('../app/modules/ui/pages/users/new-user.vue' /* webpackChunkName: "app/modules/ui/pages/users/new-user" */))
const _045c5dfc = () => interopDefault(import('../app/modules/ui/pages/vessels/motors/index.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/motors/index" */))
const _f85caac4 = () => interopDefault(import('../app/modules/ui/pages/vessels/new-vessel.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/new-vessel" */))
const _74bf9e75 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/index.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/index" */))
const _30fdcf32 = () => interopDefault(import('../app/modules/ui/pages/vessels/motors/new-motor.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/motors/new-motor" */))
const _45ff77c2 = () => interopDefault(import('../app/modules/ui/pages/brands/hull-brand/_id/index.vue' /* webpackChunkName: "app/modules/ui/pages/brands/hull-brand/_id/index" */))
const _18df61e3 = () => interopDefault(import('../app/modules/ui/pages/brands/motor-brand/_id/index.vue' /* webpackChunkName: "app/modules/ui/pages/brands/motor-brand/_id/index" */))
const _9da8ab82 = () => interopDefault(import('../app/modules/ui/pages/config/equipment-category/_id/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/equipment-category/_id/index" */))
const _483e3848 = () => interopDefault(import('../app/modules/ui/pages/config/escalation-type/_id/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/escalation-type/_id/index" */))
const _1d4fe182 = () => interopDefault(import('../app/modules/ui/pages/config/license-groups/_id/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/license-groups/_id/index" */))
const _6db8f7ce = () => interopDefault(import('../app/modules/ui/pages/config/vessel-activities/_id/index.vue' /* webpackChunkName: "app/modules/ui/pages/config/vessel-activities/_id/index" */))
const _6e2428a0 = () => interopDefault(import('../app/modules/ui/pages/processes/processes/_id/index.vue' /* webpackChunkName: "app/modules/ui/pages/processes/processes/_id/index" */))
const _199e1ae7 = () => interopDefault(import('../app/modules/ui/pages/vessels/motors/_id/index.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/motors/_id/index" */))
const _2d0a76e0 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/index.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/index" */))
const _4dabdc05 = () => interopDefault(import('../app/modules/ui/pages/config/lists/_id/values.vue' /* webpackChunkName: "app/modules/ui/pages/config/lists/_id/values" */))
const _51d8e486 = () => interopDefault(import('../app/modules/ui/pages/processes/processes/_id/documents.vue' /* webpackChunkName: "app/modules/ui/pages/processes/processes/_id/documents" */))
const _7c1d97f9 = () => interopDefault(import('../app/modules/ui/pages/processes/processes/_id/emails.vue' /* webpackChunkName: "app/modules/ui/pages/processes/processes/_id/emails" */))
const _79987de2 = () => interopDefault(import('../app/modules/ui/pages/processes/processes/_id/history.vue' /* webpackChunkName: "app/modules/ui/pages/processes/processes/_id/history" */))
const _93669606 = () => interopDefault(import('../app/modules/ui/pages/processes/processes/_id/notebook.vue' /* webpackChunkName: "app/modules/ui/pages/processes/processes/_id/notebook" */))
const _1477294f = () => interopDefault(import('../app/modules/ui/pages/processes/processes/_id/payments.vue' /* webpackChunkName: "app/modules/ui/pages/processes/processes/_id/payments" */))
const _68f5ad17 = () => interopDefault(import('../app/modules/ui/pages/vessels/motors/_id/records.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/motors/_id/records" */))
const _68aa3d69 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/audit.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/audit" */))
const _8650671c = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/auxiliaries.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/auxiliaries" */))
const _289fcd76 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/boardings.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/boardings" */))
const _fcb2abc8 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/capacity.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/capacity" */))
const _4b51f921 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/certificates/index.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/certificates/index" */))
const _7c6e12c6 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/documents.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/documents" */))
const _000daa07 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/equipments.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/equipments" */))
const _c50582e2 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/fees.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/fees" */))
const _21cec8e8 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/filings.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/filings" */))
const _723fbee6 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/inspections.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/inspections" */))
const _622e4480 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/motors.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/motors" */))
const _6c9f4102 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/owners.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/owners" */))
const _2d264ce3 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/photos.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/photos" */))
const _d3319594 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/property-reservation.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/property-reservation" */))
const _0da5cd5a = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/records/index.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/records/index" */))
const _2a335b2c = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/certificates/_idc.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/certificates/_idc" */))
const _6558ec6c = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/records/_idr/index.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/records/_idr/index" */))
const _fa7be208 = () => interopDefault(import('../app/modules/ui/pages/vessels/vessels/_id/records/_idr/photos/index.vue' /* webpackChunkName: "app/modules/ui/pages/vessels/vessels/_id/records/_idr/photos/index" */))
const _146b321a = () => interopDefault(import('../app/modules/ui/pages/seafarers/_id/index.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/_id/index" */))
const _44bfd2fd = () => interopDefault(import('../app/modules/ui/pages/users/_id/index.vue' /* webpackChunkName: "app/modules/ui/pages/users/_id/index" */))
const _4a8c14f6 = () => interopDefault(import('../app/modules/ui/pages/seafarers/_id/annotations.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/_id/annotations" */))
const _316a2d7c = () => interopDefault(import('../app/modules/ui/pages/seafarers/_id/audit.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/_id/audit" */))
const _472d6e30 = () => interopDefault(import('../app/modules/ui/pages/seafarers/_id/ballots.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/_id/ballots" */))
const _7403c7d8 = () => interopDefault(import('../app/modules/ui/pages/seafarers/_id/boardings.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/_id/boardings" */))
const _314ab3cb = () => interopDefault(import('../app/modules/ui/pages/seafarers/_id/categories.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/_id/categories" */))
const _5ce7a5ab = () => interopDefault(import('../app/modules/ui/pages/seafarers/_id/certificates.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/_id/certificates" */))
const _ce507780 = () => interopDefault(import('../app/modules/ui/pages/seafarers/_id/diplomas.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/_id/diplomas" */))
const _04c1c159 = () => interopDefault(import('../app/modules/ui/pages/seafarers/_id/documents.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/_id/documents" */))
const _dfa72518 = () => interopDefault(import('../app/modules/ui/pages/seafarers/_id/endorsements.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/_id/endorsements" */))
const _93f1e924 = () => interopDefault(import('../app/modules/ui/pages/seafarers/_id/letters.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/_id/letters" */))
const _2e359c89 = () => interopDefault(import('../app/modules/ui/pages/seafarers/_id/transfers.vue' /* webpackChunkName: "app/modules/ui/pages/seafarers/_id/transfers" */))
const _569ebc4d = () => interopDefault(import('../app/modules/ui/pages/users/_id/addresses.vue' /* webpackChunkName: "app/modules/ui/pages/users/_id/addresses" */))
const _ff40ccf4 = () => interopDefault(import('../app/modules/ui/pages/users/_id/audit.vue' /* webpackChunkName: "app/modules/ui/pages/users/_id/audit" */))
const _a9905810 = () => interopDefault(import('../app/modules/ui/pages/users/_id/contacts.vue' /* webpackChunkName: "app/modules/ui/pages/users/_id/contacts" */))
const _81ab3f12 = () => interopDefault(import('../app/modules/ui/pages/users/_id/licenses.vue' /* webpackChunkName: "app/modules/ui/pages/users/_id/licenses" */))
const _5ced92f0 = () => interopDefault(import('../app/modules/ui/pages/users/_id/processes.vue' /* webpackChunkName: "app/modules/ui/pages/users/_id/processes" */))
const _6a0c4aa9 = () => interopDefault(import('../app/modules/ui/pages/users/_id/seafarers.vue' /* webpackChunkName: "app/modules/ui/pages/users/_id/seafarers" */))
const _30442e30 = () => interopDefault(import('../app/modules/ui/pages/users/_id/vessels.vue' /* webpackChunkName: "app/modules/ui/pages/users/_id/vessels" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _0b6957b8,
    name: "404"
  }, {
    path: "/login",
    component: _7860b92e,
    name: "login"
  }, {
    path: "/recover",
    component: _2c041d67,
    name: "recover"
  }, {
    path: "/register",
    component: _26fdad16,
    name: "register"
  }, {
    path: "/user",
    component: _483b643e,
    name: "user"
  }, {
    path: "/admin/authority",
    component: _2b7e8d83,
    name: "admin-authority"
  }, {
    path: "/admin/groups",
    component: _35912c72,
    name: "admin-groups"
  }, {
    path: "/admin/journal",
    component: _1a6b4577,
    name: "admin-journal"
  }, {
    path: "/admin/roles",
    component: _15d59bfd,
    name: "admin-roles"
  }, {
    path: "/admin/sessions",
    component: _37457a8d,
    name: "admin-sessions"
  }, {
    path: "/admin/users",
    component: _5277e36a,
    name: "admin-users"
  }, {
    path: "/user/profile",
    component: _206b5c95,
    name: "user-profile"
  }, {
    path: "/admin/groups/:id",
    component: _89af6d9c,
    name: "admin-groups-id"
  }, {
    path: "/admin/users/:id",
    component: _15070bb6,
    name: "admin-users-id"
  }, {
    path: "/recover/change/:token?",
    component: _160b004e,
    name: "recover-change-token"
  }, {
    path: "/register/confirmation/:token?",
    component: _31d73258,
    name: "register-confirmation-token"
  }, {
    path: "/admin/users/:id/memberships",
    component: _07f03cf8,
    name: "admin-users-id-memberships"
  }, {
    path: "/admin/users/:id/memberships/:idm",
    component: _371bba74,
    name: "admin-users-id-memberships-idm"
  }, {
    path: "/",
    component: _0bafff5c,
    name: "index"
  }, {
    path: "/client/report",
    component: _30c60340,
    alias: ["/client/report/001","/client/report/002","/client/report/003","/client/report/004","/client/report/005","/client/report/006","/client/report/007","/client/report/008","/client/report/009","/client/report/010","/client/report/011","/client/report/012","/client/report/013","/client/report/014","/client/report/015","/client/report/016","/client/report/017","/client/report/018","/client/report/019","/client/report/020","/client/report/021","/client/report/022","/client/report/023","/client/report/024","/client/report/025","/client/report/026","/client/report/027","/client/report/028","/client/report/029","/client/report/030"],
    name: "modules-ui-pages-report"
  }, {
    path: "/client/seafarers",
    component: _75489008,
    name: "modules-ui-pages-seafarers"
  }, {
    path: "/client/users",
    component: _f3ccdddc,
    name: "modules-ui-pages-users"
  }, {
    path: "/client/brands/hull-brand",
    component: _15f3e334,
    alias: "/client/brands",
    name: "modules-ui-pages-brands-hull-brand"
  }, {
    path: "/client/brands/motor-brand",
    component: _247082f8,
    name: "modules-ui-pages-brands-motor-brand"
  }, {
    path: "/client/config/calendar",
    component: _e07e3016,
    name: "modules-ui-pages-config-calendar"
  }, {
    path: "/client/config/equipment-category",
    component: _2b075954,
    name: "modules-ui-pages-config-equipment-category"
  }, {
    path: "/client/config/escalation-type",
    component: _0e282d1e,
    name: "modules-ui-pages-config-escalation-type"
  }, {
    path: "/client/config/license-groups",
    component: _a5d0abd2,
    name: "modules-ui-pages-config-license-groups"
  }, {
    path: "/client/config/lists",
    component: _855f6258,
    alias: "/client/config",
    name: "modules-ui-pages-config-lists"
  }, {
    path: "/client/config/operation-areas",
    component: _1297ae1f,
    name: "modules-ui-pages-config-operation-areas"
  }, {
    path: "/client/config/record-status",
    component: _0c3daced,
    name: "modules-ui-pages-config-record-status"
  }, {
    path: "/client/config/rules",
    component: _75dc61b6,
    name: "modules-ui-pages-config-rules"
  }, {
    path: "/client/config/templates",
    component: _27779f78,
    name: "modules-ui-pages-config-templates"
  }, {
    path: "/client/config/vessel-activities",
    component: _fa80733a,
    name: "modules-ui-pages-config-vessel-activities"
  }, {
    path: "/client/geography/captaincies",
    component: _3d8b8122,
    alias: "/client/geography",
    name: "modules-ui-pages-geography-captaincies"
  }, {
    path: "/client/geography/counties",
    component: _a534f732,
    name: "modules-ui-pages-geography-counties"
  }, {
    path: "/client/geography/countries",
    component: _4a9d656b,
    name: "modules-ui-pages-geography-countries"
  }, {
    path: "/client/geography/districts",
    component: _5cc7901c,
    name: "modules-ui-pages-geography-districts"
  }, {
    path: "/client/geography/places",
    component: _3c179d6a,
    name: "modules-ui-pages-geography-places"
  }, {
    path: "/client/geography/ports",
    component: _6c905349,
    name: "modules-ui-pages-geography-ports"
  }, {
    path: "/client/geography/positions",
    component: _c2c7a8be,
    name: "modules-ui-pages-geography-positions"
  }, {
    path: "/client/geography/postal",
    component: _76ca930a,
    name: "modules-ui-pages-geography-postal"
  }, {
    path: "/client/processes/list",
    component: _263bf259,
    name: "modules-ui-pages-processes-list"
  }, {
    path: "/client/processes/processes",
    component: _0bf83035,
    alias: "/client/processes",
    name: "modules-ui-pages-processes-processes"
  }, {
    path: "/client/users/new-user",
    component: _396ec270,
    name: "modules-ui-pages-users-new-user"
  }, {
    path: "/client/vessels/motors",
    component: _045c5dfc,
    name: "modules-ui-pages-vessels-motors"
  }, {
    path: "/client/vessels/new-vessel",
    component: _f85caac4,
    name: "modules-ui-pages-vessels-new-vessel"
  }, {
    path: "/client/vessels/vessels",
    component: _74bf9e75,
    alias: "/client/vessels",
    name: "modules-ui-pages-vessels-vessels"
  }, {
    path: "/client/vessels/motors/new-motor",
    component: _30fdcf32,
    name: "modules-ui-pages-vessels-motors-new-motor"
  }, {
    path: "/client/brands/hull-brand/:id",
    component: _45ff77c2,
    name: "modules-ui-pages-brands-hull-brand-id"
  }, {
    path: "/client/brands/motor-brand/:id",
    component: _18df61e3,
    name: "modules-ui-pages-brands-motor-brand-id"
  }, {
    path: "/client/config/equipment-category/:id",
    component: _9da8ab82,
    name: "modules-ui-pages-config-equipment-category-id"
  }, {
    path: "/client/config/escalation-type/:id",
    component: _483e3848,
    name: "modules-ui-pages-config-escalation-type-id"
  }, {
    path: "/client/config/license-groups/:id",
    component: _1d4fe182,
    name: "modules-ui-pages-config-license-groups-id"
  }, {
    path: "/client/config/vessel-activities/:id",
    component: _6db8f7ce,
    name: "modules-ui-pages-config-vessel-activities-id"
  }, {
    path: "/client/processes/processes/:id",
    component: _6e2428a0,
    name: "modules-ui-pages-processes-processes-id"
  }, {
    path: "/client/vessels/motors/:id",
    component: _199e1ae7,
    name: "modules-ui-pages-vessels-motors-id"
  }, {
    path: "/client/vessels/vessels/:id",
    component: _2d0a76e0,
    name: "modules-ui-pages-vessels-vessels-id"
  }, {
    path: "/client/config/lists/:id/values",
    component: _4dabdc05,
    name: "modules-ui-pages-config-lists-id-values"
  }, {
    path: "/client/processes/processes/:id/documents",
    component: _51d8e486,
    name: "modules-ui-pages-processes-processes-id-documents"
  }, {
    path: "/client/processes/processes/:id/emails",
    component: _7c1d97f9,
    name: "modules-ui-pages-processes-processes-id-emails"
  }, {
    path: "/client/processes/processes/:id/history",
    component: _79987de2,
    name: "modules-ui-pages-processes-processes-id-history"
  }, {
    path: "/client/processes/processes/:id/notebook",
    component: _93669606,
    name: "modules-ui-pages-processes-processes-id-notebook"
  }, {
    path: "/client/processes/processes/:id/payments",
    component: _1477294f,
    name: "modules-ui-pages-processes-processes-id-payments"
  }, {
    path: "/client/vessels/motors/:id/records",
    component: _68f5ad17,
    name: "modules-ui-pages-vessels-motors-id-records"
  }, {
    path: "/client/vessels/vessels/:id/audit",
    component: _68aa3d69,
    name: "modules-ui-pages-vessels-vessels-id-audit"
  }, {
    path: "/client/vessels/vessels/:id/auxiliaries",
    component: _8650671c,
    name: "modules-ui-pages-vessels-vessels-id-auxiliaries"
  }, {
    path: "/client/vessels/vessels/:id/boardings",
    component: _289fcd76,
    name: "modules-ui-pages-vessels-vessels-id-boardings"
  }, {
    path: "/client/vessels/vessels/:id/capacity",
    component: _fcb2abc8,
    name: "modules-ui-pages-vessels-vessels-id-capacity"
  }, {
    path: "/client/vessels/vessels/:id/certificates",
    component: _4b51f921,
    name: "modules-ui-pages-vessels-vessels-id-certificates"
  }, {
    path: "/client/vessels/vessels/:id/documents",
    component: _7c6e12c6,
    name: "modules-ui-pages-vessels-vessels-id-documents"
  }, {
    path: "/client/vessels/vessels/:id/equipments",
    component: _000daa07,
    name: "modules-ui-pages-vessels-vessels-id-equipments"
  }, {
    path: "/client/vessels/vessels/:id/fees",
    component: _c50582e2,
    name: "modules-ui-pages-vessels-vessels-id-fees"
  }, {
    path: "/client/vessels/vessels/:id/filings",
    component: _21cec8e8,
    name: "modules-ui-pages-vessels-vessels-id-filings"
  }, {
    path: "/client/vessels/vessels/:id/inspections",
    component: _723fbee6,
    name: "modules-ui-pages-vessels-vessels-id-inspections"
  }, {
    path: "/client/vessels/vessels/:id/motors",
    component: _622e4480,
    name: "modules-ui-pages-vessels-vessels-id-motors"
  }, {
    path: "/client/vessels/vessels/:id/owners",
    component: _6c9f4102,
    name: "modules-ui-pages-vessels-vessels-id-owners"
  }, {
    path: "/client/vessels/vessels/:id/photos",
    component: _2d264ce3,
    name: "modules-ui-pages-vessels-vessels-id-photos"
  }, {
    path: "/client/vessels/vessels/:id/property-reservation",
    component: _d3319594,
    name: "modules-ui-pages-vessels-vessels-id-property-reservation"
  }, {
    path: "/client/vessels/vessels/:id/records",
    component: _0da5cd5a,
    name: "modules-ui-pages-vessels-vessels-id-records"
  }, {
    path: "/client/vessels/vessels/:id/certificates/:idc",
    component: _2a335b2c,
    name: "modules-ui-pages-vessels-vessels-id-certificates-idc"
  }, {
    path: "/client/vessels/vessels/:id/records/:idr",
    component: _6558ec6c,
    name: "modules-ui-pages-vessels-vessels-id-records-idr"
  }, {
    path: "/client/vessels/vessels/:id/records/:idr/photos",
    component: _fa7be208,
    name: "modules-ui-pages-vessels-vessels-id-records-idr-photos"
  }, {
    path: "/client/seafarers/:id",
    component: _146b321a,
    name: "modules-ui-pages-seafarers-id"
  }, {
    path: "/client/users/:id",
    component: _44bfd2fd,
    name: "modules-ui-pages-users-id"
  }, {
    path: "/client/seafarers/:id/annotations",
    component: _4a8c14f6,
    name: "modules-ui-pages-seafarers-id-annotations"
  }, {
    path: "/client/seafarers/:id/audit",
    component: _316a2d7c,
    name: "modules-ui-pages-seafarers-id-audit"
  }, {
    path: "/client/seafarers/:id/ballots",
    component: _472d6e30,
    name: "modules-ui-pages-seafarers-id-ballots"
  }, {
    path: "/client/seafarers/:id/boardings",
    component: _7403c7d8,
    name: "modules-ui-pages-seafarers-id-boardings"
  }, {
    path: "/client/seafarers/:id/categories",
    component: _314ab3cb,
    name: "modules-ui-pages-seafarers-id-categories"
  }, {
    path: "/client/seafarers/:id/certificates",
    component: _5ce7a5ab,
    name: "modules-ui-pages-seafarers-id-certificates"
  }, {
    path: "/client/seafarers/:id/diplomas",
    component: _ce507780,
    name: "modules-ui-pages-seafarers-id-diplomas"
  }, {
    path: "/client/seafarers/:id/documents",
    component: _04c1c159,
    name: "modules-ui-pages-seafarers-id-documents"
  }, {
    path: "/client/seafarers/:id/endorsements",
    component: _dfa72518,
    name: "modules-ui-pages-seafarers-id-endorsements"
  }, {
    path: "/client/seafarers/:id/letters",
    component: _93f1e924,
    name: "modules-ui-pages-seafarers-id-letters"
  }, {
    path: "/client/seafarers/:id/transfers",
    component: _2e359c89,
    name: "modules-ui-pages-seafarers-id-transfers"
  }, {
    path: "/client/users/:id/addresses",
    component: _569ebc4d,
    name: "modules-ui-pages-users-id-addresses"
  }, {
    path: "/client/users/:id/audit",
    component: _ff40ccf4,
    name: "modules-ui-pages-users-id-audit"
  }, {
    path: "/client/users/:id/contacts",
    component: _a9905810,
    name: "modules-ui-pages-users-id-contacts"
  }, {
    path: "/client/users/:id/licenses",
    component: _81ab3f12,
    name: "modules-ui-pages-users-id-licenses"
  }, {
    path: "/client/users/:id/processes",
    component: _5ced92f0,
    name: "modules-ui-pages-users-id-processes"
  }, {
    path: "/client/users/:id/seafarers",
    component: _6a0c4aa9,
    name: "modules-ui-pages-users-id-seafarers"
  }, {
    path: "/client/users/:id/vessels",
    component: _30442e30,
    name: "modules-ui-pages-users-id-vessels"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
