import { usePermissions } from '../stores/user/permissions';
import { useRoutes } from '../stores/auth/routes';
import { useUser } from '../stores/auth/user';
import { useUserMembership } from '../stores/user/membership';
import { useApp } from '../stores/system/app';
import { useBookmarks } from '../stores/user/bookmarks';
import { usePresentationState } from '@/stores/system/presentation-state';

function initProviders (use_default = false, str) {
  let arr = [];

  if (use_default) {
    arr.push({ text: 'Local', value: 'default', route: 'login' });
  }

  try {
    const parsed = JSON.parse(str);

    parsed.forEach((p) => {
      const route = p.route ? p.route : p.value;

      p.route = `login/${route}`;
    });

    arr = parsed.concat(arr);
  } catch (e) {
    console.log('unable to parse providers');
  }

  if (!arr.length) {
    console.log('no provider detected');
  }

  return arr;
}

export default async ({ redirect, app, $config, route, i18n }, inject) => {
  const { HOME_ROUTE, PLATFORM_PROVIDERS, PLATFORM_USE_DEFAULT_PROVIDER } = $config;

  const appStore = useApp();
  const userStore = useUser();
  const routesStore = useRoutes();
  const permissionsStore = usePermissions();
  const membershipStore = useUserMembership();
  const bookmarksStore = useBookmarks();
  const stateStore = usePresentationState();
  const { router } = app;
  const providers = initProviders(!!PLATFORM_USE_DEFAULT_PROVIDER, PLATFORM_PROVIDERS);

  // On init app, do authentication
  await authenticate();

  function getAuthEnpoint (key) {
    const prov = providers.find(p => p.value === key);

    if (!prov) { return null; }

    return prov.route ? prov.route : prov.value;
  }

  function getProviders () {
    return providers;
  }

  async function authenticate () {
    let authenticated = false;

    if (!appStore.ready) {
      // Authenticate user
      authenticated = await userStore.authenticate();

      appStore.loading = true;

      if (authenticated) {
        await userStore.read();
        await routesStore.read();
        await permissionsStore.get();
        await membershipStore.get();
        await bookmarksStore.get();
        readPreferences();

        // set language for user
        i18n.setLocale(userStore.language);
        app.vuetify.framework.lang.current = userStore.language;

        if (routesStore.isPublicRoute(route.path)) {
          const redirect = route?.query?.redirect;

          // hack to wait for the next frame
          setTimeout(() => {
            router.push(redirect || HOME_ROUTE);
          }, 0);
        }
      } else {
        await userStore.clean();
        await routesStore.clean();
        await permissionsStore.clean();
        await membershipStore.clean();
        await bookmarksStore.clean();
      }

      appStore.ready = true;
      appStore.loading = false;
    }

    return authenticated;
  }

  async function _login (method, values) {
    const endpoint = getAuthEnpoint(method);

    const authenticated = await userStore.login(endpoint, values);

    // set language for user
    i18n.setLocale(userStore.language);
    app.vuetify.framework.lang.current = userStore.language;

    appStore.loading = true;

    if (authenticated) {
      await userStore.read();
      await routesStore.read();
      await permissionsStore.get();
      await membershipStore.get();
      await bookmarksStore.get();
      readPreferences();
    } else {
      await userStore.clean();
      await routesStore.clean();
      await permissionsStore.clean();
      await membershipStore.clean();
      await bookmarksStore.clean();
    }

    appStore.loading = false;

    if (authenticated) {
      const { query } = app.router.currentRoute;

      const path = query.redirect ? query.redirect : HOME_ROUTE;

      redirect(path);
    }
  }

  async function _logout () {
    const authenticated = await userStore.logout();

    // Authenticate user
    appStore.loading = true;

    if (!authenticated) {
      await routesStore.clean();
      await permissionsStore.clean();
      await membershipStore.clean();
    }

    appStore.loading = false;

    if (!authenticated) {
      redirect('/login');
    }
  }

  async function _register (first_name, last_name, username, password) {
    const error = await userStore.register(first_name, last_name, username, password);

    return error;
  }

  async function _confirm (token) {
    return await userStore.confirm(token);
  }

  async function _recover (token) {
    return await userStore.recover(token);
  }

  function readPreferences () {
    if (userStore?.preferences && userStore.preferences?.presentations) {
      Object.keys(userStore.preferences.presentations).forEach((state) => {
        stateStore.loadState(state, userStore.preferences.presentations[state]);
      });
    }
  }

  inject('core', {
    login: _login,
    logout: _logout,
    register: _register,
    confirm: _confirm,
    recover: _recover,
    getProviders
  });
};
