<template>
  <span v-text="text" />
</template>
<script>
import { computed, defineComponent, toRefs } from '@vue/composition-api';

export default defineComponent({
  props: {
    field: {
      type: String,
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    textFn: {
      type: Function,
      default: () => ''
    }
  },

  setup (props) {
    const { item, field, textFn } = toRefs(props);

    const innerValue = computed(() => item.value[field.value]);

    const text = computed(() => textFn.value(innerValue.value, item.value, field.value));

    return {
      text
    };
  }
});
</script>
