import { render, staticRenderFns } from "./base.vue?vue&type=template&id=835c6902&"
import script from "./base.vue?vue&type=script&lang=js&"
export * from "./base.vue?vue&type=script&lang=js&"
import style0 from "./base.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/pdias/siiam/geomastervue2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('835c6902')) {
      api.createRecord('835c6902', component.options)
    } else {
      api.reload('835c6902', component.options)
    }
    module.hot.accept("./base.vue?vue&type=template&id=835c6902&", function () {
      api.rerender('835c6902', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/components/table/base.vue"
export default component.exports