var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-validation-observer", {
    ref: "observerRef",
    attrs: { slim: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          return [
            _c(
              "v-form",
              {
                attrs: { disabled: _vm.disabled, readonly: _vm.readonly },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "", "no-gutters": _vm.noGutters } },
                  [
                    _vm.error
                      ? _c(
                          "v-col",
                          {
                            attrs: {
                              xs: "12",
                              sm: "12",
                              md: "12",
                              lg: "12",
                              cols: "12",
                            },
                          },
                          [
                            _c(
                              "v-alert",
                              {
                                attrs: {
                                  type: "error",
                                  transition: "scale-transition",
                                  width: "100%",
                                  icon: "mdi-alert-octagon-outline",
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.error) +
                                    "\n        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.fields, function (fieldCfg, i) {
                      return [
                        typeof fieldCfg.norender === "undefined" ||
                        (fieldCfg.norender && !fieldCfg.norender.value)
                          ? _c(
                              "v-col",
                              {
                                key: i + fieldCfg.field,
                                attrs: {
                                  xs: "12",
                                  sm: "12",
                                  md: fieldCfg.cols || 12,
                                  lg: fieldCfg.cols || 12,
                                  cols: "12",
                                },
                              },
                              [
                                _c("v-validation-provider", {
                                  ref: "fieldref",
                                  refInFor: true,
                                  attrs: {
                                    name: "" + i,
                                    rules: fieldCfg.rules,
                                    slim: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              fieldCfg.component,
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    directives: [
                                                      {
                                                        name: "focus",
                                                        rawName: "v-focus",
                                                        value: _vm.autoFocus,
                                                        expression: "autoFocus",
                                                      },
                                                    ],
                                                    tag: "component",
                                                    attrs: {
                                                      outlined: "",
                                                      idx: i,
                                                      "error-messages": errors,
                                                    },
                                                    on: {
                                                      "click:append":
                                                        _vm.showPass,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.rec[fieldCfg.field],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.rec,
                                                          fieldCfg.field,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "rec[fieldCfg.field]",
                                                    },
                                                  },
                                                  "component",
                                                  fieldCfg.params,
                                                  false
                                                ),
                                                fieldCfg.events
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }