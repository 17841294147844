export { default as GAppTitle } from '../../app/components/app-title.vue'
export { default as GBreadcrumbs } from '../../app/components/breadcrumbs.vue'
export { default as GContent } from '../../app/components/content.vue'
export { default as GAlertBase } from '../../app/components/alert/base.vue'
export { default as GAvatarProfile } from '../../app/components/avatar/profile.vue'
export { default as GCardCookieConsent } from '../../app/components/card/cookie-consent.vue'
export { default as GCardDetail } from '../../app/components/card/detail.vue'
export { default as GCardList } from '../../app/components/card/list.vue'
export { default as GCardOtpConfirmation } from '../../app/components/card/otp-confirmation.vue'
export { default as GCardShortDetail } from '../../app/components/card/short-detail.vue'
export { default as GCardTaskList } from '../../app/components/card/task-list.vue'
export { default as GContextBookmark } from '../../app/components/context/bookmark.vue'
export { default as GContextCard } from '../../app/components/context/card.vue'
export { default as GContextExport } from '../../app/components/context/export.vue'
export { default as GContextFilters } from '../../app/components/context/filters.vue'
export { default as GContextGroups } from '../../app/components/context/groups.vue'
export { default as GContextHelp } from '../../app/components/context/help.vue'
export { default as GContextPresentation } from '../../app/components/context/presentation.vue'
export { default as GContextSettings } from '../../app/components/context/settings.vue'
export { default as GDrawerContext } from '../../app/components/drawer/context.vue'
export { default as GDrawerModal } from '../../app/components/drawer/modal.vue'
export { default as GDrawerNavigationMini } from '../../app/components/drawer/navigation-mini.vue'
export { default as GDrawerNavigation } from '../../app/components/drawer/navigation.vue'
export { default as GFieldCombo } from '../../app/components/field/combo.vue'
export { default as GFieldDate } from '../../app/components/field/date.vue'
export { default as GFieldList } from '../../app/components/field/list.vue'
export { default as GFieldPassword } from '../../app/components/field/password.vue'
export { default as GFieldSearch } from '../../app/components/field/search.vue'
export { default as GFieldTable } from '../../app/components/field/table.vue'
export { default as GError } from '../../app/components/error/404.vue'
export { default as GErrorBase } from '../../app/components/error/base.vue'
export { default as GErrorC401 } from '../../app/components/error/c401.vue'
export { default as GFormBase } from '../../app/components/form/base.vue'
export { default as GFormWizard } from '../../app/components/form/wizard.vue'
export { default as GOverlayBase } from '../../app/components/overlay/base.vue'
export { default as GOverlayLoading } from '../../app/components/overlay/loading.vue'
export { default as GPresentationData } from '../../app/components/presentation/data.vue'
export { default as GPresentationDetail } from '../../app/components/presentation/detail.vue'
export { default as GPresentationGallery } from '../../app/components/presentation/gallery.vue'
export { default as GPresentationTabular } from '../../app/components/presentation/tabular.vue'
export { default as GPresentationTimeline } from '../../app/components/presentation/timeline.vue'
export { default as GPresentationWizard } from '../../app/components/presentation/wizard.vue'
export { default as GRendererBtn } from '../../app/components/renderer/btn.vue'
export { default as GRendererChip } from '../../app/components/renderer/chip.vue'
export { default as GRendererCurrency } from '../../app/components/renderer/currency.vue'
export { default as GRendererDate } from '../../app/components/renderer/date.vue'
export { default as GRendererIcon } from '../../app/components/renderer/icon.vue'
export { default as GRendererJson } from '../../app/components/renderer/json.vue'
export { default as GRendererOnoff } from '../../app/components/renderer/onoff.vue'
export { default as GRendererProgress } from '../../app/components/renderer/progress.vue'
export { default as GRendererRating } from '../../app/components/renderer/rating.vue'
export { default as GRendererSparkline } from '../../app/components/renderer/sparkline.vue'
export { default as GRendererText } from '../../app/components/renderer/text.vue'
export { default as GTableBase } from '../../app/components/table/base.vue'
export { default as GTableGallery } from '../../app/components/table/gallery.vue'
export { default as GTableTimeline } from '../../app/components/table/timeline.vue'
export { default as GToastBase } from '../../app/components/toast/base.vue'
export { default as GToolbarMobile } from '../../app/components/toolbar/mobile.vue'
export { default as GFieldFilterArray } from '../../app/components/field/filter/array.vue'
export { default as GFieldFilterBool } from '../../app/components/field/filter/bool.vue'
export { default as GFieldFilterDate } from '../../app/components/field/filter/date.vue'
export { default as GFieldFilterLabel } from '../../app/components/field/filter/label.vue'
export { default as GFieldFilterNumber } from '../../app/components/field/filter/number.vue'
export { default as GFieldFilterSearch } from '../../app/components/field/filter/search.vue'
export { default as GFieldFilterSelect } from '../../app/components/field/filter/select.vue'
export { default as GFieldFilterString } from '../../app/components/field/filter/string.vue'
export { default as GFieldTemplateMEntityActionTpl } from '../../app/components/field/template/m-entity-action-tpl.vue'
export { default as GFormOtpMOtpDeleteAccesscontrol } from '../../app/components/form/otp/m-otp-delete-accesscontrol.vue'
export { default as GFormOtpMOtpDeleteAuthSimple } from '../../app/components/form/otp/m-otp-delete-auth-simple.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
