<template>
  <v-card
    ref="card"
    flat
    tile
    height="100vh"
    width="100%"
    :class="{'scrollable-content': scrollable, 'fullscreen-content': !scrollable, 'px-4': true}"
    @scroll="onScroll"
  >
    <template v-if="innerCanRead">
      <div :class="{'sticky-toolbar': scrollable}">
        <v-toolbar height="88" flat class="pr-3">
          <v-tooltip
            v-if="depth > 1"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="ml-1"
                icon
                v-bind="attrs"
                v-on="on"
                @click="onClickPrevious"
              >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('general.go_back') }}</span>
          </v-tooltip>

          <v-btn
            icon
            color="primary"
            :class="{
              'bg-shadow': true,
              'ml-1': depth <= 1,
              'ml-2': depth > 1
            }"
          >
            <v-icon>
              {{ icon }}
            </v-icon>
          </v-btn>

          <v-toolbar-title
            :class="{
              'font-weight-medium': true,
              'ml-4': depth > 1,
              'mr-3': true
            }"
          >
            <span>
              {{ innerTitle }}

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :loading="loadingBookmarks"
                    color="primary"
                    icon
                    :plain="!bookmarked"
                    small
                    style="margin-bottom: 2px; overflow: auto !important;"
                    v-bind="attrs"
                    v-on="on"
                    @click="onClickBookmark"
                  >
                    <v-icon style="height: 21px; font-size: 21px; width: 21px;">
                      {{ bookmarked ? 'mdi-bookmark-check-outline' : 'mdi-bookmark-outline' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ bookmarked ? $t('general.remove_bookmark') : $t('general.add_bookmark') }}</span>
              </v-tooltip>
            </span>

            <!-- <v-expand-transition> -->
            <g-breadcrumbs class="pa-0" />
          <!-- </v-expand-transition> -->
          </v-toolbar-title>

          <v-spacer />

          <slot
            name="header-tools"
            :can-create="innerCanCreate"
            :can-read="innerCanRead"
            :can-update="innerCanUpdate"
            :can-delete="innerCanDelete"
          />

          <v-tooltip
            v-if="hasExport"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :disable="disableExport"
                icon
                color="info"
                class="bg-shadow ml-2"
                v-bind="attrs"
                v-on="on"
                @click="onClickExport"
              >
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('general.export') }}</span>
          </v-tooltip>

          <!-- <v-tooltip
          v-if="hasSave"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :disabled="disableSave"
              icon
              color="primary"
              class="bg-shadow ml-2"
              v-bind="attrs"
              v-on="on"
              @click="onClickSave"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('general.save') }}</span>
        </v-tooltip> -->

          <v-tooltip
            v-if="hasCreate && innerCanCreate"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :disable="disableCreate"
                icon
                color="success"
                class="bg-shadow ml-2"
                v-bind="attrs"
                v-on="on"
                @click="onClickCreate"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{ createNewRecordLabel ?
              createNewRecordLabel :
              $t('general.create_new_record') }}
            </span>
          </v-tooltip>

          <v-tooltip
            v-if="hasUpdate && innerCanUpdate"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="disableUpdate"
                icon
                color="warning"
                class="bg-shadow ml-2"
                v-bind="attrs"
                v-on="on"
                @click="onClickUpdate"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ updateSelectedRecordLabel ?
              updateSelectedRecordLabel :
              $t('general.update_selected_record') }}
            </span>
          </v-tooltip>

          <v-tooltip
            v-if="hasDelete && innerCanDelete"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="disableDelete"
                icon
                color="error"
                class="bg-shadow ml-2"
                v-bind="attrs"
                v-on="on"
                @click="onClickDelete"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ deleteSelectedRecordLabel ?
              deleteSelectedRecordLabel:
              $t('general.delete_selected_record') }}
            </span>
          </v-tooltip>

          <v-tooltip
            v-if="hasArchive"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="disableArchive"
                icon
                color="error"
                class="bg-shadow ml-2"
                v-bind="attrs"
                v-on="on"
                @click="onClickArchive"
              >
                <v-icon>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>{{ archiveSelectedRecordLabel ?
              archiveSelectedRecordLabel :
              $t('general.archive_selected_record') }}
            </span>
          </v-tooltip>
        </v-toolbar>

        <v-fade-transition>
          <v-divider v-if="scrolled" />
        </v-fade-transition>

        <v-fade-transition>
          <div v-if="!scrolled" style="height: 34px;" class="px-5 pb-2 white">
            <v-tooltip v-if="userMembership" bottom>
              <template #activator="{ on, attrs }">
                <v-chip
                  small
                  color="secondary"
                  outlined
                  class="bg-shadow"
                  style="border: none"
                  :disabled="!innerCanRead"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small left>
                    mdi-account-group-outline
                  </v-icon>

                  {{ userMembership }}
                </v-chip>
              </template>
              <span class="caption">
                {{ $t('general.active_user_group') }}
              </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-chip
                  small
                  color="success"
                  outlined
                  class="bg-shadow"
                  style="border: none"
                  :disabled="!innerCanRead"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small left>
                    mdi-book-open
                  </v-icon>
                  {{ $t('general.reading') }}
                </v-chip>
              </template>
              <span class="caption">
                {{ $t('general.your_profile_has_permission_to_read') }}
              </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-chip
                  small
                  color="success"
                  outlined
                  class="bg-shadow ml-1"
                  style="border: none"
                  :disabled="!innerCanCreate"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small left>
                    mdi-plus
                  </v-icon>
                  {{ $t('general.creation') }}
                </v-chip>
              </template>

              <span class="caption">
                {{ $t('general.your_profile_has_permission_to_create') }}
              </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-chip
                  small
                  color="success"
                  outlined
                  class="bg-shadow ml-1"
                  style="border: none"
                  :disabled="!innerCanUpdate"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small left>
                    mdi-pencil-outline
                  </v-icon>
                  {{ $t('general.writing') }}
                </v-chip>
              </template>

              <span class="caption">
                {{ $t('general.your_profile_has_permission_to_write') }}
              </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-chip
                  small
                  color="success"
                  outlined
                  class="bg-shadow ml-1"
                  style="border: none"
                  :disabled="!innerCanDelete"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small left>
                    mdi-delete-outline
                  </v-icon>
                  {{ $t('general.deletion') }}
                </v-chip>
              </template>

              <span class="caption">
                {{ $t('general.your_profile_has_permission_to_delete') }}
              </span>
            </v-tooltip>
          </div>
        </v-fade-transition>
      </div>

      <div class="px-4">
        <slot
          name="headline"
          :can-create="innerCanCreate"
          :can-read="innerCanRead"
          :can-update="innerCanUpdate"
          :can-delete="innerCanDelete"
        />
      </div>

      <div class="px-4">
        <slot />
      </div>
      <!-- {{ disallowCreate }}
      {{ disallowUpdate }}
      {{ disallowDelete }} -->
    </template>

    <template v-else>
      <g-error-c401 />
    </template>
  </v-card>
</template>
<script>
import { useRouter } from '@nuxtjs/composition-api';
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, toRef, watch } from '@vue/composition-api';
import useModalForm from '../composables/useModalForm';
import { useI18n } from '../composables/useI18n';
import { useFields } from '../composables/useFields';
import useToast from '../composables/use-toast';
import { useRoutes } from '@/stores/auth/routes';
import { useUserMembership } from '@/stores/user/membership';
import { usePermissions } from '@/stores/user/permissions';
import { useBookmarks } from '@/stores/user/bookmarks';
import { usePresentationState } from '@/stores/system/presentation-state';
import { useSync } from '@/composables/useSync';

export default defineComponent({
  props: {
    entity: {
      type: String,
      required: true
    },

    title: {
      type: String,
      default: null
    },

    scrollable: {
      type: Boolean,
      default: false
    },

    hasExport: {
      type: Boolean,
      default: false
    },

    hasCreate: {
      type: Boolean,
      default: false
    },

    hasUpdate: {
      type: Boolean,
      default: false
    },

    hasDelete: {
      type: Boolean,
      default: false
    },

    hasArchive: {
      type: Boolean,
      default: false
    },

    hasSave: {
      type: Boolean,
      default: false
    },

    disableExport: {
      type: Boolean,
      default: false
    },

    disableCreate: {
      type: Boolean,
      default: false
    },

    disableUpdate: {
      type: Boolean,
      default: true
    },

    disableDelete: {
      type: Boolean,
      default: true
    },

    disableArchive: {
      type: Boolean,
      default: true
    },

    disableSave: {
      type: Boolean,
      default: true
    },

    createNewRecordLabel: {
      type: String,
      default: null
    },
    updateSelectedRecordLabel: {
      type: String,
      default: null
    },
    deleteSelectedRecordLabel: {
      type: String,
      default: null
    },
    archiveSelectedRecordLabel: {
      type: String,
      default: null
    },

    disallowCreate: {
      type: Boolean,
      default: false
    },
    disallowUpdate: {
      type: Boolean,
      default: false
    },
    disallowDelete: {
      type: Boolean,
      default: false
    }
  },

  setup (props, { emit }) {
    const title = useSync(props, 'title', emit);
    const entity = toRef(props, 'entity');

    const disallowCreate = toRef(props, 'disallowCreate');
    const disallowUpdate = toRef(props, 'disallowUpdate');
    const disallowDelete = toRef(props, 'disallowDelete');

    const innerCanCreate = ref(false);// useSync(props, 'canCreate', emit);
    const innerCanRead = ref(false);// useSync(props, 'canRead', emit);
    const innerCanUpdate = ref(false);// useSync(props, 'canUpdate', emit);
    const innerCanDelete = ref(false);// useSync(props, 'canDelete', emit);

    const card = ref();
    let innerTitle = ref('');
    const icon = ref('');
    const depth = ref(0);
    const scrolled = ref(false);
    const scrollY = ref(0);

    const i18n = useI18n();
    const { gfield } = useFields();
    const { openForm } = useModalForm();
    const snackbar = useToast();
    const store = usePermissions();
    const bookmarksStore = useBookmarks();
    const routesStore = useRoutes();
    const router = useRouter();
    const state = usePresentationState();
    const userMembershipStore = useUserMembership();

    const path = router.currentRoute.path;
    const route = routesStore.findRoute(path);

    const userMembership = computed(() => userMembershipStore?.row?.['Membership.name']);
    const bookmark = computed(() => {
      // bookmarks always end with /
      const p = !path.endsWith('/') ? path + '/' : path;

      return bookmarksStore.rows.find(row => row.path === p);
    });
    const bookmarked = computed(() => !!bookmark.value);
    const loadingBookmarks = computed(() => bookmarksStore.loading);
    const bookmarkFields = computed(() => [
      gfield('v-text-field', 'title', { label: i18n.t('general.title') }, 'required'),
      gfield('v-text-field', 'path', { label: i18n.t('general.path'), readonly: true }, 'required')
    ]);

    if (route) {
      // Either use the prop title or the route
      if (title.value) {
        innerTitle = title;
      } else {
        innerTitle.value = route.title;
        title.value = route.title;
      }

      icon.value = route.icon;
      depth.value = route.depth;
    }

    if (entity.value) {
      innerCanCreate.value = store.can('create', entity.value) && !disallowCreate.value;
      innerCanRead.value = store.can('read', entity.value);
      innerCanUpdate.value = store.can('update', entity.value) && !disallowUpdate.value;
      innerCanDelete.value = store.can('delete', entity.value) && !disallowDelete.value;
    } else {
      innerCanCreate.value = false;
      innerCanRead.value = false;
      innerCanUpdate.value = false;
      innerCanDelete.value = false;
    }

    // on mount, restore scroll position
    onMounted(() => {
      const y = state.activeState?.scrollTop;
      const lastRoute = state.activeState?.lastRoute;
      const el = card?.value.$el;

      if (y && el && path === lastRoute) {
        el.scrollTop = y;
      }
    });

    // on unmount, save scroll position
    onBeforeUnmount(() => {
      state.activeState.scrollTop = scrollY.value;
      state.activeState.lastRoute = path;
    });

    // on refresh, update permissions
    watch(() => store.rows, (value) => {
      innerCanCreate.value = store.can('create', entity.value) && !disallowCreate.value;
      innerCanRead.value = store.can('read', entity.value);
      innerCanUpdate.value = store.can('update', entity.value) && !disallowUpdate.value;
      innerCanDelete.value = store.can('delete', entity.value) && !disallowDelete.value;
    }, { deep: true });

    watch(disallowUpdate, (value) => {
      innerCanCreate.value = store.can('create', entity.value) && !disallowCreate.value;
      innerCanRead.value = store.can('read', entity.value);
      innerCanUpdate.value = store.can('update', entity.value) && !disallowUpdate.value;
      innerCanDelete.value = store.can('delete', entity.value) && !disallowDelete.value;
    });

    return {
      userMembership,
      innerCanCreate,
      innerCanRead,
      innerCanUpdate,
      innerCanDelete,

      card,
      innerTitle,
      icon,
      depth,
      scrolled,
      bookmarked,
      loadingBookmarks,

      onClickPrevious,
      onClickBookmark,
      onClickExport,
      onClickCreate,
      onClickUpdate,
      onClickDelete,
      onClickArchive,
      onClickSave,
      onScroll
    };

    function onClickPrevious () {
      router.go(-1);
    }

    async function onClickBookmark () {
      if (bookmarksStore.loading) {
        return;
      }

      if (!bookmarked.value) {
        openForm(i18n.t('general.new_bookmark'), {
          fields: bookmarkFields,
          record: {
            title: '',
            path
          }
        }, onSaveBookmark);
      } else {
        await onDeleteBookmark();
      }
    }

    async function onSaveBookmark (payload) {
      const { title } = payload.output;
      const icon = route.icon;

      const { status, error } = await bookmarksStore.create({ icon, path, title });

      if (!error) {
        await bookmarksStore.get();
      } else if (status === 409) {
        snackbar.alert(i18n.t('general.error_limit_bookmark'));
      } else {
        snackbar.alert(i18n.t('general.error_saving_bookmark'));
      }
    }

    async function onDeleteBookmark () {
      const { error } = await bookmarksStore.delete({ id: bookmark.value.id });

      if (!error) {
        await bookmarksStore.get();
      } else {
        snackbar.alert(i18n.t('general.error_deleting_bookmark'));
      }
    }

    function onClickExport () {
      emit('click:export');
    }

    function onClickCreate () {
      emit('click:create');
    }

    function onClickUpdate () {
      emit('click:update');
    }

    function onClickDelete () {
      emit('click:delete');
    }

    function onClickArchive () {
      emit('click:archive');
    }

    function onClickSave () {
      emit('click:save');
    }

    function onScroll ({ target: { scrollTop } }) {
      if (scrollTop > 0) {
        scrolled.value = true;
      } else {
        scrolled.value = false;
      }

      scrollY.value = scrollTop;
    }
  }
});
</script>

<style scoped>
.scrollable-content {
  overflow-y: scroll;
}

.sticky-toolbar {
  position: sticky;
  top: 0;
  z-index: 3;
}

.toolbar-divider {
  border: thin solid rgba(0,0,0,0.17);
}
</style>
<style>
.bg-shadow::before {
  opacity: 0.12;
}
</style>
