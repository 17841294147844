<template>
  <v-navigation-drawer
    :value="open"
    mini-variant
    :color="!dark ? 'primary' : ''"
    dark
    :mini-variant-width="miniSize"
    class="elevation-0"
    :mobile-breakpoint="0"
  >
    <template #prepend>
      <v-sheet color="" :height="miniSize" class="d-flex justify-center align-center">
        <v-btn
          style="height: 100%;"
          x-large
          elevation="0"
          link
          to="/"
          @click="onChangeHome"
        >
          <v-avatar tile size="52">
            <v-img
              src="/favicon.ico"
              alt="home"
            />
          </v-avatar>
        </v-btn>
      </v-sheet>
    </template>

    <v-list :width="miniSize">
      <v-list-item-group
        v-model="selectedIdx"
        @change="onChangeNav"
      >
        <v-tooltip
          v-for="(item, index) in navTree"
          :key="item.route+index"
          right
        >
          <template #activator="{ on, attrs }">
            <v-list-item
              :key="index+item.route"
              :value="item.route"
              link
              no-action
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon>
                <v-icon>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content />
            </v-list-item>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>
      </v-list-item-group>
    </v-list>

    <template v-if="!mobile" #append>
      <v-divider class="" />

      <div v-if="!!navUser" flat height="60">
        <v-speed-dial
          v-model="show"
          direction="top"
          open-on-hover
          transition="slide-y-reverse-transition"
        >
          <template #activator>
            <v-list class="pa-0">
              <v-list-item-group>
                <v-list-item
                  to="/user/profile"
                  :height="miniSize"
                  link
                  no-action
                  @click="onChangeNavUser"
                >
                  <!-- <v-list-item-icon> -->
                  <!-- <v-icon>mdi-account</v-icon> -->

                  <v-avatar class="my-2">
                    <v-img
                      src="/api/user/profile-image"
                      alt="John"
                    >
                      <template #placeholder>
                        <v-icon>mdi-account</v-icon>
                      </template>
                    </v-img>
                  </v-avatar>
                  <!-- </v-list-item-icon> -->
                  <v-list-item-content />
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </template>

          <v-btn icon @click="onLogout">
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </v-speed-dial>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { useContext, useRoute, useRouter } from '@nuxtjs/composition-api';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { useRoutes } from '@/stores/auth/routes';
import { useSync } from '@/composables/useSync';
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  props: {
    open: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Object,
      required: true
    },
    showLogout: {
      type: Boolean,
      default: false
    }
  },

  setup (props, { emit }) {
    const { $core, $vuetify, $config } = useContext();

    const { HOME_ROUTE } = $config;

    const i18n = useI18n();
    const routesStore = useRoutes();
    const router = useRouter();
    const route = useRoute();

    const mini = ref(false);
    const selectedIdx = ref('');
    const miniSize = ref('72px');
    const selected = useSync(props, 'selected', emit);
    const show = useSync(props, 'showLogout', emit);

    // useSync(props, 'selected', emit);
    const navTree = computed(() => routesStore.tree.filter(r => r.route !== '/user'));
    const navUser = computed(() => routesStore.tree.find(r => r.route === '/user'));
    // const navHome = computed(() => routesStore.tree.find(r => r.route === '/'));

    const mobile = computed(() => $vuetify.breakpoint.mobile);
    const dark = computed(() => $vuetify.theme.dark);

    const selectedUser = ref(-1);

    watch(route, (newRoute) => {
      const { path } = newRoute;

      const node = navTree.value.find(parent => path.startsWith(parent.route));

      if (node && node.route !== '/') {
        selected.value = node;
      } else if (path === '/') {
        selected.value = {
          id: -1,
          title: i18n.t('general.home'),
          route: '/',
          childs: [
            {
              id: -1,
              icon: 'mdi-home-outline',
              title: i18n.t('general.home'),
              route: '/'
            }
          ]
        };
      }
      selectedIdx.value = selected.value.route
        ? selected.value.route
        : (node && node.route)
            ? node.route
            : null;
    }, { immediate: true });

    watch(navTree, (value) => {
      const { path } = route.value;

      const node = navTree.value.find(parent => path.startsWith(parent.route));

      if (node && node.route !== '/') {
        selected.value = node;
      } else if (path === '/') {
        selected.value = {
          id: -1,
          title: i18n.t('general.home'),
          route: '/',
          childs: [
            {
              id: -1,
              icon: 'mdi-home-outline',
              title: i18n.t('general.home'),
              route: '/'
            }
          ]
        };
      } else {
        selected.value = {};
      }
      selectedIdx.value = selected.value.route
        ? selected.value.route
        : (node && node.route)
            ? node.route
            : null;
    });

    return {
      navTree,
      navUser,
      show,
      mini,
      miniSize,
      selectedIdx,
      selectedUser,

      mobile,
      dark,

      onLogout,
      onChangeHome,
      onChangeNav,
      onChangeNavUser
    };

    async function onLogout () {
      await $core.logout();
    }

    function onChangeHome () {
      router.push(HOME_ROUTE);
    }

    function onChangeNav (idx) {
      const { path } = route.value;
      const look = idx || path;

      const node = navTree.value.find(parent => look.startsWith(parent.route));
      const childs = node?.childs;

      selected.value = node || {};

      if (childs && childs.length) {
        router.push(childs[0].route);
      }
    }

    function onChangeNavUser () {
      selected.value = navUser.value;
    }
  }
});
</script>
<style scoped>
.multi-navigation >>> .v-navigation-drawer__content {
  display: flex;
}

.grey--background.lighten-5 {
  background: #FAFAFA;
}

.blue-grey--background.lighten-5 {
  background: #ECEFF1;
}
</style>
