var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-breadcrumbs", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.items.length > 0,
        expression: "items.length > 0",
      },
    ],
    attrs: { items: _vm.items },
    scopedSlots: _vm._u([
      {
        key: "divider",
        fn: function () {
          return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
        },
        proxy: true,
      },
      {
        key: "item",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "v-breadcrumbs-item",
              {
                class: {
                  "primary--text breadcrumb-link font-weight-medium": true,
                  disabled: item.disabled,
                },
                on: {
                  click: function ($event) {
                    return _vm.onClickLink(item)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(item.text) + "\n    ")]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }