var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "multi-navigation",
      staticStyle: { "z-index": "4" },
      attrs: {
        permanent: !_vm.mobile,
        absolute: _vm.mobile,
        width: _vm.width,
        rail: "",
        app: "",
      },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c("g-drawer-navigation-mini", {
        ref: "mininavRef",
        attrs: {
          open: _vm.open,
          selected: _vm.selected,
          "show-logout": _vm.showLogout,
        },
        on: {
          "update:selected": function ($event) {
            _vm.selected = $event
          },
          "update:showLogout": function ($event) {
            _vm.showLogout = $event
          },
          "update:show-logout": function ($event) {
            _vm.showLogout = $event
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "d-flex": true,
            "flex-column": true,
            "grey--background lighten-5": !_vm.dark,
          },
          style: "width: " + _vm.widthInner + ";",
        },
        [
          _c("g-app-title"),
          _vm._v(" "),
          _c(
            "v-list",
            {
              class: { "pa-0": true },
              staticStyle: { flex: "1" },
              attrs: { nav: "" },
            },
            [
              _c(
                "v-list-item-group",
                { staticClass: "py-2 px-4", attrs: { color: "primary" } },
                _vm._l(_vm.selected.childs, function (child) {
                  return _c(
                    "v-list-item",
                    { key: child.route, attrs: { to: child.route } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v(" " + _vm._s(child.icon))])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-title",
                        { staticStyle: { "white-space": "normal" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(child.title) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-divider", {}),
          _vm._v(" "),
          _c(
            "v-speed-dial",
            {
              attrs: {
                direction: "top",
                "open-on-hover": "",
                transition: "slide-y-reverse-transition",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function () {
                    return [
                      _c(
                        "v-list",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-list-item-group",
                            [
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    to: "/user/profile",
                                    height: "72px",
                                    link: "",
                                    "no-action": "",
                                  },
                                  on: { click: _vm.onChangeNavUser },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-6 subtitle-2 d-flex flex-column justify-center",
                                      staticStyle: { height: "64px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text--secondary caption font-weight-bold",
                                          staticStyle: {
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis",
                                            "white-space": "nowrap",
                                            "line-height": "1rem !important",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.userTitle) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "text--secondary font-weight-bold",
                                          staticStyle: {
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis",
                                            "white-space": "nowrap",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.userName) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-list-item-content"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.showLogout,
                callback: function ($$v) {
                  _vm.showLogout = $$v
                },
                expression: "showLogout",
              },
            },
            [
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "", plain: "", elevation: "0" },
                  on: { click: _vm.onLogout },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("general.logout")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }