<template>
  <div class="mb-8">
    <v-validation-observer slim>
      <v-validation-provider
        ref="validationProvider"
        name="table-field"
        rules=""
        slim
      >
        <v-card
          flat
          outlined
          :class="{
            'g-field-table': true,
            'g-field-table-error': errorMessages.length
          }"
          :disabled="disabled"
          @focus="onFocus"
        >
          <!-- LABEL -->
          <div
            style="letter-spacing: normal;"
          >
            <label
              :class="{
                'v-label v-label--active theme--light mx-2': true,
                'g-field-table-error-label': errorMessages.length
              }"
            >
              {{ label }}
            </label>
          </div>

          <!-- TABLE -->
          <v-data-table
            show-expand
            single-expand
            :headers="innerHeaders"
            :items="innerValueCopy"
            hide-default-footer
            :item-key="rowKey"
            :expanded.sync="expanded"
            :no-data-text="$t('general.no_items')"
            :item-class="rowClassifier"
            @item-expanded="onItemExpanded"
            @click:row="onClickRow"
          >
            <template #expanded-item="{ item }">
              <td
                :colspan="headers.length + 2"
                class="py-6 pt-7 px-6"
              >
                <!-- <h6 class="mb-7 subtitle-1 text--secondary">
              Detalhes do registo
            </h6> -->

                <!-- Setting disabled prevents parent observer from inheriting validation flags-->
                <v-validation-observer slim disabled>
                  <g-form-base
                    ref="innerFormRef"
                    :fields="fields"
                    :record="innerFormRecord"
                    :valid.sync="formValid"
                  />
                </v-validation-observer>

                <div class="d-flex justify-end">
                  <v-btn
                    v-if="isCancelable"
                    class="mr-2"
                    text
                    @click="onCancelItem"
                  >
                    <v-icon left>
                      mdi-close
                    </v-icon>

                    {{ $t('general.cancel') }}
                  </v-btn>

                  <v-btn
                    v-else
                    class="mr-2"
                    text
                    @click="onRemoveItemById(item.$id)"
                  >
                    <v-icon left>
                      mdi-delete-outline
                    </v-icon>

                    {{ $t('general.delete') }}
                  </v-btn>

                  <v-btn
                    :disabled="!formValid"
                    text
                    outlined
                    @click="onSaveItem"
                  >
                    <v-icon left>
                      mdi-content-save-outline
                    </v-icon>

                    {{ $t('general.save') }}
                  </v-btn>
                </div>
              </td>
            </template>

            <template
              v-for="(config, i) in renderers"
              #[`item.${config.value}`]="{ item }"
            >
              <component
                :is="config.renderer.component"
                :key="i"
                :item="item"
                :field="config.renderer.value ? config.renderer.value : config.value"
                v-bind="config.renderer.params"
                v-on="config.renderer.events"
              />
            </template>

            <!-- EXPAND BTN-->
            <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
              <td class="text-start">
                <v-btn
                  :disabled="isEditing && !isCancelable"
                  icon
                  class="v-data-table__expand-icon"
                  :class="{'v-data-table__expand-icon--active' : isExpanded}"
                  @click.stop="onClickRow(item, { expand, isExpanded })"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </template>

            <!-- ACTIONS-->
            <template

              #[`item.actions`]="{ index, item }"
            >
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-if="allowDelete || item.$new"
                    icon
                    small
                    class="bg-shadow"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      small
                      @click.stop="onRemoveItem(index)"
                    >
                      mdi-delete-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t('general.delete') }}
                </span>
              </v-tooltip>
            </template>
          </v-data-table>

          <div class="d-flex justify-end">
            <v-divider width="100%" />

            <div class="d-flex justify-space-between align-center mx-2" style="height: 48px; width: 100%;">
              <v-card-text class="text--secondary">
                {{ innerValueCopy.length }} {{ $tc('general.item', innerValueCopy.length === 1 ? 1 : 0) }}
              </v-card-text>

              <v-btn
                small
                text
                :disabled="isEditing || disabled"
                @click="onAddItem"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                {{ $t('general.add_item') }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-validation-provider>
    </v-validation-observer>
    <div class="g-field-table-error-message ma-3">
      {{ errorMessages.join(', ') }}
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, inject, ref, toRefs, watch } from '@vue/composition-api';
import _ from 'lodash';
import { useSync } from '@/composables/useSync';
import { useI18n } from '@/composables/useI18n';
import { useArrayEquality } from '@/composables/use-array-equality';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: null
    },

    idx: {
      type: Number,
      default: null
    },

    valid: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    rowKey: {
      type: String,
      default: '$id'
    },

    headers: {
      type: Array,
      default: () => []
    },

    fields: {
      type: Array,
      default: () => []
    },

    value: {
      type: Array,
      default: () => []
    },

    modelValue: {
      type: Array,
      default: () => []
    },

    error: {
      type: Boolean,
      default: false
    },

    errorCount: {
      type: Number,
      default: 0
    },

    errorMessages: {
      type: Array,
      default: () => null
    },

    allowDelete: {
      type: Boolean,
      default: true
    }
  },

  setup (props, { emit }) {
    const { headers, fields, allowDelete, idx } = toRefs(props);

    const i18n = useI18n();
    const { isEqualArr } = useArrayEquality();
    const innerValue = useSync(props, 'value', emit);
    const innerModelValue = useSync(props, 'modelValue', emit);
    const innerErrorMessages = useSync(props, 'errorMessages', emit);
    const innerHeaders = computed(() => {
      const headersCopy = [].concat(headers.value);

      headersCopy.push({
        text: '',
        value: 'actions',
        align: 'center',
        width: 40,
        sortable: false
      });

      return headersCopy;
    });
    const emptyItem = computed(() => fields.value.reduce((acc, curr) => {
      acc[curr.field] = null;

      return acc;
    }, {}));

    const validationProvider = ref();
    const innerValueCopy = ref([]);
    const focused = ref(false);
    const expanded = ref([]);

    const innerFormRef = ref();
    const innerFormRecord = ref({});
    const formValid = ref(true);
    const seq = ref(1);
    const expandedIndex = ref(-1);

    const renderers = computed(() => headers.value.filter(header => !!header.renderer));
    const isEditing = computed(() => !!innerFormRef.value);
    const isCancelable = computed(() => !innerFormRecord.value?.$volatile);
    const lastRef = ref(innerValueCopy.value);

    const setFormChanged = inject('setFormChanged');

    // Maybe this isn't required
    // We validate if internal form is open
    // extend('no-volatile-rows', {
    //   validate (value) {
    //     return Array.isArray(value) && value.findIndex(row => !!row.$volatile) === -1;
    //   },
    //   message: (field, values) => 'ERROR'
    // });

    // On change the reference to this array,
    // mirror to internal value
    watch(innerValue, (value, oldValue) => {
      // if it's the same ref or the ref was set internally by the component,
      // do nothing
      if (value === oldValue || lastRef.value === value) {
        // if the reference of the new/old arrays
        // is the same, do nothing
        return;
      }

      seq.value = 1;

      innerValueCopy.value = value.map((row) => {
        const clone = _.cloneDeep(row);

        clone.$id = seq.value++;
        clone.$valid = true;
        clone.$volatile = false;

        return clone;
      });
    }, { immediate: true });

    watch(formValid, (value) => {
      if (innerFormRecord.value) {
        innerFormRecord.value.$valid = value;
      }
    });

    watch(innerFormRef, (value) => {
      if (!value) {
        formValid.value = true;
      }
    });

    // whenever form is expanded/colapsed, validate
    watch(expandedIndex, (value) => {
      validationProvider.value?.setErrors(value > -1
        ? [
            i18n.t('validation.save_or_close')
          ]
        : []);
    });

    // TODO: find out why removing this does not trigger
    // the "changed" when updating the array for the first time
    updateInnerValue();

    return {
      validationProvider,
      innerValueCopy,
      innerModelValue,
      innerErrorMessages,
      innerHeaders,

      renderers,
      focused,
      formValid,
      innerFormRef,
      innerFormRecord,
      expanded,
      isCancelable,
      isEditing,

      rowClassifier,
      onBlur,
      onFocus,
      onAddItem,
      onRemoveItem,
      onRemoveItemById,
      onClickRow,
      onCancelItem,
      onSaveItem,
      onItemExpanded
    };

    function rowClassifier (row) {
      return row.$volatile ? 'g-field-table-new row-cursor' : 'row-cursor';
    }

    function onBlur (value) {
      emit('blur', value);

      focused.value = false;
    }

    function onFocus (value) {
      emit('focus', value);

      focused.value = true;
    }

    function updateInnerValue () {
      const changed = isEqualArr(lastRef.value, innerValue.value);

      lastRef.value = [].concat(innerValue.value);

      emit('input', lastRef.value);

      // If the old value len equal new value len,
      // vee-validate will not consider this as a
      // change (ie. edit a property of an item).
      // So we hack the dirtyness of the field by
      // setting it dirty on every change
      if (changed) {
        setTimeout(() =>
          setFormChanged(idx.value, true)
        , 0);
      }
    }

    function onAddItem () {
      const newItem = {
        ...emptyItem.value,
        $id: seq.value++,
        $valid: false,
        $new: true,
        $volatile: true
      };

      innerValue.value.push({});
      innerValueCopy.value.push(newItem);

      // Manually expanding items does not
      // trigger events, so we set the record here
      innerFormRecord.value = newItem;

      updateInnerValue();

      expanded.value = [newItem];
      expandedIndex.value = innerValueCopy.value.length - 1;
    }

    function onRemoveItemById (id) {
      const index = innerValueCopy.value.findIndex(row => row.$id === id);

      onRemoveItem(index);
    }

    function onRemoveItem (index) {
      const item = innerValueCopy.value[index];

      if (!allowDelete.value && !item.$new) {
        return;
      }

      if (index > -1) {
        innerValue.value.splice(index, 1);
        innerValueCopy.value.splice(index, 1);
      }

      expandedIndex.value = -1;

      updateInnerValue();
    }

    function onCancelItem () {
      expanded.value = [];

      expandedIndex.value = -1;
    }

    function onSaveItem (clear = true) {
      const values = innerFormRef.value.getDirtyValues();

      // if there were changes, update
      if (Object.keys(values).length) {
        for (const key in values) {
          innerValue.value[expandedIndex.value][key] = values[key];
          innerValueCopy.value[expandedIndex.value][key] = values[key];
        }

        // if the item was newly added,
        // make it old
        if (innerValueCopy.value[expandedIndex.value].$volatile) {
          innerValueCopy.value[expandedIndex.value].$volatile = false;
        }

        updateInnerValue();
      }

      // Only clear the expanded array if
      // clicking the save button. Saves
      // done by click the row should let
      // v-data-table handle the expanded items
      if (clear) {
        expanded.value = [];
        expandedIndex.value = -1;
      }
    }

    function onClickRow (item, event) {
      // if not editing, expand/collapse
      if (!isEditing.value) {
        event.expand(!event.isExpanded);

        return;
      }

      // if editing and form valid, save and expand/collapse
      if (isCancelable.value) {
        event.expand(!event.isExpanded);
      }
    }

    function onItemExpanded ({ item, value }) {
      // if expanded
      if (value) {
        innerFormRecord.value = item;

        expandedIndex.value = innerValueCopy.value.findIndex((row) => {
          return row.$id === item.$id;
        });
      } else {
        innerFormRecord.value = null;

        expandedIndex.value = -1;
      }
    }
  }
});
</script>
<style>

.g-field-table {
  border: thin solid rgba(0, 0, 0, 0.38) !important;
}

.g-field-table:hover  {
  border: 1px solid currentColor;
}

.g-field-table label {
  left: 4px;
  right: auto;
  position: absolute;
  transform: translateY(-24px) scale(0.75);
  transform-origin: top left;
  top: 18px;
  background: white;
  pointer-events: auto;
  cursor: text;
}

.g-field-table-error {
    border: 2px solid var(--v-error-base) !important
}

.g-field-table-error-label {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
  animation: v-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.g-field-table-error-message {
  line-height: 1.4em !important;
  text-align: justify !important;
  font-size: 12px;
  color: var(--v-error-base) !important;
}

/* .g-field-table-new {} */

.g-field-table-new td:nth-of-type(2)::after {
  position: absolute;
  content: 'NOVO REGISTO';
  left: 138px;
  opacity: 0.7;
  font-weight: 500;
  color: var(--v-success-base);
  position: absolute;
  transform: translate(-50%, -50%);
}

.row-cursor:hover {
  cursor: pointer;
}
</style>
