<template>
  <g-context-card :title="$t('general.bookmarks')">
    <template #default>
      <v-list
        v-if="bookmarks.length"
        nav
        dense
      >
        <v-list-item-group>
          <template
            v-for="(bookmark, i) in bookmarks"
          >
            <v-divider
              v-if="i !== 0"
              :key="'divider-' + i"
              class="mb-1"
            />

            <v-list-item
              :key="'item-' + i"
              :to="bookmark.path"
              exact
              :disabled="loading"
              class="d-flex align-center"
            >
              <v-list-item-avatar>
                <v-icon>
                  {{ bookmark.icon }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  #{{ i + 1 }} {{ bookmark.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-decoration-underline">
                  {{ bookmark.path }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      :disabled="loading"
                      :loading="removingId === bookmark.id"
                      v-bind="attrs"
                      v-on="on"
                      @click.prevent="onClickDelete(bookmark.id)"
                    >
                      <v-icon>
                        mdi-delete-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('general.delete') }}
                  </span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>

      <div v-else class="text--secondary text-center py-2" style="opacity: 0.7">
        {{ $t('general.empty') }}
      </div>
    </template>
  </g-context-card>
</template>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useBookmarks } from '@/stores/user/bookmarks';
import useToast from '@/composables/use-toast';
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  props: {
    toolId: {
      type: String,
      required: true
    }
  },

  setup () {
    const i18n = useI18n();
    const snackbar = useToast();
    const bookmarksStore = useBookmarks();

    const bookmarks = computed(() => bookmarksStore.rows);
    const loading = computed(() => bookmarksStore.loading);

    const removingId = ref(null);

    return {
      bookmarks,
      loading,
      removingId,

      onClickDelete
    };

    async function onClickDelete (id) {
      removingId.value = id;

      const { error } = await bookmarksStore.delete({ id });

      if (!error) {
        await bookmarksStore.get();
      } else {
        snackbar.alert(i18n.t('general.error_deleting_bookmark'));
      }

      removingId.value = null;
    }
  }
});
</script>

<style scoped>
.text-justify {
    text-align: justify;
    text-justify: inter-word;
}
</style>
