import Vue from 'vue';

Vue.directive('focus', {
  bind (el, binding) {
    if (binding.value) {
      el.vfocus = true;
    }
  },

  inserted: function (el) {
    if (el.vfocus) {
      const input = el.querySelector('input:not([type=hidden]),textarea:not([type=hidden])');

      if (input) {
        setTimeout(() => {
          input.focus();
        }, 0);
      }
    }
  }
});
