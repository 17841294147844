<template>
  <g-content
    :title.sync="title"
    :entity="entity"
    :has-create="allowCreate"
    :has-update="allowUpdate"
    :has-delete="allowDelete"
    :disable-update="!tableSelection.length"
    :disable-delete="!tableSelection.length"
    :create-new-record-label="createNewRecordLabel"
    :update-selected-record-label="updateSelectedRecordLabel"
    :delete-selected-record-label="deleteSelectedRecordLabel"
    :archive-selected-record-label="archiveSelectedRecordLabel"
    :disallow-create="disallowCreate"
    :disallow-update="disallowUpdate"
    :disallow-delete="disallowDelete"
    @click:create="onClickCreateRow"
    @click:update="onClickUpdateRow"
    @click:delete="onClickDeleteRow"
  >
    <!-- Slot for custom header tools-->
    <template #header-tools="headerParams">
      <slot name="header-tools" v-bind="headerParams" />
    </template>

    <g-table-base
      :class="{
        'fullscreen-table': true,
        'row-cursor': allowInspect
      }"
      :show-select="allowUpdate || allowDelete"
      :headers="tableState.headers"
      :rows="items"
      :total="total"
      :loading="loading"
      :options.sync="tableState.options"
      :selection.sync="tableSelection"
      :item-class="itemClass"
      :dense="tableState.dense"
      :striped="tableState.striped"
      :multi-sort="tableState.multiSort"
      :error="error"
      @click:row="onClickRow"
    />
  </g-content>
</template>

<script>
import {
  defineComponent,
  ref,
  toRaw,
  toRefs,
  watch
} from '@vue/composition-api';
import { useRouter } from '@nuxtjs/composition-api';
import { usePresentationState } from '@/stores/system/presentation-state';
import { useI18n } from '@/composables/useI18n';
import { useSync } from '@/composables/useSync';
import { useModalForm } from '@/composables/useModalForm';
import { useExport } from '@/composables/use-export';

export default defineComponent({
  props: {
    caller: {
      type: String,
      required: true
    },

    entity: {
      type: String,
      required: true
    },

    headers: {
      type: Array,
      required: true
    },

    items: {
      type: Array,
      default: () => []
    },

    total: {
      type: Number,
      default: 0
    },

    loading: {
      type: Boolean,
      default: false
    },

    primaryKey: {
      type: String,
      default: 'id'
    },

    itemClass: {
      type: Function,
      default: () => null
    },

    options: {
      type: Object,
      default: () => null
    },

    filters: {
      type: Array,
      default: () => null
    },

    selection: {
      type: Array,
      default: () => null
    },

    formTitle: {
      type: String,
      default: ''
    },

    formCreate: {
      type: Array,
      default: () => []
    },

    formCreateHandler: {
      type: Function,
      default: () => () => null
    },

    formUpdate: {
      type: Array,
      default: () => []
    },

    formUpdateHandler: {
      type: Function,
      default: () => () => null
    },

    formDeleteHandler: {
      type: Function,
      default: () => () => null
    },

    allowInspect: {
      type: Boolean,
      default: false
    },

    allowCreate: {
      type: Boolean,
      default: false
    },

    allowUpdate: {
      type: Boolean,
      default: false
    },

    allowDelete: {
      type: Boolean,
      default: false
    },

    exportLocal: {
      type: Boolean,
      default: false
    },

    error: {
      type: String,
      default: null
    },

    createNewRecordLabel: {
      type: String,
      default: null
    },
    updateSelectedRecordLabel: {
      type: String,
      default: null
    },
    deleteSelectedRecordLabel: {
      type: String,
      default: null
    },
    archiveSelectedRecordLabel: {
      type: String,
      default: null
    },

    disallowCreate: {
      type: Boolean,
      default: false
    },

    disallowUpdate: {
      type: Boolean,
      default: false
    },

    disallowDelete: {
      type: Boolean,
      default: false
    },

    dynamicHeaders: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'update:options',
    'update:filters',
    'update:selection',
    'update:headers',
    'refresh'
  ],

  setup (props, { emit }) {
    const i18n = useI18n();
    const exporter = useExport();
    const stateStore = usePresentationState();
    const { openForm } = useModalForm();
    const router = useRouter();

    const {
      formTitle,
      formCreate,
      formCreateHandler,
      formUpdate,
      formUpdateHandler,
      formDeleteHandler,
      headers,
      items,
      exportLocal,
      dynamicHeaders
    } = toRefs(props);

    const caller = dynamicHeaders.value ? toRaw(props.caller) + router?.currentRoute?.fullPath : toRaw(props.caller);

    const title = ref();

    stateStore.initState(caller, {
      type: 'tabular',
      title,
      fields: headers, // TODO: required for reactive filters
      headers: props.headers, // TODO: required to make to toggle column visibility
      filters: props.filters,
      options: props.options,
      count: items.value.length,
      dense: false,
      striped: false,
      multiSort: false,
      exportFn
    }, dynamicHeaders.value);

    const tableState = ref(stateStore.activeState);
    const tableSelection = props.selection
      ? useSync(props, 'selection', emit)
      : ref([]);

    function updateTitles (heads) {
      const headerMap = {};

      heads.forEach((h) => {
        headerMap[h.value] = h;
      });

      tableState.value.headers.forEach((h) => {
        if (headerMap[h.value]) {
          h.text = headerMap[h.value].text;
        }
      });
    }

    watch(items, (value) => { tableState.value.count = value.length; });
    watch(() => tableState.value.headers, value => emit('update:headers', value), { deep: true });
    watch(() => tableState.value.options, value => emit('update:options', value), { immediate: true });
    watch(() => tableState.value.filters, filters => emit('update:filters', filters), { immediate: true });
    watch(() => tableState.value.options, () => emit('refresh'));
    watch(() => tableState.value.filters, () => { tableState.value.options.page = 1; emit('refresh'); });
    watch(() => props.headers, (value) => {
      if (!dynamicHeaders.value) {
        updateTitles(value);
      } else {
        tableState.value.headers = value;
      }
    });

    return {
      title,
      tableState,
      tableSelection,
      onClickRow,
      onClickCreateRow,
      onClickUpdateRow,
      onClickDeleteRow
    };

    function exportFn () {
      if (exportLocal.value) {
        exporter.exportCSV(tableState.value.headers, items.value, i18n);
      } else {
        emit('export');
      }
    }

    function onClickRow (row) {
      emit('click:row', row);
    }

    function onClickCreateRow () {
      if (formCreate.value.length) {
        openForm(i18n.t('general.create') + ' ' + formTitle.value, { fields: formCreate, record: {} }, formCreateHandler.value);
      } else {
        emit('click:create');
      }
    }

    function onClickUpdateRow () {
      const record = tableSelection.value[0];

      if (formUpdate.value.length) {
        openForm(i18n.t('general.update') + ' ' + formTitle.value, { fields: formUpdate, record }, formUpdateHandler.value);
      } else {
        emit('click:update', record);
      }
    }

    function onClickDeleteRow () {
      const record = tableSelection.value[0];

      formDeleteHandler.value(record);
    }
  }
});
</script>
<style scoped>
.fullscreen-table {
  height: calc(100vh - 122px);
}

.fullscreen-table >>> .v-data-table__wrapper {
  height: calc(100% - 60px);
}

.row-cursor >>> tbody tr :hover {
  cursor: pointer;
}
</style>
