function download (content, name) {
  const link = document.createElement('a');
  link.download = name;
  link.href = encodeURI(content);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function exportCSV (headers, items, i18n) {
  let first = true;
  let csvContent = 'data:text/csv;charset=utf-8,';

  items.forEach((r) => {
    const values = [];
    const columns = [];
    headers.forEach((h) => {
      if (!h.hidden && !h.renderless) {
        columns.push('"' + h.text + '"');
        if (typeof r[h.value] === 'boolean') {
          values.push('"' + (r[h.value] ? i18n.t('general.yes') : i18n.t('general.no')) + '"');
        } else if (r[h.value] === null) {
          values.push('""');
        } else {
          values.push('"' + r[h.value] + '"');
        }
      }
    });

    if (first) {
      csvContent += columns.join(',');
      csvContent += '\n';
      first = false;
    }
    csvContent += values.join(',');
    csvContent += '\n';
  });

  download(csvContent, 'export');
}

export function useExport () {
  return {
    exportCSV
  };
}

export default useExport;
