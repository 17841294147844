import { defineStore } from 'pinia';

export const usePresentationState = defineStore('presentation-state', {
  state: () => ({
    presentations: {},
    saveState: {},
    test: {},
    activeId: null
  }),

  getters: {
    activeState (state) {
      return state.presentations[this.activeId];
    },

    activeFilters (state) {
      return state.presentations[this.activeId]?.filters;
    },

    activeFields (state) {
      return state.presentations[this.activeId]?.fields;
    },

    activeOptions (state) {
      return state.presentations[this.activeId]?.options;
    },
    activeType (state) {
      return state.presentations[this.activeId]?.type;
    },
    activeBadges (state) {
      return state.presentations[this.activeId]?.badges;
    }
  },

  actions: {
    initState (id, state, preventSave = false) {
      this.activeId = id;
      if (!this.presentations[id]) {
        if (!preventSave && this.saveState[id]) {
          state.striped = this.saveState[id].striped;
          state.dense = this.saveState[id].dense;
          state.options.itemsPerPage = this.saveState[id].itemsPerPage;
          state.multiSort = this.saveState[id].multiSort;

          state.headers.forEach((h) => {
            if (this.saveState[id].headers[h.value].hidden !== undefined) {
              h.hidden = this.saveState[id].headers[h.value].hidden;
            }
          });
        }
        this.presentations[id] = state;
        this.presentations[id].badges = {};
      }
    },
    loadState (id, state) {
      if (!this.saveState[id]) {
        this.saveState[id] = state;
      }
    }
  }
});
