var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    staticClass: "v-search-field",
    attrs: {
      items: _vm.items,
      loading: _vm.loading,
      "search-input": _vm.search,
      "hide-no-data": _vm.firstLoad,
      "hide-selected": "",
      outlined: "",
      clearable: _vm.clearable,
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      label: _vm.label,
      placeholder: _vm.innerPlaceholder,
      "return-object": "",
      "no-filter": "",
      readonly: _vm.readonly,
      disabled: _vm.disabled,
      hint: _vm.hint,
      "persistent-hint": _vm.persistentHint,
      "error-messages": _vm.errorMessages,
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event
      },
      "update:search-input": function ($event) {
        _vm.search = $event
      },
      input: _vm.onInput,
      blur: _vm.onBlur,
      focus: _vm.onFocus,
    },
    scopedSlots: _vm._u(
      [
        _vm.filterOptions.length
          ? {
              key: "append",
              fn: function () {
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "", dense: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: _vm.errorMessages.length
                                            ? "error"
                                            : null,
                                          text: "",
                                          small: "",
                                        },
                                        on: {
                                          mouseup: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.searchByText) +
                                          "\n          "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v(
                                        "\n            mdi-filter-variant\n          "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3333218445
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        _vm._l(_vm.filterOptions, function (item, index) {
                          return _c(
                            "v-list-item",
                            {
                              key: index,
                              on: {
                                click: function ($event) {
                                  _vm.searchBy = item.value
                                  _vm.searchByText = item.text
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.text)),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
        _vm.itemTpl
          ? {
              key: "item",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(_vm.itemTpl, { tag: "component", attrs: { item: item } }),
                ]
              },
            }
          : null,
      ],
      null,
      true
    ),
    model: {
      value: _vm.vModel,
      callback: function ($$v) {
        _vm.vModel = $$v
      },
      expression: "vModel",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }