var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-stepper",
    {
      attrs: { vertical: "", flat: "" },
      model: {
        value: _vm.step,
        callback: function ($$v) {
          _vm.step = $$v
        },
        expression: "step",
      },
    },
    [
      _vm._l(_vm.forms, function (form, i) {
        return [
          _c(
            "v-stepper-step",
            {
              key: "setp-" + i,
              attrs: { complete: _vm.step > i + 1, step: i + 1 },
            },
            [
              _vm._v("\n      " + _vm._s(form.title) + "\n\n      "),
              form.summary
                ? _c("small", [_vm._v(" " + _vm._s(form.summary))])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-stepper-content",
            { key: "content-" + i, attrs: { step: i + 1 } },
            [
              _c("g-form-base", {
                ref: "formsRef",
                refInFor: true,
                staticClass: "mb-12 mt-1",
                attrs: { valid: _vm.valid[i], fields: form.fields },
                on: {
                  "update:valid": function ($event) {
                    return _vm.$set(_vm.valid, i, $event)
                  },
                },
              }),
              _vm._v(" "),
              i < _vm.forms.length - 1
                ? _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid[i], color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onClickNext(i)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.continue")) +
                          "\n      "
                      ),
                    ]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid[i], color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onClickSubmit(i)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.submit")) +
                          "\n      "
                      ),
                    ]
                  ),
              _vm._v(" "),
              i !== 0
                ? _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onClickPrevious(i)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.previous")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }