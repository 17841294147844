<template>
  <g-context-card :title="$t('general.settings')">
    <template #default>
      <div class="py-2">
        <v-select
          v-model="lang"
          outlined
          :label="$t('general.language')"
          :items="countries"
          :return-object="false"
          @change="onChangeLocale"
        />
        <v-select
          v-model="dateFormat"
          outlined
          :label="$t('general.dateFormat')"
          :items="formats"
          :return-object="false"
          @change="onChangeSettings($event, 'date_format')"
        />
        <!-- <v-switch v-model="theme.dark" :label="$t('general.dark_mode')" /> -->
      </div>
    </template>
  </g-context-card>
</template>
<script>
import { useContext } from '@nuxtjs/composition-api';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useI18n } from '@/composables/useI18n';
import { useUser } from '@/stores/auth/user';

export default defineComponent({
  props: {
    toolId: {
      type: String,
      required: true
    }
  },

  setup () {
    const i18n = useI18n();
    const { $vuetify } = useContext();

    const lang = ref(i18n.locale);
    const userStore = useUser();
    const dateFormat = ref(userStore?.preferences?.date_format ? userStore.preferences.date_format : 'numeric');
    const theme = $vuetify.theme;

    const countries = computed(() => [
      {
        text: i18n.t('lang.en'),
        value: 'en'
      },
      {
        text: i18n.t('lang.pt'),
        value: 'pt'
      }
    ]);

    const formats = computed(() => [
      {
        text: 'Numeric',
        value: 'numeric'
      },
      {
        text: 'Short',
        value: 'short'
      },
      {
        text: 'Long',
        value: 'long'
      },
      {
        text: 'Custom',
        value: 'custom'
      }
    ]);

    // const options = {};
    // console.log(i18n.d(new Date(), 'short', 'ja-JP'));

    // contains available locales
    // ref(i18n.availableLocales);

    return {
      i18n,
      theme,
      countries,
      lang,
      formats,
      dateFormat,

      onChangeLocale,
      onChangeSettings
    };

    async function onChangeLocale (value) {
      i18n.setLocale(value);
      $vuetify.lang.current = value;

      const preferences = {};

      if (userStore?.preferences) {
        preferences.preferences = userStore.preferences;
        preferences.preferences.language = value;
      } else {
        preferences.preferences = {
          language: value
        };
      }
      await userStore.update(preferences);
      await userStore.read();
    }

    async function onChangeSettings (value, type) {
      const preferences = {};

      if (userStore?.preferences) {
        preferences.preferences = userStore.preferences;
        preferences.preferences[type] = value;
      } else {
        preferences.preferences = {
          [type]: value
        };
      }
      await userStore.update(preferences);
      await userStore.read();
    }
  }
});
</script>
