// computes equality between two json objs
function isEqualObj (obj1, obj2) {
  if (typeof obj1 === typeof obj2 && typeof obj1 !== 'object') {
    return obj1 === obj2;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  let isEqual = true;

  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) {
      isEqual = false;
      break;
    }
  }

  return isEqual;
}

// computes equality between two array of objects
function isEqualArr (arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  let isEqual = true;
  let i = 0;

  while (isEqual && i < arr1.length) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    if (!isEqualObj(obj1, obj2)) {
      isEqual = false;
      break;
    }

    i++;
  }

  return isEqual;
}

export function useArrayEquality () {
  return {
    isEqualArr
  };
}
