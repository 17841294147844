var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      class: { "grey--background lighten-5": !_vm.dark },
      staticStyle: { "z-index": "4" },
      attrs: {
        right: !_vm.mobile,
        permanent: !_vm.mobile,
        fixed: _vm.mobile,
        absolute: _vm.mobile,
        width: _vm.width,
        app: "",
      },
      model: {
        value: _vm.showMobile,
        callback: function ($$v) {
          _vm.showMobile = $$v
        },
        expression: "showMobile",
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex fill-height" },
        [
          _c(
            "v-list",
            { attrs: { dense: "", nav: "", width: "54" } },
            [
              _c(
                "v-list-item-group",
                {
                  staticClass: "d-flex flex-column",
                  staticStyle: { height: "100%" },
                  attrs: { mandatory: _vm.mobile, color: "primary" },
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                },
                [
                  _vm._l(_vm.tools, function (tool, i) {
                    return [
                      _c(
                        "v-tooltip",
                        {
                          key: i,
                          attrs: { left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.allowed.includes(
                                                  tool.id
                                                ),
                                                expression:
                                                  "allowed.includes(tool.id)",
                                              },
                                            ],
                                            style: !tool.spacer
                                              ? "max-height: 48px"
                                              : "",
                                            attrs: {
                                              disabled: tool.spacer,
                                              value: i,
                                            },
                                          },
                                          "v-list-item",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _vm.badges[tool.id]
                                              ? _c(
                                                  "v-badge",
                                                  {
                                                    attrs: {
                                                      content:
                                                        _vm.badges[tool.id],
                                                      color: "primary",
                                                      overlap: "",
                                                    },
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(tool.icon) +
                                                          "\n                    "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _c("v-icon", [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(tool.icon) +
                                                      "\n                  "
                                                  ),
                                                ]),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("v-list-item-content"),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(tool.tooltip))]),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show || _vm.mobile,
                  expression: "show || mobile",
                },
              ],
              style: _vm.mobile ? "flex: 1;" : "width: 300px;",
            },
            [
              _c(_vm.cmp, {
                tag: "component",
                class: { "grey--background lighten-5": !_vm.dark },
                attrs: { "tool-id": _vm.toolId },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }