var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g-context-card", {
    attrs: { title: _vm.$t("general.settings") },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "py-2" },
              [
                _c("v-select", {
                  attrs: {
                    outlined: "",
                    label: _vm.$t("general.language"),
                    items: _vm.countries,
                    "return-object": false,
                  },
                  on: { change: _vm.onChangeLocale },
                  model: {
                    value: _vm.lang,
                    callback: function ($$v) {
                      _vm.lang = $$v
                    },
                    expression: "lang",
                  },
                }),
                _vm._v(" "),
                _c("v-select", {
                  attrs: {
                    outlined: "",
                    label: _vm.$t("general.dateFormat"),
                    items: _vm.formats,
                    "return-object": false,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onChangeSettings($event, "date_format")
                    },
                  },
                  model: {
                    value: _vm.dateFormat,
                    callback: function ($$v) {
                      _vm.dateFormat = $$v
                    },
                    expression: "dateFormat",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }