var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column justify-center align-center",
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "bg-shadow",
          attrs: { icon: "", width: "80", height: "80", ripple: false },
          on: {
            click: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "v-icon",
            { staticStyle: { "font-size": "56px" }, attrs: { color: "error" } },
            [_vm._v("\n      mdi-alert-circle-outline\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("h1", { staticClass: "mt-4" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
      ]),
      _vm._v(" "),
      _c("h4", { staticClass: "font-weight-medium mt-3 text--secondary" }, [
        _vm._v("\n    " + _vm._s(_vm.subtitle) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }