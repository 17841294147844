var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-overlay",
    {
      attrs: { absolute: "", "z-index": "100" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pb-2 px-2", attrs: { light: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "d-flex flex-column" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "bg-shadow mb-3",
                  attrs: { "x-large": "", icon: "", color: "error" },
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("\n          " + _vm._s(_vm.icon) + "\n        "),
                  ]),
                ],
                1
              ),
              _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                _vm._l(_vm.btns, function (btn, i) {
                  return _c(
                    "v-col",
                    { key: i },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "bg-shadow",
                          attrs: {
                            block: "",
                            color: btn.color,
                            text: !!btn.outlined,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClickBtn(btn)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(btn.text) + "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }