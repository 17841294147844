import { ref } from '@vue/composition-api';

const message = ref({
  type: '',
  text: '',
  color: '',
  icon: '',
  show: false
});

function getIcon (value) {
  let icon = '';

  switch (value) {
    case 'error':
      icon = 'mdi-alert-octagon-outline';
      break;
    case 'success':
      icon = 'mdi-check-circle-outline';
      break;
    case 'info':
      icon = 'mdi-information-outline';
      break;
    case 'warn':
    default:
      icon = 'mdi-alert-decagram-outline';
      break;
  }

  return icon;
}

function getColor (value) {
  let color = '';

  switch (value) {
    case 'error':
      color = 'error';
      break;
    case 'success':
      color = 'success';
      break;
    case 'info':
      color = 'info';
      break;
    case 'warn':
      color = 'warning';
      break;
  }

  return color;
}

function pushMsg (type, text) {
  const icon = getIcon(type);
  const color = getColor(type);

  message.value = {
    icon,
    color,
    text,
    show: true
  };
}

function getMessage () { return message; }
function alert (text) { pushMsg('error', text); }
function warn (text) { pushMsg('warn', text); }
function info (text) { pushMsg('info', text); }
function success (text) { pushMsg('success', text); }

export function useToast () {
  return {
    alert,
    warn,
    info,
    success,

    getMessage
  };
}

export default useToast;
