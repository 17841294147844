<template>
  <v-card flat class="pt-2 d-flex flex-column justify-center align-center transparent" width="100%">
    <v-carousel
      v-if="carousel"
      :height="size * 2"
      hide-delimiters
    >
      <v-carousel-item v-if="!photos.length" class="g-empty-carousel-text">
        <span class="title font-weight-bold" style="color: rgba(0,0,0,0.3) !important;">
          {{ $t('general.empty') }}
        </span>
      </v-carousel-item>

      <template v-else>
        <v-carousel-item
          v-for="(item,i) in photos"
          :key="i"
          :src="item.foto"
          reverse-transition="fade-transition"
          transition="fade-transition"
        />
      </template>
    </v-carousel>
    <v-avatar
      v-else
      class="my-2"
      :size="size"
      style="border: 2px solid var(--v-primary-lighten2)"
    >
      <v-img
        v-if="src"
        :width="size"
        :src="innerSrc"
      />

      <v-icon v-else color="primary" style="font-size: 4rem;">
        {{ defaultIcon }}
      </v-icon>
    </v-avatar>

    <v-list width="100%" dense class="font-weight-medium transparent">
      <v-list-item class="text-h6 primary--text">
        <div class="text-center" style="width: 100%">
          {{ title }}
        </div>
      </v-list-item>

      <v-list-item v-if="secTitle && !secTitleLnk" class="text-h7 primary--text">
        <div class="text-center" style="width: 100%">
          {{ secTitle }}
        </div>
      </v-list-item>

      <v-list-item v-if="secTitleLnk" class="text-h7 primary--text">
        <div class="text-center" style="width: 100%">
          <a :href="secTitleLnk" target="_blank">{{ secTitle }}</a>
        </div>
      </v-list-item>

      <v-list-item v-if="subtitle" class="primary--text text-subtitle-2 pt-3">
        <div class="text-center" style="width: 100%">
          <v-chip color="primary" class="px-4">
            {{ subtitle }}
          </v-chip>
        </div>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import { computed, defineComponent, toRefs } from '@vue/composition-api';

export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 180
    },

    src: {
      default: null
    },

    fromBase64: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: null
    },

    secTitle: {
      type: String,
      default: null
    },

    secTitleLnk: {
      type: String,
      default: null
    },

    subtitle: {
      type: String,
      default: null
    },

    defaultIcon: {
      type: String,
      default: 'mdi-account-outline'
    },

    carousel: {
      type: Boolean,
      default: false
    },
    photos: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    const { src, fromBase64 } = toRefs(props);

    const innerSrc = computed(() => {
      if (fromBase64.value) {
        const str = bufferToBase64(src.value);

        return `data:image/png;base64,${str}`;
      } else {
        return src.value;
      }
    });

    return { innerSrc };

    function bufferToBase64 (buffer) {
      if (!buffer) {
        return null;
      }

      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      return window.btoa(binary);
    }
  }
});
</script>
<style scoped>
.v-list-item--dense, .v-list--dense .v-list-item {
  min-height: 32px !important;
}

.g-empty-carousel-text >>> .v-responsive__content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
