<template>
  <!-- <v-row no-gutters> -->
  <v-navigation-drawer
    v-model="open"
    class="multi-navigation"
    style="z-index: 4"
    :permanent="!mobile"
    :absolute="mobile"
    :width="width"
    rail
    app
  >
    <g-drawer-navigation-mini ref="mininavRef" :open="open" :selected.sync="selected" :show-logout.sync="showLogout" />

    <div
      :class="{
        'd-flex': true,
        'flex-column': true,
        'grey--background lighten-5': !dark
      }"
      :style="`width: ${widthInner};`"
    >
      <g-app-title />

      <v-list
        nav
        :class="{ 'pa-0': true }"
        style="flex: 1;"
      >
        <v-list-item-group
          color="primary"
          class="py-2 px-4"
        >
          <v-list-item
            v-for="child in selected.childs"
            :key="child.route"
            :to="child.route"
          >
            <v-list-item-icon>
              <v-icon> {{ child.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: normal;">
              {{ child.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-spacer />

      <v-divider class="" />

      <v-speed-dial
        v-model="showLogout"
        direction="top"
        open-on-hover
        transition="slide-y-reverse-transition"
      >
        <template #activator>
          <v-list class="pa-0">
            <v-list-item-group>
              <v-list-item
                to="/user/profile"
                height="72px"
                link
                no-action
                @click="onChangeNavUser"
              >
                <div style="height: 64px" class="px-6 subtitle-2 d-flex flex-column justify-center">
                  <div class="text--secondary caption font-weight-bold" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; line-height: 1rem !important;">
                    {{ userTitle }}
                  </div>
                  <div class="text--secondary font-weight-bold" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                    {{ userName }}
                  </div>
                </div>
                <v-list-item-content />
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </template>

        <v-btn text plain elevation="0" @click="onLogout">
          {{ $t('general.logout') }}
        </v-btn>
      </v-speed-dial>
    </div>
  </v-navigation-drawer>
</template>
<script>
import { useContext } from '@nuxtjs/composition-api';
import { defineComponent, ref, computed, watch, onUnmounted } from '@vue/composition-api';
import { useUser } from '@/stores/auth/user';

export default defineComponent({
  setup () {
    const { $vuetify, $eventBus } = useContext();
    const userStore = useUser();

    const mininavRef = ref();
    const open = ref(false);
    const width = ref(null);
    const widthInner = ref(null);
    const selected = ref({});
    const showLogout = ref(false);

    const mobile = computed(() => $vuetify.breakpoint.mobile);
    const dark = computed(() => $vuetify.theme.dark);
    const userName = computed(() => userStore.fullname);
    const userTitle = computed(() => {
      if (!userStore.details) {
        return '';
      }

      const { title } = userStore.details;

      return title;
    });

    $eventBus.$on('open:navigation', value => (open.value = true));

    watch(
      mobile,
      (value) => {
        open.value = !value;
        width.value = '300px';
        widthInner.value = '228px';
      },
      { immediate: true }
    );

    onUnmounted(() => {
      const { $eventBus } = useContext();

      $eventBus.$off('open:navigation');
    });

    return {
      mininavRef,
      open,
      width,
      widthInner,
      selected,
      userName,
      userTitle,

      mobile,
      dark,
      showLogout,

      onLogout,
      onChangeNavUser
    };

    async function onLogout () {
      await mininavRef.value.onLogout();
    }

    function onChangeNavUser () {
      mininavRef.value.onChangeNavUser();
    }
  }
});
</script>
<style scoped>
.multi-navigation >>> .v-navigation-drawer__content {
  display: flex;
}

.grey--background.lighten-5 {
  background: #FAFAFA;
}

.blue-grey--background.lighten-5 {
  background: #ECEFF1;
}
</style>
