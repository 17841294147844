<template>
  <v-rating
    :color="color"
    :background-color="emptyColor"
    :full-icon="icon"
    :empty-icon="emptyIcon"
    :length="length"
    :value="value"
    size="20"
    readonly
    dense
  />
</template>
<script>
import { computed, defineComponent, toRef } from '@vue/composition-api';

export default defineComponent({
  props: {
    field: {
      type: String,
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    icon: {
      type: String,
      default: 'mdi-star'
    },

    emptyIcon: {
      type: String,
      default: 'mdi-star-outline'
    },

    color: {
      type: String,
      default: 'primary'
    },

    emptyColor: {
      type: String,
      default: 'primary'
    },

    length: {
      type: String,
      default: '5'
    }
  },

  setup (props) {
    const item = toRef(props, 'item');
    const field = toRef(props, 'field');

    const value = computed(() => {
      return item.value[field.value];
    });

    return {
      value
    };
  }
});
</script>
