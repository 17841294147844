import { en, pt } from 'vuetify/es5/locale/index.js';

export default ({ app }) => {
  const { vuetify } = app;

  const { lang } = vuetify.framework;

  // lang.t = i18n.t;
  lang.locales = {
    en,
    pt
  };
};
