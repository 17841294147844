import { defineStore } from 'pinia';
import { useFetch } from '@/composables/useFetch';

export const useUser = defineStore('user', {
  state: () => ({
    authenticated: false,
    loading: false,
    user: null,
    error: false
  }),

  getters: {
    isAdmin: () => false,

    isAuthenticated: state => state.authenticated,

    details: state => state.user,

    fullname: (state) => {
      if (!state.details) {
        return '';
      }

      const { first_name, last_name } = state.details;

      return `${first_name} ${last_name}`;
    },

    shortname: (state) => {
      if (!state.fullname) {
        return '';
      }

      const parts = state.fullname.split(' ');

      if (!parts.length) {
        return '';
      }

      const first = parts[0];
      const last = parts.length > 1 ? parts[parts.length - 1] : '';

      return `${first} ${last}`;
    },

    // language: state => state?.user?.language || 'pt'
    language: state => state?.user?.preferences?.language || 'pt',
    preferences: state => state?.user?.preferences || {}
  },

  actions: {
    async read () {
      this.loading = true;

      const opts = {
        method: 'get'
      };

      const { data } = await useFetch('/api/user/me', opts);

      this.user = data;

      this.loading = false;
    },

    async update (values) {
      this.loading = true;

      const { data, error } = await useFetch('/api/user/update', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: {
          query: {
            values,
            where: {}
          }
        }
      });

      this.error = !!error;

      this.loading = false;

      return data;
    },

    async authenticate () {
      const opts = {
        refetch: true,
        method: 'get'
      };

      const { data } = await useFetch('/api/auth/authenticate', opts);

      this.authenticated = data === true;

      return this.authenticated;
    },

    async login (endpoint, payload) {
      this.loading = true;

      const { status, data, error } = await useFetch(`/api/auth/${endpoint}`, {
        method: 'post',
        server: false,
        refetch: true,
        body: {
          ...payload
        }
      });

      this.error = !!error;
      this.loading = false;

      if (status === 200) {
        this.user = data;
        this.authenticated = !!data.id;
      }

      return this.authenticated;
    },

    async logout () {
      const { data } = await useFetch('/api/auth/logout', {
        refetch: true
      });

      if (data) {
        this.user = null;
        this.authenticated = false;
      }

      return this.authenticated;
    },

    async register (first_name, last_name, email, password) {
      this.loading = true;

      const { error } = await useFetch('/api/auth/register', {
        method: 'post',
        server: false,
        refetch: true,
        body: {
          email,
          password,
          first_name,
          last_name
        }
      });

      this.loading = false;

      return error;
    },

    async recover (email) {
      this.loading = true;

      const { error } = await useFetch('/api/auth/recover', {
        method: 'post',
        server: false,
        refetch: true,
        body: {
          email
        }
      });

      this.loading = false;

      return error;
    },

    async confirm (token) {
      this.loading = true;

      const { error } = await useFetch('/api/auth/confirm', {
        method: 'post',
        server: false,
        refetch: true,
        body: {
          token
        }
      });

      this.loading = false;

      return error;
    },

    clean (cleanError = false) {
      this.user = null;
      this.authenticated = false;
      this.loading = false;

      if (cleanError) {
        this.error = false;
      }
    }
  }
});
