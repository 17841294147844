<template>
  <g-context-card :title="$t('general.exports')">
    <template #default>
      <v-list dense>
        <v-list-item class="text--secondary">
          <v-list-item-title>
            <span class="font-weight-bold"> {{ $t('general.data') }}:&nbsp;</span>
            <span class="text--secondary">{{ title }}</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text--secondary">
          <v-list-item-title>
            <span class="font-weight-bold"> {{ $t('general.num_columns') }}:&nbsp;</span>
            <span class="text--secondary">{{ hCount }}</span>
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="text--secondary">
          <v-list-item-title>
            <span class="font-weight-bold"> {{ $t('general.num_lines') }}:&nbsp;</span>
            <span class="text--secondary">{{ rCount }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <div class="mx-3 my-3">
        <v-btn
          color="primary"
          outlined
          block
          class="bg-shadow"
          style="border: none;"
          @click="onExportCSV"
        >
          <v-icon left>
            mdi-download
          </v-icon>
          {{ $t('general.export_data') }}
        </v-btn>
      </div>
    </template>
  </g-context-card>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api';
import { usePresentationState } from '@/stores/system/presentation-state';

export default defineComponent({
  props: {
    toolId: {
      type: String,
      required: true
    }
  },

  setup () {
    const stateStore = usePresentationState();

    const title = computed(() => stateStore?.activeState?.title);
    const hCount = computed(() => stateStore?.activeState?.headers?.filter(h => !h.hidden && !h.renderless).length);
    const rCount = computed(() => stateStore?.activeState?.count);

    return {
      title,
      hCount,
      rCount,
      onExportCSV
    };

    function onExportCSV () {
      const { exportFn } = stateStore.activeState;

      if (exportFn) {
        exportFn();
      }
    }
  }
});
</script>

<style scoped>
.text-justify {
    text-align: justify;
    text-justify: inter-word;
}
</style>
