var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    class: {
      "v-striped-table": _vm.striped,
    },
    attrs: {
      "fixed-header": "",
      "show-select": _vm.showSelect,
      "single-select": "",
      "multi-sort": _vm.multiSort,
      "checkbox-color": "primary",
      headers: _vm.iHeaders,
      items: _vm.rows,
      "item-key": _vm.itemKey,
      "server-items-length": _vm.total ? _vm.total : _vm.rows.length,
      options: _vm.iOptions,
      loading: _vm.loading,
      "footer-props": {
        "items-per-page-options": [10, 25, 50],
      },
      "item-class": _vm.itemClass,
      "hide-default-footer": _vm.hideFooter,
      dense: _vm.dense,
    },
    on: {
      "update:headers": function ($event) {
        _vm.iHeaders = $event
      },
      "update:options": function ($event) {
        _vm.iOptions = $event
      },
      "click:row": _vm.onClickRow,
      "item-selected": _vm.onItemSelected,
    },
    scopedSlots: _vm._u(
      [
        _vm.error
          ? {
              key: "no-data",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "pa-4", staticStyle: { opacity: "0.8" } },
                    [
                      _c("v-icon", { attrs: { left: "", color: "error" } }, [
                        _vm._v("\n        mdi-alert-circle-outline\n      "),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "error--text" }, [
                        _vm._v("\n        " + _vm._s(_vm.error) + "\n      "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
        _vm._l(_vm.renderers, function (config, i) {
          return {
            key: "item." + config.value,
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  config.renderer.component,
                  _vm._g(
                    _vm._b(
                      {
                        key: i,
                        tag: "component",
                        attrs: {
                          item: item,
                          field: config.renderer.value
                            ? config.renderer.value
                            : config.value,
                        },
                      },
                      "component",
                      config.renderer.params,
                      false
                    ),
                    config.renderer.events
                  )
                ),
              ]
            },
          }
        }),
      ],
      null,
      true
    ),
    model: {
      value: _vm.iSelection,
      callback: function ($$v) {
        _vm.iSelection = $$v
      },
      expression: "iSelection",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }