var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { staticClass: "transparent", attrs: { dense: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        _vm._l(_vm.items, function (item, i) {
          return _c(
            "v-col",
            {
              key: i,
              attrs: { cols: "12", sm: "12", md: "12", lg: item.cols || 12 },
            },
            [
              _c(
                "v-list-item",
                { staticClass: "text--secondary d-flex align-start" },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(" " + _vm._s(item.text) + ": "),
                  ]),
                  _vm._v(" "),
                  !item.isList
                    ? _c("span", { staticClass: "text--secondary" }, [
                        _vm._v(
                          "\n          " + _vm._s(item.value) + "\n        "
                        ),
                      ])
                    : _c("span", [
                        _c(
                          "table",
                          { staticStyle: { "list-style-type": "none" } },
                          [
                            _vm._l(item.value, function (row, j) {
                              return [
                                _c(
                                  "tr",
                                  { key: j },
                                  _vm._l(row, function (dt, h) {
                                    return _c(
                                      "td",
                                      {
                                        key: h,
                                        staticClass: "px-2",
                                        style:
                                          "" +
                                          (h !== 0
                                            ? "border-left: thin solid #ddd;"
                                            : "") +
                                          (j !== 0
                                            ? "border-top: thin solid #ddd;"
                                            : ""),
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(dt) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]),
                ]
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }