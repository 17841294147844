var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g-context-card", {
    attrs: { title: _vm.$t("general.help") },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _vm.helpText
              ? _c("div", {
                  staticClass: "text-justify",
                  domProps: { innerHTML: _vm._s(_vm.helpText) },
                })
              : _c(
                  "div",
                  {
                    staticClass: "text--secondary text-center py-2",
                    staticStyle: { opacity: "0.7" },
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("general.empty")) + "\n    "
                    ),
                  ]
                ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }