<template>
  <div>
    <v-sparkline
      style="max-height: 32px"
      :color="color"
      :line-width="lineWidth"
      :height="height"
      :padding="0"
      :smooth="radius"
      :gradient="gradient"
      :value="value"
    />
  </div>
</template>
<script>
import { computed, defineComponent, toRefs } from '@vue/composition-api';

export default defineComponent({
  props: {
    field: {
      type: String,
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    icon: {
      type: String,
      default: null
    },

    color: {
      type: String,
      default: 'primary'
    },

    height: {
      type: String,
      default: '24'
    },

    lineWidth: {
      type: String,
      default: '2'
    },

    radius: {
      type: String,
      default: '10'
    },

    gradient: {
      type: Array,
      default: () => []
    }
  },

  setup (props) {
    const { field, item } = toRefs(props);

    const value = computed(() => {
      return item.value[field.value];
    });

    return {
      value
    };
  }
});
</script>
