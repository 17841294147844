<template>
  <v-btn class="bg-shadow" small icon :ripple="false">
    <v-icon small :color="config.color">
      {{ config.icon }}
    </v-icon>
  </v-btn>
</template>
<script>
import { computed, defineComponent, toRefs } from '@vue/composition-api';

export default defineComponent({
  props: {
    field: {
      type: String,
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    color: {
      type: String,
      default: 'success'
    },

    offColor: {
      type: String,
      default: 'grey'
    }
  },

  setup (props) {
    const { item, field, color, offColor } = toRefs(props);

    const config = computed(() => {
      const flag = !!item.value[field.value];

      return {
        icon: flag ? 'mdi-circle' : 'mdi-power-off',
        color: flag ? color.value : offColor.value
      };
    });

    return {
      config
    };
  }
});
</script>
