<template>
  <g-context-card :title="$t('general.presentation')">
    <template #default>
      <div class="py-2">
        <v-select
          v-model="visibleFields"
          multiple
          outlined
          dense
          :label="$t('general.active_fields')"
          :items="currentFields"
        />

        <v-select
          v-model="currentMultiSort"
          :label="$t('general.sort_type')"
          outlined
          dense
          :items="[{text: $t('general.sort_type_singular'), value: false}, {text: $t('general.sort_type_multiple'), value: true}]"
        />

        <v-select
          v-model="currentOptions.itemsPerPage"
          outlined
          dense
          :label="$t('general.items_per_page')"
          :items="[10, 25, 50]"
        />

        <v-select
          v-model="currentRowHeight"
          outlined
          dense
          :label="$t('general.row_height')"
          :items="[{text: $t('general.row_height_default'), value: false}, {text: $t('general.row_height_dense'), value: true}]"
        />

        <v-select
          v-model="currentRowStyle"
          outlined
          dense
          :label="$t('general.row_style')"
          :items="[{text: $t('general.row_style_default'), value: false}, {text: $t('general.row_style_striped'), value: true}]"
        />
      </div>
    </template>
  </g-context-card>
</template>
<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { usePresentationState } from '@/stores/system/presentation-state';
import { useUser } from '@/stores/auth/user';

export default defineComponent({
  props: {
    toolId: {
      type: String,
      required: true
    }
  },

  setup () {
    const stateStore = usePresentationState();

    const visibleFields = ref([]);
    const userStore = useUser();

    const activeState = computed(() => stateStore.activeState);

    const currentOptions = computed(() => activeState.value.options ? activeState.value.options : {});
    const currentFields = computed(() => activeState.value.headers ? activeState.value.headers.filter(f => !f.renderless) : []);
    const currentRowHeight = computed({
      get: () => activeState.value.dense,
      set: (value) => { activeState.value.dense = value; }
    });
    const currentRowStyle = computed({
      get: () => activeState.value.striped,
      set: (value) => { activeState.value.striped = value; }
    });
    const currentMultiSort = computed({
      get: () => activeState.value.multiSort,
      set: (value) => { activeState.value.multiSort = value; }
    });

    watch(currentFields, (fields) => {
      if (!fields) {
        visibleFields.value = [];
        return;
      }

      visibleFields.value = fields
        .filter(field => !field.hidden)
        .map(field => field.value);
    }, { immediate: true });

    watch(visibleFields, (vf) => {
      let update = false;
      for (let i = 0; i < currentFields.value.length; i++) {
        const field = currentFields.value[i];

        const found = vf.findIndex(visibleField => visibleField === field.value) > -1;

        if (field.hidden !== !found) {
          field.hidden = !found;

          update = true;
        }
      }

      if (update) {
        activeState.value.headers.splice(1, 0);
        updatePreferences(stateStore.presentations);
      }
    });

    watch(currentMultiSort, (value) => {
      if (!value) {
        currentOptions.value.sortBy?.splice(1, currentOptions.value.sortBy.length);
        currentOptions.value.sortDesc?.splice(1, currentOptions.value.sortDesc.length);
      }

      // force reactivity to reload the table
      if (activeState.value.options) {
        activeState.value.options = {
          ...activeState.value.options
        };
      }
    });

    watch(() => currentOptions.value.itemsPerPage, (value) => {
      updatePreferences(stateStore.presentations);
    },
    { deep: true }
    );

    watch(() => [activeState.value.striped, activeState.value.dense, activeState.value.multiSort], (value) => {
      updatePreferences(stateStore.presentations);
    },
    { deep: true }
    );

    return {
      currentOptions,
      currentFields,
      visibleFields,
      currentRowHeight,
      currentRowStyle,
      currentMultiSort,

      updatePreferences
    };

    async function updatePreferences (value) {
      const preferences = {};
      preferences.preferences = userStore.preferences;

      if (!preferences.preferences.presentations) {
        preferences.preferences.presentations = {};
      }

      const headers = {};
      activeState.value.headers.forEach((v) => {
        const config = { value: v.value };
        if (v.hidden !== undefined) {
          config.hidden = v.hidden;
        }
        headers[v.value] = config;
      });

      preferences.preferences.presentations[stateStore.activeId] = {
        striped: activeState.value.striped,
        dense: activeState.value.dense,
        itemsPerPage: activeState.value.options.itemsPerPage,
        multiSort: activeState.value.multiSort,
        headers
      };

      await userStore.update(preferences);
    }
  }
});
</script>
