<template>
  <v-card-text>
    {{ $t('general.confirm') }}:

    <div class="d-flex">
      <v-list five-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle class="text--primary">
              {{ $t('general.id') }}:
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary">
              {{ $t('general.role') }}:
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary">
              {{ $t('general.entity') }}:
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary">
              {{ $t('general.action') }}:
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text--primary">
              {{ $t('general.possession') }}:
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list five-line>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ record.id }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ role }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ entity }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ action }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ possession }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-card-text>
</template>
<script>
import { computed, defineComponent, toRef } from '@vue/composition-api';

export default defineComponent({
  props: {
    record: {
      type: Object,
      required: true
    }
  },

  setup (props) {
    const record = toRef(props, 'record');

    const role = computed(() => {
      return record.value['Role.name'];
    });

    const entity = computed(() => {
      return record.value['EntityAction.Entity.name'];
    });

    const action = computed(() => {
      return record.value['EntityAction.action'];
    });

    const possession = computed(() => {
      return record.value['EntityAction.possession'];
    });

    return {
      role,
      entity,
      action,
      possession
    };
  }
});
</script>
