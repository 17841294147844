var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "v-alert-shadow ma-0 mt-2",
      attrs: {
        type: _vm.type,
        outlined: "",
        icon: "mdi-alert-octagon-outline",
        elevation: "1",
      },
    },
    [
      _c(
        "div",
        { staticClass: "body-1" },
        [_vm._v("\n    " + _vm._s(_vm.bodyText) + "\n\n    "), _vm._t("body")],
        2
      ),
      _vm._v(" "),
      _vm.hasAction
        ? _c(
            "v-btn",
            {
              attrs: { color: _vm.type, outlined: "", small: "" },
              on: { click: _vm.onClickBtn },
            },
            [
              _vm.actionIcon
                ? _c("v-icon", [
                    _vm._v("\n      " + _vm._s(_vm.actionIcon) + "\n    "),
                  ])
                : _vm._e(),
              _vm._v("\n    " + _vm._s(_vm.actionText) + "\n  "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }