import axios from 'axios';
import { useFetch } from '@/composables/useFetch';

function parseError (error) {
  if (!error) { return null; }

  return error.response?.data;
}

function createReadState (offset, limit, order, filters) {
  let rs = '';

  rs = `${offset}${limit}${order}`;
  rs += JSON.stringify(filters);

  return rs;
}

export async function get (ctx) {
  const { data, error } = await useFetch(ctx.api.get, {
    method: 'GET'
  });

  return {
    error: parseError(error),
    rows: error ? [] : data.result,
    total: error ? 0 : data.total
  };
}

export async function read (ctx, params, opts = {}) {
  const query = {};
  const force = !!opts?.force;
  const signal = opts?.signal || null;

  if (params && typeof params === 'object') {
    const { page, itemsPerPage, sortBy, sortDesc, filters } = params;

    if (itemsPerPage > 0) {
      query.limit = itemsPerPage;

      if (page > 0) {
        query.offset = (page - 1) * itemsPerPage;
      }
    }

    if (Array.isArray(sortBy) && Array.isArray(sortDesc) && sortBy.length && sortBy.length === sortDesc.length) {
      query.order = sortBy.map((value, i) => [value, sortDesc[i] ? 'DESC' : 'ASC']);
    }

    if (typeof filters === 'object' && Object.keys(filters).length) {
      query.filters = {};

      filters.forEach((filter) => {
        if (!query.filters[filter.field]) {
          query.filters[filter.field] = {};
        }

        query.filters[filter.field][filter.operator] = filter.value;
      });
    }
  }

  const queryState = createReadState(query.offset, query.limit, query.order, query.filters);

  let status = null;
  let error = null;
  let rows = [];
  let total = 0;
  let refresh = false;

  if (force || ctx.readState !== queryState) {
    refresh = true;

    const result = await useFetch(ctx.api.get, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: {
        query
      },
      signal
    });

    error = parseError(result.error);
    rows = error ? [] : result.data.result;
    total = error ? 0 : result.data.total;
    status = result.status;
  } else {
    error = ctx.error;
    rows = ctx.rows;
    total = ctx.total;
  }

  return {
    rows,
    total,
    status,
    error,
    queryState,
    refresh
  };
}

export async function req (method, endpoint, body, opts = {}) {
  const headers = opts?.headers ? opts.headers : {};

  const { data, error } = await useFetch(`/api/${endpoint}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    body
  });

  return {
    error: parseError(error),
    data
  };
}

export async function create (ctx, values) {
  const { data, error, status } = await useFetch(ctx.api.put, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: {
      query: {
        values
      }
    }
  });

  return {
    error: parseError(error),
    data,
    status
  };
}

export async function createMulti (ctx, values, url) {
  const formData = new FormData();

  const keys = Object.keys(values);

  for (let i = 0; i < keys.length; i++) {
    formData.append(keys[i], values[keys[i]]);
  }

  let data = null;
  let error = null;

  try {
    const result = await axios({
      url,
      method: 'PUT',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData
    });

    data = result.data;
    error = result.error;
  } catch (err) {
    error = err;
  }

  return {
    error: parseError(error),
    data
  };
}

export async function update (ctx, values, where) {
  const { data, error } = await useFetch(ctx.api.patch, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: {
      query: {
        values,
        where
      }
    }
  });

  return {
    error: parseError(error),
    data
  };
}

export async function del (ctx, where) {
  const { data, error } = await useFetch(ctx.api.delete, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json;charset=utf-8' },
    body: {
      data: {
        query: {
          where
        }
      }
    }
  });

  return {
    error: parseError(error),
    data
  };
}
