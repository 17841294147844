<template>
  <g-context-card :title="$t('general.filters')">
    <template #default>
      <div class="py-2">
        <g-form-base ref="form" :valid.sync="valid" :fields="fields" :record="record" auto-focus />

        <v-menu
          offset-y
          left
        >
          <template #activator="{ attrs, on }">
            <v-btn
              text
              style="text-transform: none !important;"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary">
                mdi-plus
              </v-icon>
              <h4
                class="ml-2 primary--text"
              >
                {{ $t('general.add_filter') }}
              </h4>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item v-if="!unfilteredFields.length">
              {{ $t('general.no_fields_available') }}
            </v-list-item>

            <v-list-item
              v-for="(field, i) in unfilteredFields"
              :key="i"
              @click="onAddFilter(field)"
            >
              <v-list-item-title> {{ field.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </g-context-card>
</template>
<script>
import { computed, defineComponent, ref, toRef, watch } from '@vue/composition-api';
import _ from 'lodash';
import { usePresentationState } from '@/stores/system/presentation-state';

export default defineComponent({
  props: {
    toolId: {
      type: String,
      required: true
    }
  },

  setup (props) {
    const stateStore = usePresentationState();

    const toolId = toRef(props, 'toolId');

    const fieldsList = ref([]);
    const fields = ref([]);
    const filters = ref([]);
    const valid = ref(false);
    const record = ref({});
    const form = ref(null);

    const filteredFields = computed(() => filters.value.map(f => f.field));
    const unfilteredFields = computed(() => fieldsList.value.filter(f => !filteredFields.value.includes(f.value) && !filteredFields.value.includes(f.fltValue)));

    let debouncer = null;

    watch(() => stateStore.activeState, (state) => {
      fieldsList.value = [];
      fields.value = [];
      filters.value = [];

      if (!state || !state.fields || !state.filters) {
        return;
      }

      const { fields: stateFields, filters: stateFilters } = state;

      fieldsList.value = stateFields.filter(field => !!field.filter);

      stateFilters.forEach((filter, idx) => {
        const fieldCfg = stateFields.find(field => field.value === filter.field || field.fltValue === filter.field);

        if (fieldCfg) {
          addField(fieldCfg, filter);

          if (fieldCfg.fltValue) {
            record.value[fieldCfg.fltValue] = filter.value;
          } else {
            record.value[fieldCfg.value] = filter.value;
          }
        }
      });

      filters.value = _.cloneDeep(stateFilters);
    }, { immediate: true });

    return {
      valid,
      fieldsList,
      fields,
      filters,
      form,

      unfilteredFields,

      record,

      onAddFilter,
      onRemoveFilter,
      emitFilters
    };

    function addField (config, filter = { operator: null }) {
      const text = config.text;
      const field = config.fltValue ? config.fltValue : config.value;
      const { type, params } = config.filter;
      const { operator } = filter;

      fields.value.push({
        component: `g-field-filter-${type}`,
        params: {
          ...params,
          label: text,
          operator
        },
        field,
        events: {
          'change:operator': (val) => {
            const filter = findFilter(filters.value, field);

            if (filter) {
              filter.operator = val;

              emitFilters(0);
            }
          },
          'change:value': ({ value, delayed }) => {
            const filter = findFilter(filters.value, field);

            if (filter) {
              filter.value = value;

              emitFilters(delayed ? 400 : 0);
            }
          },
          remove: () => {
            onRemoveFilter(field);
          }
        },
        rules: 'required'
      });

      record.value[field] = null;
    }

    function addFilter (config) {
      filters.value.push({
        field: config.fltValue ? config.fltValue : config.value,
        type: config.filter.type,
        operator: null,
        value: null
      });
    }

    function onAddFilter (config) {
      addField(config);
      addFilter(config);
    }

    function onRemoveFilter (field) {
      removeFilter(fields.value, field);
      removeFilter(filters.value, field);

      if (form.value) {
        form.value.setValue(field, null);
      }

      emitFilters();
    }

    function findFilter (arr, field) {
      return arr.find(f => f.field === field);
    }

    function findFilterIdx (arr, field) {
      return arr.findIndex(f => f.field === field);
    }

    function removeFilter (arr, field) {
      const idx = findFilterIdx(arr, field);

      if (idx > -1) {
        arr.splice(idx, 1);
      }
    }

    function emitFilters (delay = 0) {
      if (debouncer) { clearTimeout(debouncer); }

      debouncer = setTimeout(() => {
        const effectiveFilters = _.cloneDeep(filters.value);

        // Only send valid filters
        stateStore.activeState.filters = effectiveFilters.filter(f => f.value !== '' && f.value !== null);

        // Update badge count
        stateStore.activeBadges[toolId.value] = stateStore.activeState.filters.length;
      }, delay);
    }
  }
});
</script>
