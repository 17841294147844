var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { staticClass: "pa-0" },
    [
      _vm._l(_vm.items, function (item, i) {
        return [
          i > 0 ? _c("v-divider", { key: "divider-" + i }) : _vm._e(),
          _vm._v(" "),
          _c(
            "v-list-item",
            {
              key: i,
              style: item.disabled
                ? "cursor: not-allowed !important; opacity: 0.4;"
                : "",
              on: {
                click: function ($event) {
                  return _vm.onClickItem(item)
                },
              },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c(
                    "v-icon",
                    {
                      staticStyle: { opacity: "0.84" },
                      attrs: { color: item.color || _vm.defaultColor },
                    },
                    [_vm._v("\n          " + _vm._s(item.icon) + "\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    { class: (item.color || _vm.defaultColor) + "--text" },
                    [_vm._v("\n          " + _vm._s(item.text) + "\n        ")]
                  ),
                  _vm._v(" "),
                  item.subtext
                    ? _c(
                        "v-list-item-subtitle",
                        {
                          class: item.color ? item.color + "--text" : "",
                          style:
                            "text-align: justify; overflow: visible !important;white-space: unset !important;" +
                            (item.color ? "opacity: 0.85" : ""),
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.subtext) + "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              item.events && item.events.click
                ? _c(
                    "v-list-item-icon",
                    [
                      _c(
                        "v-icon",
                        {
                          staticStyle: { opacity: "0.84" },
                          attrs: { color: item.color || _vm.defaultColor },
                        },
                        [_vm._v("\n          mdi-chevron-right\n        ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }