var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    { staticClass: "bg-shadow", attrs: { small: "", icon: "", ripple: false } },
    [
      _c("v-icon", { attrs: { small: "", color: _vm.config.color } }, [
        _vm._v("\n    " + _vm._s(_vm.config.icon) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }