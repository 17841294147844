<template>
  <div>
    <g-field-filter-label
      :label="label"
      :operator.sync="innerOperator"
      :operators="operators"
      @click:remove="onRemove"
    />

    <v-text-field
      v-model="innerValue"
      dense
      outlined
      clearable
      :label="$t('general.value')"
      :error-messages="errorMessages"
      @input="onInput"
    />
  </div>
</template>
<script>
import { computed, defineComponent, ref, toRef, watch } from '@vue/composition-api';
import { useI18n } from '~/composables/useI18n';
import { useSync } from '@/composables/useSync';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },

    value: {
      type: String,
      default: null
    },

    operator: {
      type: String,
      default: null
    },

    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const i18n = useI18n();

    const operator = toRef(props, 'operator');

    const innerValue = useSync(props, 'value', emit);
    const innerOperator = ref('eq');

    if (operator.value) {
      innerOperator.value = operator.value;
    }

    const operators = computed(() => [
      {
        name: i18n.t('general.equal_to'),
        value: 'eq'
      },
      {
        name: i18n.t('general.not_equal_to'),
        value: 'ne'
      },
      {
        name: i18n.t('general.greater_than'),
        value: 'gt'
      },
      {
        name: i18n.t('general.greater_than_equal'),
        value: 'gte'
      },
      {
        name: i18n.t('general.lower_than'),
        value: 'lt'
      },
      {
        name: i18n.t('general.lower_than_equal'),
        value: 'lte'
      }
    ]);

    watch(innerOperator, onChangeOperator, { immediate: true });

    return {
      innerOperator,
      innerValue,

      operators,

      onInput,
      onRemove
    };

    function onInput (value) {
      // first event is required for the form + validations
      emit('input', value);

      emit('change:value', {
        value,
        delayed: true
      });
    }

    function onRemove () {
      emit('remove');
    }

    function onChangeOperator (value) {
      emit('change:operator', value);
    }
  }
});
</script>
