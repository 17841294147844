var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("g-field-filter-label", {
        attrs: {
          label: _vm.label,
          operator: _vm.innerOperator,
          operators: _vm.operators,
        },
        on: {
          "update:operator": function ($event) {
            _vm.innerOperator = $event
          },
          "click:remove": _vm.onRemove,
        },
      }),
      _vm._v(" "),
      _c("v-autocomplete", {
        staticClass: "v-search-field",
        attrs: {
          loading: _vm.loading,
          "search-input": _vm.search,
          dense: "",
          outlined: "",
          label: _vm.$t("general.value"),
          items: _vm.items,
          "item-text": _vm.itemText,
          "item-value": _vm.itemValue,
          placeholder: _vm.innerPlaceholder,
          "return-object": "",
          "no-filter": "",
          "error-messages": _vm.errorMessages,
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.search = $event
          },
          "update:search-input": function ($event) {
            _vm.search = $event
          },
          input: _vm.onInput,
          blur: _vm.onBlur,
          focus: _vm.onFocus,
        },
        model: {
          value: _vm.innerValue,
          callback: function ($$v) {
            _vm.innerValue = $$v
          },
          expression: "innerValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }