<template>
  <g-context-card :title="$t('general.user_groups')">
    <template #default>
      <div class="py-2">
        <v-select
          v-model="currentMembership"
          outlined
          :label="$t('general.selected_group')"
          hide-details
          :menu-props="{
            'transition': 'slide-y-transition',
            'offset-y': true
          }"
          :items="memberships"
          item-text="Membership.name"
          item-value="membership_id"
          :loading="loading"
          @change="onGroupChange"
        />

        <div class="pa-3">
          <span class="subtitle-1">
            {{ $t('general.roles_for_selected_group') }}:
          </span>

          <div class="my-2">
            <v-chip
              v-for="(p, i) in roles"
              :key="i"
              outlined
              class="bg-shadow mr-2"
              style="border: none"
              color="secondary"
            >
              {{ p['Role.name'] }}
            </v-chip>
          </div>
        </div>
      </div>
    </template>
  </g-context-card>
</template>
<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { usePermissions } from '@/stores/user/permissions';
import { useUserMembership } from '@/stores/user/membership';
import { useUserMembershipList } from '@/stores/user/membership-list';
import { useRoutes } from '@/stores/auth/routes';
import { useLoadingOverlay } from '@/composables/use-loading-overlay';
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  props: {
    toolId: {
      type: String,
      required: true
    }
  },

  setup () {
    const i18n = useI18n();
    const userMembershipListStore = useUserMembershipList();
    const userMembershipStore = useUserMembership();
    const userPermissionsStore = usePermissions();
    const userRoutes = useRoutes();
    const overlay = useLoadingOverlay();

    const membership = computed(() => userMembershipStore.row?.membership_id);
    const memberships = computed(() => userMembershipListStore.rows);
    const roles = computed(() => userMembershipStore.row?.roles ? userMembershipStore.row?.roles : []);
    const permissions = computed(() => userPermissionsStore.rows);
    const loading = computed(() => userMembershipListStore.loading);

    const currentMembership = ref();

    watch(membership, (value) => { currentMembership.value = value; }, { immediate: true });
    watch(() => userMembershipStore.loading, (value) => {
      value ? overlay.showLoading(i18n.t('general.switching_membership')) : overlay.hideLoading();
    });

    userMembershipListStore.get();

    return {
      loading,
      roles,
      permissions,
      memberships,
      currentMembership,

      onGroupChange
    };

    async function onGroupChange (value) {
      try {
        const { error } = await userMembershipStore.changeTo(value);

        if (error) {
          throw error;
        }

        userMembershipStore.get();
        userPermissionsStore.get();
        userRoutes.read();
      } catch (e) {

      }
    }
  }
});
</script>
