var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g-context-card", {
    attrs: { title: _vm.$t("general.exports") },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "v-list",
              { attrs: { dense: "" } },
              [
                _c(
                  "v-list-item",
                  { staticClass: "text--secondary" },
                  [
                    _c("v-list-item-title", [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(_vm.$t("general.data")) + ": "),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "text--secondary" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-list-item",
                  { staticClass: "text--secondary" },
                  [
                    _c("v-list-item-title", [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("general.num_columns")) + ": "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "text--secondary" }, [
                        _vm._v(_vm._s(_vm.hCount)),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-list-item",
                  { staticClass: "text--secondary" },
                  [
                    _c("v-list-item-title", [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("general.num_lines")) + ": "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "text--secondary" }, [
                        _vm._v(_vm._s(_vm.rCount)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mx-3 my-3" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "bg-shadow",
                    staticStyle: { border: "none" },
                    attrs: { color: "primary", outlined: "", block: "" },
                    on: { click: _vm.onExportCSV },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("\n          mdi-download\n        "),
                    ]),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("general.export_data")) +
                        "\n      "
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }