var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g-content",
    {
      attrs: {
        entity: _vm.entity,
        "has-create": _vm.allowCreate,
        "has-update": _vm.allowUpdate,
        "has-delete": _vm.allowDelete,
        "disable-update": !_vm.tableSelection.length,
        "disable-delete": !_vm.tableSelection.length,
      },
      on: {
        "click:create": _vm.onClickCreateRow,
        "click:update": _vm.onClickUpdateRow,
        "click:delete": _vm.onClickDeleteRow,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "header-tools",
            fn: function (headerParams) {
              return [_vm._t("header-tools", null, null, headerParams)]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _c("g-table-gallery", {
        class: {
          "fullscreen-table": true,
          "row-cursor": _vm.allowInspect,
        },
        attrs: {
          "show-select": _vm.allowUpdate || _vm.allowDelete,
          headers: _vm.tableState.headers,
          rows: _vm.items,
          total: _vm.total,
          loading: _vm.loading,
          error: _vm.error,
          "image-key": _vm.imageKey,
          "thumb-key": _vm.thumbKey,
          options: _vm.tableState.options,
          selection: _vm.tableSelection,
          dense: _vm.tableState.dense,
          "multi-sort": _vm.tableState.multiSort,
        },
        on: {
          "update:options": function ($event) {
            return _vm.$set(_vm.tableState, "options", $event)
          },
          "update:selection": function ($event) {
            _vm.tableSelection = $event
          },
          "click:row": _vm.onClickRow,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }