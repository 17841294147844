var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-8" },
    [
      _c(
        "v-validation-observer",
        { attrs: { slim: "" } },
        [
          _c(
            "v-validation-provider",
            {
              ref: "validationProvider",
              attrs: { name: "table-field", rules: "", slim: "" },
            },
            [
              _c(
                "v-card",
                {
                  class: {
                    "g-field-table": true,
                    "g-field-table-error": _vm.errorMessages.length,
                  },
                  attrs: { flat: "", outlined: "", disabled: _vm.disabled },
                  on: { focus: _vm.onFocus },
                },
                [
                  _c("div", { staticStyle: { "letter-spacing": "normal" } }, [
                    _c(
                      "label",
                      {
                        class: {
                          "v-label v-label--active theme--light mx-2": true,
                          "g-field-table-error-label": _vm.errorMessages.length,
                        },
                      },
                      [
                        _vm._v(
                          "\n            " + _vm._s(_vm.label) + "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-data-table", {
                    attrs: {
                      "show-expand": "",
                      "single-expand": "",
                      headers: _vm.innerHeaders,
                      items: _vm.innerValueCopy,
                      "hide-default-footer": "",
                      "item-key": _vm.rowKey,
                      expanded: _vm.expanded,
                      "no-data-text": _vm.$t("general.no_items"),
                      "item-class": _vm.rowClassifier,
                    },
                    on: {
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                      "item-expanded": _vm.onItemExpanded,
                      "click:row": _vm.onClickRow,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "expanded-item",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                {
                                  staticClass: "py-6 pt-7 px-6",
                                  attrs: { colspan: _vm.headers.length + 2 },
                                },
                                [
                                  _c(
                                    "v-validation-observer",
                                    { attrs: { slim: "", disabled: "" } },
                                    [
                                      _c("g-form-base", {
                                        ref: "innerFormRef",
                                        attrs: {
                                          fields: _vm.fields,
                                          record: _vm.innerFormRecord,
                                          valid: _vm.formValid,
                                        },
                                        on: {
                                          "update:valid": function ($event) {
                                            _vm.formValid = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-end" },
                                    [
                                      _vm.isCancelable
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { text: "" },
                                              on: { click: _vm.onCancelItem },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-close\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                "\n\n                  " +
                                                  _vm._s(
                                                    _vm.$t("general.cancel")
                                                  ) +
                                                  "\n                "
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onRemoveItemById(
                                                    item.$id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-delete-outline\n                  "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                "\n\n                  " +
                                                  _vm._s(
                                                    _vm.$t("general.delete")
                                                  ) +
                                                  "\n                "
                                              ),
                                            ],
                                            1
                                          ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            disabled: !_vm.formValid,
                                            text: "",
                                            outlined: "",
                                          },
                                          on: { click: _vm.onSaveItem },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [
                                              _vm._v(
                                                "\n                    mdi-content-save-outline\n                  "
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            "\n\n                  " +
                                              _vm._s(_vm.$t("general.save")) +
                                              "\n                "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        _vm._l(_vm.renderers, function (config, i) {
                          return {
                            key: "item." + config.value,
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c(
                                  config.renderer.component,
                                  _vm._g(
                                    _vm._b(
                                      {
                                        key: i,
                                        tag: "component",
                                        attrs: {
                                          item: item,
                                          field: config.renderer.value
                                            ? config.renderer.value
                                            : config.value,
                                        },
                                      },
                                      "component",
                                      config.renderer.params,
                                      false
                                    ),
                                    config.renderer.events
                                  )
                                ),
                              ]
                            },
                          }
                        }),
                        {
                          key: "item.data-table-expand",
                          fn: function (ref) {
                            var item = ref.item
                            var expand = ref.expand
                            var isExpanded = ref.isExpanded
                            return [
                              _c(
                                "td",
                                { staticClass: "text-start" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "v-data-table__expand-icon",
                                      class: {
                                        "v-data-table__expand-icon--active":
                                          isExpanded,
                                      },
                                      attrs: {
                                        disabled:
                                          _vm.isEditing && !_vm.isCancelable,
                                        icon: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.onClickRow(item, {
                                            expand: expand,
                                            isExpanded: isExpanded,
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-down"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.actions",
                          fn: function (ref) {
                            var index = ref.index
                            var item = ref.item
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _vm.allowDelete || item.$new
                                              ? _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "bg-shadow",
                                                        attrs: {
                                                          icon: "",
                                                          small: "",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { small: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.onRemoveItem(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    mdi-delete-outline\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("general.delete")) +
                                        "\n              "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c("v-divider", { attrs: { width: "100%" } }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center mx-2",
                          staticStyle: { height: "48px", width: "100%" },
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "text--secondary" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.innerValueCopy.length) +
                                  " " +
                                  _vm._s(
                                    _vm.$tc(
                                      "general.item",
                                      _vm.innerValueCopy.length === 1 ? 1 : 0
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                text: "",
                                disabled: _vm.isEditing || _vm.disabled,
                              },
                              on: { click: _vm.onAddItem },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(
                                  "\n                mdi-plus\n              "
                                ),
                              ]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("general.add_item")) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "g-field-table-error-message ma-3" }, [
        _vm._v("\n    " + _vm._s(_vm.errorMessages.join(", ")) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }