<template>
  <v-app>
    <v-container fluid :style="`height: calc(${containerH}); padding: 0`">
      <Nuxt />
    </v-container>

    <client-only>
      <g-card-cookie-consent />
    </client-only>

    <v-footer
      v-if="footer"
      padless
      class="elevation-2"
      style="z-index: 1"
    >
      <v-card
        flat
        tile
        width="100%"
        :height="footerH"
        class="text-center py-2 grey lighten-3"
      >
        <v-img
          :height="footerH - 16"
          src="/footer.png"
          alt="footer"
          contain
        />
      </v-card>
    </v-footer>
  </v-app>
</template>
<script>
import { useContext } from '@nuxtjs/composition-api';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup () {
    const { $config } = useContext();

    const { PLATFORM_USE_FOOTER } = $config;

    const footer = !!PLATFORM_USE_FOOTER;

    const footerH = 68;
    const containerH = footer ? '100% - 60px' : '100%';

    return {
      footer,
      footerH,
      containerH
    };
  }
});
</script>
