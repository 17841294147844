var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "transparent--background",
      attrs: { color: "transparent", flat: "" },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "pt-2 d-flex justify-center" },
        [
          _c(
            "v-list",
            {
              staticClass: "font-weight-medium pa-0 transparent",
              attrs: { dense: "" },
            },
            [
              _vm._l(_vm.items, function (item, i) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      key: i,
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b(
                                      { staticClass: "text--secondary" },
                                      "v-list-item",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-6",
                                        attrs: { color: "primary", left: "" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(item.icon) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !item.component
                                      ? [
                                          !item.link
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(item.value) +
                                                    "\n                "
                                                ),
                                              ])
                                            : _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "text-decoration-underline",
                                                  attrs: {
                                                    href: item.link,
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(item.value) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                        ]
                                      : _c(
                                          item.component,
                                          _vm._b(
                                            {
                                              tag: "component",
                                              staticClass: "text--secondary",
                                            },
                                            "component",
                                            item.params,
                                            false
                                          )
                                        ),
                                  ],
                                  2
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(item.tooltip) + "\n        "
                      ),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }