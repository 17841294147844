<template>
  <div>
    <!-- nest in a div to be able to inherit class and style properties -->
    <v-menu
      v-model="show"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :readonly="readonly"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          ref="inputDate"
          v-model="formatted"
          :label="label"
          :placeholder="placeholder"
          :clearable="clearable"
          type="date"
          outlined
          :error-messages="errorMessages"
          v-bind="attrs"
          v-on="on"
          @blur="onBlur"
          @focus="onFocus"
          @click:clear.stop.prevent="onClear"
          @change="onInput($event)"
          @click.stop.prevent
        />
      </template>
      <v-date-picker
        v-model="innerValue"
        :min="min"
        :max="max"
        no-title
        show-adjacent-months
        @input="onInput($event)"
      />
    </v-menu>
  </div>
</template>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useSync } from '@/composables/useSync';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },

    outlined: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },

    modelValue: {
      default: null
    },
    handler: {
      type: Function,
      default: () => []
    },

    placeholder: {
      type: String,
      default: ''
    },

    min: {
      type: String,
      default: null
    },

    max: {
      type: String,
      default: null
    },

    formatType: {
      type: String,
      default: null
    },

    readonly: {
      type: Boolean,
      default: false
    },

    errorMessages: {
      type: Array,
      default: () => []
    }
  },

  setup (props, { emit }) {
    const innerValue = useSync(props, 'value', emit);
    const innerModelValue = useSync(props, 'modelValue', emit);
    const innerErrorMessages = useSync(props, 'errorMessages', emit);

    const vModel = ref(null);
    const show = ref(false);
    const inputDate = ref(null);

    const formatted = computed({
      get () {
        return convertValueDate(innerValue.value);
      },
      set (newDate) {}
    });

    return {
      innerValue,
      innerModelValue,
      vModel,
      innerErrorMessages,

      formatted,
      show,
      inputDate,

      onInput,
      onBlur,
      onFocus,
      onClear,
      convertValueDate
    };

    function onInput (value) {
      innerValue.value = value;
      innerModelValue.value = value;

      emit('input', value);

      show.value = false;
    }

    function onBlur (value) {
      emit('blur', value);
    }

    function onFocus (value) {
      emit('focus', value);
    }

    function onClear () {
      innerValue.value = null;
      innerModelValue.value = null;

      emit('input', null);
    }

    function convertValueDate (value) {
      // check date
      if (!value) {
        return null;
      }

      const partsDate = value.split('-');
      const validSplitDate = partsDate.length === 3;

      if (validSplitDate) {
        const newDate = new Date(value);

        return newDate.toISOString().slice(0, 10);
      }
    }
  }

});
</script>
