<template>
  <v-progress-linear
    :value="item[field]"
    :color="color"
    :height="height"
    :rounded="rounded"
  />
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    field: {
      type: String,
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    color: {
      type: String,
      default: 'primary'
    },

    height: {
      type: String,
      default: '8'
    },

    rounded: {
      type: Boolean,
      default: true
    }
  }
});
</script>
