var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticStyle: { width: "100%" } },
    [
      _vm.not404
        ? _c("g-error-base", {
            attrs: {
              title: _vm.title,
              subtitle: _vm.subtitle,
              color: "error",
              icon: "mdi-alert-circle-outline",
              link: "",
              "link-text": _vm.linkText,
              "link-icon": "mdi-chevron-left",
            },
            on: { "click:link": _vm.onClickLink },
          })
        : _c("g-error-base", {
            attrs: {
              title: _vm.$t("page_404.oops"),
              subtitle: _vm.$t("page_404.page_not_found"),
              color: "primary",
              icon: "mdi-sign-direction-remove",
              "icon-size": "44",
              link: "",
              "link-text": _vm.linkText,
              "link-icon": "mdi-home-outline",
            },
            on: { "click:link": _vm.onClickLink },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }