import { ref } from '@vue/composition-api';

const show = ref(false);
const title = ref(null);

function getShow () { return show; }
function getTitle () { return title; }
function showLoading (text = null) {
  title.value = text;
  show.value = true;
}
function hideLoading () { show.value = false; }

export function useLoadingOverlay () {
  return {
    showLoading,
    hideLoading,

    getShow,
    getTitle
  };
}
