const types = {
  string: value => String(value),
  int: parseInt,
  float: parseFloat,
  bool: value => Boolean(value),
  date: value => Date(value)
};

export function findPKField (fields) {
  const primaryKey = [];

  for (const key in fields) {
    if (!types[key]) { continue; }

    if (fields[key].primaryKey) { primaryKey.push(key); }
  }
  return primaryKey;
}

export function parseFields (fields) {
  const map = {};

  for (const key in fields) {
    const field = fields[key];

    const converter = types[field.type];

    if (!converter) { continue; }

    map[key] = field;
    map[key].cv = converter;
  }

  return map;
}

export function hydrate (rows, fields) {
  console.warn('[Pinia] TODO: Hydrate fields');
  return rows.map((row) => {
    const hydrated = {};

    try {
      for (const key in fields) {
        let value = row[key];

        if (!value) {
          // TODO: fallback to default
          value = null;
        }

        hydrated[key] = value;// definition.cv(row[key]);
      }
    } catch (e) {
      console.error(e);
    }

    return hydrated;
  });
}

export function createNew (fields) {
  const record = {};

  for (const key in fields) {
    record[key] = null;
  }

  return record;
}
