<template>
  <v-card flat>
    <template v-if="text">
      <v-card-text class="pt-0">
        {{ text }}
      </v-card-text>
    </template>

    <template
      v-else-if="record && tpl"
    >
      <component :is="tpl" :record="record" class="text--primary" />
    </template>

    <v-card-text class="pt-0 text--primary">
      {{ $t('general.please_insert_code_bellow_to_confirm') }}.
    </v-card-text>

    <div class="d-flex justify-center my-2">
      <div style="max-width: 200px;">
        <g-form-base :valid.sync="innerValid" :dirty.sync="innerDirty" :fields="fields" :record="otpRecord" />

        <div class="d-flex justify-center my-4">
          <div v-if="innerValid && innerDirty" class="success--text">
            <v-icon left large color="success">
              mdi-check-circle-outline
            </v-icon>

            <span>
              {{ $t('general.confirmed') }}
            </span>
          </div>

          <div v-if="!innerValid && innerDirty" class="error--text">
            <v-icon left large color="error">
              mdi-close-circle-outline
            </v-icon>
            <span>
              {{ $t('general.code_does_not_match') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import { computed, defineComponent, ref, toRef } from '@vue/composition-api';
import { useSync } from '@/composables/useSync';

export default defineComponent({
  props: {
    text: {
      type: String,
      default: ''
    },

    record: {
      type: Object,
      default: () => { return {}; }
    },

    tpl: {
      type: Object,
      default: () => { return {}; }
    },

    length: {
      type: Number,
      default: 3
    },

    valid: {
      type: Boolean,
      default: false
    },

    dirty: {
      type: Boolean,
      default: false
    }
  },

  setup (props, { emit }) {
    const innerValid = useSync(props, 'valid', emit);
    const innerDirty = useSync(props, 'dirty', emit);

    const length = toRef(props, 'length');

    const fields = computed(() => [
      {
        component: 'v-otp-input',
        params: {
          length: length.value,
          plain: true,
          dense: true,
          readonly: true
        },
        field: 'otp'
      },
      {
        component: 'v-otp-input',
        params: {
          length: length.value,
          type: 'number',
          'hide-spin-buttons': true,
          dense: true
        },
        field: 'confirmation',
        rules: 'required|matchwith:@0'
      }
    ]);

    const otpRecord = ref({
      otp: genNumber(length.value),
      confirmation: ''
    });

    return {
      innerValid,
      innerDirty,
      fields,
      otpRecord
    };

    function genNumber (len) {
      let value = '';

      while (value.length < len) {
        const r = Math.floor(Math.random() * 10) + 0;

        value += r;
      }

      return value;
    }
  }
});
</script>
