<template>
  <v-list>
    <template v-for="(item, i) in items">
      <v-list-item
        :key="`item-${i}`"
        :class="{
          'v-task-list-item': true,
          'v-task-list-item-disabled': !!item.disabled
        }"
        :three-line="!!item.tags"
        v-on="item.events"
      >
        <!-- <v-list-item-icon>
          <v-btn icon class="bg-shadow">
            <v-icon color="primary lighten-2">
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </v-list-item-icon> -->
        <v-list-item-content>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="item.subtext" :class="`${item.subtextColor}--text`">
            {{ item.subtext }}
          </v-list-item-subtitle>

          <v-list-item-subtitle v-if="item.tags">
            <v-tooltip v-for="(tag, t) in item.tags" :key="t" bottom>
              <template #activator="{ on, attrs }">
                <v-chip
                  outlined
                  small
                  class="bg-shadow mr-1 mt-2"
                  style="border: none !important;"
                  :color="tag.color"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-if="tag.icon" small left>
                    {{ tag.icon }}
                  </v-icon>
                  {{ tag.text }}
                </v-chip>
              </template>
              <span>{{ tag.tooltip }}</span>
            </v-tooltip>
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon v-if="!item.disabled" class="d-flex justify-center align-center">
          <v-icon :color="item.afterIconColor || 'primary'" right>
            {{ item.afterIcon }}
          </v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-divider
        :key="`divider-${i}`"
      />
    </template>
    <v-list />
  </v-list>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
});
</script>
<style>
.v-task-list-item .v-list-item__icon:last-child {
  align-self: center !important;
}

.v-task-list-item-disabled {
  cursor: not-allowed !important;
}
</style>
