var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-progress-linear", {
    attrs: {
      value: _vm.item[_vm.field],
      color: _vm.color,
      height: _vm.height,
      rounded: _vm.rounded,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }