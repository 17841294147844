<template>
  <g-content
    :entity="entity"
    :has-create="allowCreate"
    :has-update="allowUpdate"
    :has-delete="allowDelete"
    :disable-update="!tableSelection.length"
    :disable-delete="!tableSelection.length"
    @click:create="onClickCreateRow"
    @click:update="onClickUpdateRow"
    @click:delete="onClickDeleteRow"
  >
    <!-- Slot for custom header tools-->
    <template #header-tools="headerParams">
      <slot name="header-tools" v-bind="headerParams" />
    </template>

    <g-table-gallery
      :class="{
        'fullscreen-table': true,
        'row-cursor': allowInspect
      }"
      :show-select="allowUpdate || allowDelete"
      :headers="tableState.headers"
      :rows="items"
      :total="total"
      :loading="loading"
      :error="error"
      :image-key="imageKey"
      :thumb-key="thumbKey"
      :options.sync="tableState.options"
      :selection.sync="tableSelection"
      :dense="tableState.dense"
      :multi-sort="tableState.multiSort"
      @click:row="onClickRow"
    />
  </g-content>
</template>

<script>
import {
  defineComponent,
  ref,
  toRaw,
  toRefs,
  watch
} from '@vue/composition-api';
import { usePresentationState } from '@/stores/system/presentation-state';
import { useI18n } from '@/composables/useI18n';
import { useSync } from '@/composables/useSync';
import { useModalForm } from '@/composables/useModalForm';

export default defineComponent({
  props: {
    entity: {
      type: String,
      required: true
    },

    caller: {
      type: String,
      required: true
    },

    headers: {
      type: Array,
      required: true
    },

    items: {
      type: Array,
      default: () => []
    },

    total: {
      type: Number,
      default: 0
    },

    loading: {
      type: Boolean,
      default: false
    },

    primaryKey: {
      type: String,
      default: 'id'
    },

    itemClass: {
      type: Function,
      default: () => null
    },

    imageKey: {
      type: String,
      default: null
    },

    thumbKey: {
      type: String,
      default: null
    },

    titleKey: {
      type: String,
      default: null
    },

    options: {
      type: Object,
      default: () => null
    },

    filters: {
      type: Array,
      default: () => null
    },

    selection: {
      type: Array,
      default: () => null
    },

    formTitle: {
      type: String,
      default: ''
    },

    formCreate: {
      type: Array,
      default: () => []
    },

    formCreateHandler: {
      type: Function,
      default: () => () => null
    },

    formUpdate: {
      type: Array,
      default: () => []
    },

    formUpdateHandler: {
      type: Function,
      default: () => () => null
    },

    formDeleteHandler: {
      type: Function,
      default: () => () => null
    },

    allowInspect: {
      type: Boolean,
      default: false
    },

    allowCreate: {
      type: Boolean,
      default: false
    },

    allowUpdate: {
      type: Boolean,
      default: false
    },

    allowDelete: {
      type: Boolean,
      default: false
    },

    error: {
      type: String,
      default: null
    }
  },

  emits: [
    'update:options',
    'update:filters',
    'update:selection',
    'update:headers',
    'refresh'
  ],

  setup (props, { emit }) {
    const i18n = useI18n();
    const stateStore = usePresentationState();
    const { openForm } = useModalForm();

    const caller = toRaw(props.caller);

    const {
      formTitle,
      formCreate,
      formCreateHandler,
      formUpdate,
      formUpdateHandler,
      formDeleteHandler,
      headers
    } = toRefs(props);

    stateStore.initState(caller, {
      type: 'gallery',
      fields: headers, // TODO: required for reactive filters
      headers: props.headers, // TODO: required to make to toggle column visibility
      filters: props.filters,
      options: props.options,
      dense: false,
      striped: false,
      multiSort: false
    });

    const tableState = ref(stateStore.activeState);
    const tableSelection = props.selection
      ? useSync(props, 'selection', emit)
      : ref([]);

    watch(() => tableState.value.headers, value => emit('update:headers', value), { deep: true });
    watch(() => tableState.value.options, value => emit('update:options', value), { immediate: true });
    watch(() => tableState.value.filters, filters => emit('update:filters', filters), { immediate: true });
    watch(() => tableState.value.options, () => emit('refresh'));
    watch(() => tableState.value.filters, () => { tableState.value.options.page = 1; emit('refresh'); });

    return {
      tableState,
      tableSelection,
      onClickRow,
      onClickCreateRow,
      onClickUpdateRow,
      onClickDeleteRow
    };

    function onClickRow (row) {
      emit('click:row', row);
    }

    function onClickCreateRow () {
      if (formCreate.value.length) {
        openForm(i18n.t('general.create') + ' ' + formTitle.value, { fields: formCreate, record: {} }, formCreateHandler.value);
      } else {
        emit('click:create');
      }
    }

    function onClickUpdateRow () {
      const record = tableSelection.value[0];

      if (formUpdate.value.length) {
        openForm(i18n.t('general.update') + ' ' + formTitle.value, { fields: formUpdate, record }, formUpdateHandler.value);
      } else {
        emit('click:update', record);
      }
    }

    function onClickDeleteRow () {
      const record = tableSelection.value[0];

      formDeleteHandler.value(record);
    }
  }
});
</script>
<style scoped>
.fullscreen-table {
  height: calc(100vh - 122px);
}

.fullscreen-table >>> .v-data-table__wrapper {
  height: calc(100% - 60px);
  overflow-y: scroll !important;
}
</style>
