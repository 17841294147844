import _ from 'lodash';
import { CREATE_ACTION, DELETE_ACTION, READ_ACTION, UPDATE_ACTION, GET_ACTION } from '../composables/use-stores';
import { parseFields, findPKField, createNew } from './fields';
import { create, read, update, del, createMulti, get } from './proxy';

// default state
export function state (collection, fields, relationships = [], dependencies = [], mutations = []) {
  return {
    loading: false,
    rows: [],
    total: 0,
    error: null,
    primaryKey: Object.freeze(findPKField(fields)),
    fields: Object.freeze(parseFields(fields)),
    relationships: Object.freeze(relationships),
    dependencies: Object.freeze(dependencies),
    mutations: Object.freeze(mutations),
    api: Object.freeze({
      get: `/api/${collection}`,
      put: `/api/${collection}`,
      patch: `/api/${collection}`,
      delete: `/api/${collection}`
    }),
    readState: '',
    lastQuery: null,
    autoRefresh: false,
    controller: null
  };
}

// default getters
export function getters () {
  return {
    allRows: state => state.rows,
    find (state) { return (field, value) => state.rows.find(row => row[field] === value); },
    first: state => state.rows.length ? state.rows[0] : null,
    last: state => state.rows.length ? state.rows[state.rows.length - 1] : null,
    count: state => state.rows.length
    // error: state => state.errorObj
  };
}

// util to generate all available actions
function generateActions () {
  return {
    pivot () {
      return createNew(this.fields);
    },

    async get () {
      this.loading = true;

      const { rows, total, error } = await get(this);

      this.error = error;
      this.rows = rows;
      this.total = total;

      this.loading = false;

      return {
        rows,
        total,
        error
      };
    },

    async read (params, force = false) {
      if (this.loading && this.controller) {
        this.controller.abort();
      }

      this.loading = true;

      const controller = new AbortController();

      this.controller = controller;

      const { rows, total, status, error, queryState, refresh } = await read(this, params, { force, signal: controller.signal });

      // if refresh and queried was not canceled
      if (refresh && status !== 0) {
        this.readState = queryState;
        this.lastQuery = _.cloneDeep(params);

        this.error = error;
        this.rows = rows;
        this.total = total;
      }

      // fixes a bug where loading flag is set to false
      // when queries are canceled
      if (status !== 0) {
        this.loading = false;
      }

      return {
        rows,
        total,
        error
      };
    },

    async create (values) {
      this.loading = true;

      const result = await create(this, values);

      this.error = result.error;

      this.loading = false;

      return result;
    },

    async createMulti (values, url) {
      this.loading = true;

      const result = await createMulti(this, values, url);

      this.error = result.error;

      this.loading = false;

      return result;
    },

    async update (values, where) {
      this.loading = true;

      const { data, error } = await update(this, values, where);

      this.error = error;

      this.loading = false;

      return { data, error };
    },

    async delete (where) {
      this.loading = true;

      const { data, error } = await del(this, where);

      this.error = error;

      this.loading = false;

      return { data, error };
    },

    async readProxy (params, force = true) {
      return await read(this, params, force);
    },

    async createProxy (values) {
      return await create(this, values);
    },

    async createMultiProxy (values, url) {
      return await createMulti(this, values, url);
    },

    async updateProxy (values, where) {
      return await update(this, values, where);
    },

    async deleteProxy (where) {
      return await del(this, where);
    },

    async refresh () {
      const params = this.lastQuery || {};

      return await this.read(params, true);
    },

    clean () {
      this.error = null;
      this.rows = [];
      this.total = 0;
    }
  };
}

// default actions
export function actions (allowed) {
  if (!allowed || !Array.isArray(allowed)) {
    return {};
  }

  const allActions = generateActions();

  const addedActions = {
    pivot: allActions.pivot,
    clean: allActions.clean,
    refresh: allActions.refresh
  };

  for (let i = 0; i < allowed.length; i++) {
    const action = allowed[i];

    switch (action) {
      case GET_ACTION:
        addedActions.get = allActions.get;
        break;
      case CREATE_ACTION:
        addedActions.create = allActions.create;
        addedActions.createMulti = allActions.createMulti;
        addedActions.createProxy = allActions.createProxy;
        addedActions.createMultiProxy = allActions.createMultiProxy;
        break;
      case READ_ACTION:
        addedActions.read = allActions.read;
        addedActions.readProxy = allActions.readProxy;
        break;
      case UPDATE_ACTION:
        addedActions.update = allActions.update;
        addedActions.updateProxy = allActions.updateProxy;
        break;
      case DELETE_ACTION:
        addedActions.delete = allActions.delete;
        addedActions.deleteProxy = allActions.deleteProxy;
        break;
      default:
        break;
    }
  }

  return addedActions;
}

export default {
  state,
  getters,
  actions
};
