var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-center pb-2" },
    [
      _c("h3", { staticClass: "font-weight-medium" }, [
        _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "", left: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var attrs = ref.attrs
                var on = ref.on
                var show = ref.value
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "px-1 mx-1",
                          staticStyle: { "text-transform": "none !important" },
                          attrs: { small: "", text: "" },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("h3", { staticClass: "primary--text" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.humanOperator) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-icon", { attrs: { color: "primary" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              show ? "mdi-chevron-up" : "mdi-chevron-down"
                            ) +
                            "\n        "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { dense: "" } },
            _vm._l(_vm.operators, function (op, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  on: {
                    click: function ($event) {
                      _vm.innerOperator = op.value
                    },
                  },
                },
                [_c("v-list-item-title", [_vm._v(" " + _vm._s(op.name))])],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "mx-2",
          attrs: { small: "", icon: "" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.onClickRemove.apply(null, arguments)
            },
          },
        },
        [_c("v-icon", [_vm._v("\n      mdi-close\n    ")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }