import { computed, isRef, ref, unref } from '@nuxtjs/composition-api';
import _ from 'lodash';
import { useArrayEquality } from './use-array-equality';

const component = ref(null);
const currentConfig = ref(null);
const show = ref(false);

export function useModalForm () {
  const { isEqualArr } = useArrayEquality();

  return {
    currentConfig,
    getShow,
    getRef,
    setRef,
    createOptions,
    createForm,
    openOptions,
    openForm,
    openMultistepForm,
    openTaskList,
    openOTP,
    openCustom,
    closeForm
  };

  function createOptions (options) {
    return computed(() => options.map((option) => {
      const { title, icon, handler } = option;

      return {
        text: title,
        icon,
        events: {
          click: () => handler()
        }
      };
    }));
  }

  function createForm (fields) {
    return computed(() => fields.map((option) => {
      const { title, icon, handler } = option;

      return {
        text: title,
        icon,
        events: {
          click: () => handler()
        }
      };
    }));
  }

  function openOptions (title, items) {
    currentConfig.value = {
      component: 'g-card-list',
      caller: 'caller',
      title,
      params: {
        items
      }
    };
  }

  function openTaskList (title, items) {
    currentConfig.value = {
      component: 'g-card-task-list',
      caller: 'caller',
      title,
      params: {
        items
      }
    };
  }

  function openForm (title, params, handler) {
    currentConfig.value = {
      component: 'g-form-base',
      caller: 'caller',
      title,
      params,
      handler
    };

    if (params && params.record &&
      Object.keys(params.record).length !== 0) {
      const initialVal = setInterval(() => {
        if (getRef().value) {
          getRef().value.validate();
          clearInterval(initialVal);
        }
      }, 1000);
    }
  }

  function openMultistepForm (title, params, handler = () => { }) {
    const inputRecord = params.record || {};
    const forms = params.forms || [];

    let step = 0;
    const path = [];
    const dirtyness = [];
    const allInput = {};
    const allOutput = {};

    // If not ref, ref it
    const record = !isRef(inputRecord) ? ref(inputRecord) : inputRecord;
    const pristine = _.cloneDeep(unref(record));

    // Start with step 1
    showStep(forms[0], forms.length > 1);

    // Iterate every visible field and set dirty=true where
    // pristine[field] !== record[field]. This is required
    // because each step of the multistep form has an isolated
    // dirtiness state
    function caculateDirtyness (context) {
      setTimeout(() => {
        // Since timeout will execute the callback
        // on the next frame request, component.value
        // might be null if we are exiting the modal form
        if (!component.value) {
          return;
        }

        // if the form has changes, we validate it
        let hasChanges = false;

        context.fields.value.forEach((item, i) => {
          let isEqual;

          if (
            Array.isArray(pristine[item.field]) &&
            Array.isArray(record.value[item.field])
          ) {
            // diff between arrays
            isEqual = isEqualArr(pristine[item.field], record.value[item.field]);
          } else {
            isEqual = pristine[item.field] === record.value[item.field];
          }

          component.value.setChanged(i, !isEqual);

          if (!isEqual) {
            hasChanges = true;
          }
        });

        component.value.validate({ silent: !hasChanges });
      }, 0);
    }

    // Manages all steps of the multiform
    // Hacks the previous and next button to
    // presever the state of the input record
    function showStep (context) {
      path.push(step);
      dirtyness.push(false);

      const finalStep = context.next && typeof context.next === 'string' && context.next === 'final';

      // on previous, go back 1 step
      const prevHandler = () => {
        // step--;
        path.pop();
        dirtyness.pop();

        step = path.length ? path[path.length - 1] : 0;

        caculateDirtyness(forms[step]);
      };

      // on next, update record with changes
      // and show next step
      const nextHandler = ({ input, output, dirty }) => {
        allInput[step] = input;
        allOutput[step] = output;
        dirtyness[step] = dirty;

        record.value = {
          ...record.value,
          ...output
        };

        if (context.next) {
          const nextId = context.next({}, record.value, step);

          step = forms.findIndex(f => f.id === nextId);
        } else {
          step++;
        }

        showStep(forms[step]);

        caculateDirtyness(forms[step]);

        return null;
      };

      // on finish, gather all changes
      // and call handler
      const callback = async ({ input, output, dirty }) => {
        allInput[step] = input;
        allOutput[step] = output;

        let resInput = {};

        for (const key in allInput) {
          resInput = {
            ...resInput,
            ...allInput[key]
          };
        }

        let resOutput = {};

        for (const key in allOutput) {
          resOutput = {
            ...resOutput,
            ...allOutput[key]
          };
        }

        return await handler({ input: resInput, output: resOutput });
      };

      // update current view
      currentConfig.value = {
        component: 'g-form-base',
        caller: 'caller',
        title: context.title,
        subtitle: title,
        multistep: true,
        steps: forms.length,
        finalStep,
        params: {
          fields: context.fields,
          record
        },
        dirtyOverride: dirtyness.findIndex(d => d === true) > -1,
        prevHandler: step === 0 ? null : prevHandler,
        nextHandler,
        handler: callback
      };
    }
  }

  function openOTP (title, params, handler) {
    currentConfig.value = {
      component: 'g-card-otp-confirmation',
      caller: 'caller',
      title,
      params,
      handler,
      hasDelete: true,
      hasForm: true
    };
  }

  function openCustom (component, title, params, handler, opts = { hasSave: true, hasDelete: false }) {
    currentConfig.value = {
      component,
      caller: 'caller',
      title,
      params,
      handler,
      hasSave: !!opts.hasSave,
      hasDelete: !!opts.hasDelete
    };
  }

  function closeForm () {
    show.value = false;
  }

  function setRef (c) {
    component.value = c;
  }

  function getRef () {
    return component;
  }

  function getShow () {
    return show;
  }
}

export default useModalForm;
