<template>
  <v-bottom-sheet
    v-model="show"
    inset
    persistent
  >
    <v-card
      rounded
    >
      <v-card-text class="d-flex align-center pa-4">
        {{ text }}

        <v-spacer />

        <v-btn color="success" @click="onClickAccept">
          {{ $t('general.confirm') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api';
import { useI18n } from '@/composables/useI18n';
import { useCookies } from '@/composables/use-cookies';

export default defineComponent({
  setup () {
    const i18n = useI18n();
    const { getCookie, addCookie } = useCookies();

    const text = i18n.t('general.cookie_consent_body');

    const cookieKey = 'cookie_consent';
    const show = ref(false);

    show.value = !getCookie(cookieKey);

    return {
      text,
      show,

      onClickAccept
    };

    function onClickAccept () {
      addCookie(cookieKey, true, 30);

      show.value = false;
    }
  }
});
</script>
