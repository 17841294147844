var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "v-timeline-header" },
      [
        _c("v-divider"),
        _vm._v(" "),
        _c(
          "v-toolbar",
          { attrs: { flat: "", dense: "" } },
          [
            _c("v-spacer"),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: { elevation: "0", text: "" },
                on: { click: _vm.onSort },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.isDesc
                        ? _vm.$t("general.descending")
                        : _vm.$t("general.ascending")
                    ) +
                    "\n        "
                ),
                _c("v-icon", { attrs: { right: "" } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.isDesc
                          ? "mdi-sort-ascending"
                          : "mdi-sort-descending"
                      ) +
                      "\n        "
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("v-divider"),
        _vm._v(" "),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-linear", { attrs: { indeterminate: "" } }),
                _vm._v(" "),
                !_vm.total
                  ? _c("div", { staticClass: "my-2 text--secondary" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.loading_items")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.loading && !_vm.total
          ? _c("div", { staticClass: "text-center" }, [
              _c("div", { staticClass: "my-2 text--secondary" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("general.no_data_available")) +
                    "\n      "
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "v-timeline-body px-6" },
      [
        _vm.total > 0
          ? _c(
              "v-timeline",
              { attrs: { dense: !_vm.dense } },
              _vm._l(_vm.rows, function (item, i) {
                return _c(
                  "v-timeline-item",
                  {
                    key: i,
                    staticClass: "mb-4",
                    attrs: {
                      color: _vm.getItemColor(item),
                      "icon-color": "white",
                      icon: _vm.getItemIcon(item),
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "opposite",
                          fn: function () {
                            return [
                              _c("span", { staticClass: "text--secondary" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.timeKey
                                        ? _vm.$d(new Date(item[_vm.timeKey]))
                                        : null
                                    ) +
                                    "\n          "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "v-card",
                      {
                        attrs: { color: _vm.getItemColor(item), outlined: "" },
                      },
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "text-h6 px-8 white--text" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.titleKey ? item[_vm.titleKey] : null
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-card-text",
                          { staticClass: "white text--primary" },
                          [
                            _c(
                              "v-list",
                              { attrs: { dense: "" } },
                              _vm._l(_vm.iHeaders, function (field, index) {
                                return _c(
                                  "v-list-item",
                                  { key: index },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "align-end" },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(field.text) +
                                              "\n                  "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-subtitle",
                                          [
                                            !field.renderer
                                              ? [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        item[field.value]
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              : _c(
                                                  field.renderer.component,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          item: item,
                                                          field:
                                                            field.renderer
                                                              .value,
                                                        },
                                                      },
                                                      "component",
                                                      field.renderer.params,
                                                      false
                                                    ),
                                                    field.renderer.events
                                                  )
                                                ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _vm._t("after-body", null, { item: item }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            )
          : _c(
              "div",
              { staticClass: "pa-4", staticStyle: { opacity: "0.8" } },
              [
                _c("v-icon", { attrs: { left: "", color: "error" } }, [
                  _vm._v("\n        mdi-alert-circle-outline\n      "),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "error--text" }, [
                  _vm._v("\n        " + _vm._s(_vm.error) + "\n      "),
                ]),
              ],
              1
            ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "v-timeline-footer white" },
      [
        _c("v-divider"),
        _vm._v(" "),
        _c("v-data-footer", {
          attrs: {
            options: _vm.iOptions,
            pagination: _vm.pagination,
            "items-per-page-options": [10, 25, 50],
          },
          on: {
            "update:options": function ($event) {
              _vm.iOptions = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }