<template>
  <v-card color="transparent" flat class="transparent--background">
    <v-card-text class="pt-2 d-flex justify-center">
      <v-list dense class="font-weight-medium pa-0 transparent">
        <template v-for="(item, i) in items">
          <v-tooltip :key="i" right>
            <template #activator="{ on, attrs }">
              <v-list-item
                class="text--secondary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary" left class="mr-6">
                  {{ item.icon }}
                </v-icon>

                <template v-if="!item.component">
                  <span v-if="!item.link">
                    {{ item.value }}
                  </span>

                  <a v-else :href="item.link" class="text-decoration-underline" target="_blank">
                    {{ item.value }}
                  </a>
                </template>

                <component
                  :is="item.component"
                  v-else
                  v-bind="item.params"
                  class="text--secondary"
                />
              </v-list-item>
            </template>
            {{ item.tooltip }}
          </v-tooltip>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
});
</script>
<style scoped>
.v-list-item--dense, .v-list--dense .v-list-item {
  min-height: 36px !important;
}
</style>
