<template>
  <v-stepper
    v-model="step"
    vertical
    flat
  >
    <template v-for="(form, i) in forms ">
      <v-stepper-step
        :key="`setp-${i}`"
        :complete="step > i + 1"
        :step="i + 1"
      >
        {{ form.title }}

        <small v-if="form.summary"> {{ form.summary }}</small>
      </v-stepper-step>

      <v-stepper-content :key="`content-${i}`" :step="i + 1">
        <g-form-base
          ref="formsRef"
          class="mb-12 mt-1"
          :valid.sync="valid[i]"
          :fields="form.fields"
        />

        <v-btn
          v-if="i < forms.length - 1"
          :disabled="!valid[i]"
          color="primary"
          @click="onClickNext(i)"
        >
          {{ $t('general.continue') }}
        </v-btn>

        <v-btn
          v-else
          :disabled="!valid[i]"
          color="primary"
          @click="onClickSubmit(i)"
        >
          {{ $t('general.submit') }}
        </v-btn>

        <v-btn v-if="i !== 0" text @click="onClickPrevious(i)">
          {{ $t('general.previous') }}
        </v-btn>
      </v-stepper-content>
    </template>
  </v-stepper>
</template>

<script>
import { defineComponent, ref, toRefs } from '@vue/composition-api';

export default defineComponent({
  props: {
    forms: {
      type: Array,
      required: true
    },

    record: {
      type: Object,
      required: true
    }
  },

  setup (props, { emit }) {
    const { forms, record } = toRefs(props);

    const formsRef = ref();
    const valid = ref([]);
    const step = ref(1);

    return {
      formsRef,
      valid,
      step,

      onClickPrevious,
      onClickNext,
      onClickSubmit
    };

    function onClickPrevious (i) {
      const { property } = forms.value[i];

      record.value[property] = null;

      step.value -= 1;
    }

    function onClickNext (i) {
      const form = formsRef.value[i];

      const values = form.submit(false);

      let { property } = forms.value[i];

      if (!property) {
        property = i;
      }

      record.value[property] = values;

      step.value = step.value + 1;
    }

    function onClickSubmit (i) {
      const form = formsRef.value[i];

      const values = form.submit(false);

      let { property } = forms.value[i];

      if (!property) {
        property = i;
      }

      record.value[property] = values;

      emit('submit', record.value);
    }
  }
});
</script>
