<template>
  <v-bottom-navigation v-if="mobile" absolute color="primary">
    <v-btn @click="onOpenNavigation">
      <span>{{ $t("general.menu") }}</span>

      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <!-- <v-btn>
      <span>{{ $t("general.favourite") }}</span>

      <v-icon>mdi-cards-heart-outline</v-icon>
    </v-btn> -->

    <v-btn @click="onOpenTools">
      <span>{{ $t("general.tools") }}</span>

      <v-icon>mdi-wrench</v-icon>
    </v-btn>

    <v-btn to="/user/profile">
      <span>{{ $t("general.profile") }}</span>

      <v-icon>mdi-account</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
import { useContext } from '@nuxtjs/composition-api';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  setup () {
    const { $eventBus, $vuetify } = useContext();

    const mobile = computed(() => $vuetify.breakpoint.mobile);

    return {
      mobile,

      onOpenNavigation,
      onOpenTools
    };

    function onOpenNavigation () {
      $eventBus.$emit('open:navigation');
    }

    function onOpenTools () {
      $eventBus.$emit('open:tools');
    }
  }
});
</script>
