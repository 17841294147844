var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "elevation-0",
      attrs: {
        value: _vm.open,
        "mini-variant": "",
        color: !_vm.dark ? "primary" : "",
        dark: "",
        "mini-variant-width": _vm.miniSize,
        "mobile-breakpoint": 0,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "prepend",
            fn: function () {
              return [
                _c(
                  "v-sheet",
                  {
                    staticClass: "d-flex justify-center align-center",
                    attrs: { color: "", height: _vm.miniSize },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticStyle: { height: "100%" },
                        attrs: {
                          "x-large": "",
                          elevation: "0",
                          link: "",
                          to: "/",
                        },
                        on: { click: _vm.onChangeHome },
                      },
                      [
                        _c(
                          "v-avatar",
                          { attrs: { tile: "", size: "52" } },
                          [
                            _c("v-img", {
                              attrs: { src: "/favicon.ico", alt: "home" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          !_vm.mobile
            ? {
                key: "append",
                fn: function () {
                  return [
                    _c("v-divider", {}),
                    _vm._v(" "),
                    !!_vm.navUser
                      ? _c(
                          "div",
                          { attrs: { flat: "", height: "60" } },
                          [
                            _c(
                              "v-speed-dial",
                              {
                                attrs: {
                                  direction: "top",
                                  "open-on-hover": "",
                                  transition: "slide-y-reverse-transition",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function () {
                                        return [
                                          _c(
                                            "v-list",
                                            { staticClass: "pa-0" },
                                            [
                                              _c(
                                                "v-list-item-group",
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      attrs: {
                                                        to: "/user/profile",
                                                        height: _vm.miniSize,
                                                        link: "",
                                                        "no-action": "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.onChangeNavUser,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        { staticClass: "my-2" },
                                                        [
                                                          _c("v-img", {
                                                            attrs: {
                                                              src: "/api/user/profile-image",
                                                              alt: "John",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "placeholder",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-account"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              1925218037
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-list-item-content"),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3572619392
                                ),
                                model: {
                                  value: _vm.show,
                                  callback: function ($$v) {
                                    _vm.show = $$v
                                  },
                                  expression: "show",
                                },
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: { click: _vm.onLogout },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-logout")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        { attrs: { width: _vm.miniSize } },
        [
          _c(
            "v-list-item-group",
            {
              on: { change: _vm.onChangeNav },
              model: {
                value: _vm.selectedIdx,
                callback: function ($$v) {
                  _vm.selectedIdx = $$v
                },
                expression: "selectedIdx",
              },
            },
            _vm._l(_vm.navTree, function (item, index) {
              return _c(
                "v-tooltip",
                {
                  key: item.route + index,
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-list-item",
                              _vm._g(
                                _vm._b(
                                  {
                                    key: index + item.route,
                                    attrs: {
                                      value: item.route,
                                      link: "",
                                      "no-action": "",
                                    },
                                  },
                                  "v-list-item",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.icon) +
                                          "\n              "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-list-item-content"),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_vm._v(" "), _c("span", [_vm._v(_vm._s(item.title))])]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }