var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        {
          staticClass: "g-expansion-panels",
          attrs: { accordion: "", flat: "" },
          model: {
            value: _vm.expanded,
            callback: function ($$v) {
              _vm.expanded = $$v
            },
            expression: "expanded",
          },
        },
        _vm._l(_vm.rows, function (item, i) {
          return _c("v-hover", {
            key: i,
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var hover = ref.hover
                    return [
                      _c(
                        "v-expansion-panel",
                        {
                          class: {
                            "field-error": !_vm.validRows[i],
                            "field-hover": hover,
                          },
                          attrs: { flat: "" },
                          on: {
                            change: function ($event) {
                              return _vm.onChange(i)
                            },
                          },
                        },
                        [
                          _c("v-expansion-panel-header", {
                            attrs: { "hide-actions": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var open = ref.open
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-2" },
                                            [
                                              open
                                                ? [
                                                    _vm._v(
                                                      "\n                  mdi-chevron-up\n                "
                                                    ),
                                                  ]
                                                : [
                                                    _vm._v(
                                                      "\n                  mdi-chevron-down\n                "
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          !_vm.validRows[i]
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color:
                                                      "var(--v-error-base)",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        color: "error",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-alert-outline"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "general.field_contains_errors"
                                                        )
                                                      ) +
                                                      "\n              "
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c("span", [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.templateFn(item)
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                icon: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.rows.splice(i, 1)
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-delete-outline"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _vm._v(" "),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c("g-form-base", {
                                ref: "forms",
                                refInFor: true,
                                staticClass: "my-4",
                                attrs: {
                                  dense: "",
                                  fields: _vm.fields,
                                  record: _vm.rows[i],
                                  valid: _vm.validRows[i],
                                  "persist-validation": true,
                                },
                                on: {
                                  "update:valid": function ($event) {
                                    return _vm.$set(_vm.validRows, i, $event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-end" },
        [
          _c(
            "v-btn",
            {
              staticClass: "my-2",
              attrs: { color: "primary", text: "" },
              on: { click: _vm.onAdd },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("\n        mdi-plus\n      "),
              ]),
              _vm._v(" " + _vm._s(_vm.$t("general.add_item")) + "\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }