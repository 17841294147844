var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g-context-card", {
    attrs: { title: _vm.$t("general.user_groups") },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "py-2" },
              [
                _c("v-select", {
                  attrs: {
                    outlined: "",
                    label: _vm.$t("general.selected_group"),
                    "hide-details": "",
                    "menu-props": {
                      transition: "slide-y-transition",
                      "offset-y": true,
                    },
                    items: _vm.memberships,
                    "item-text": "Membership.name",
                    "item-value": "membership_id",
                    loading: _vm.loading,
                  },
                  on: { change: _vm.onGroupChange },
                  model: {
                    value: _vm.currentMembership,
                    callback: function ($$v) {
                      _vm.currentMembership = $$v
                    },
                    expression: "currentMembership",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "pa-3" }, [
                  _c("span", { staticClass: "subtitle-1" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.roles_for_selected_group")) +
                        ":\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "my-2" },
                    _vm._l(_vm.roles, function (p, i) {
                      return _c(
                        "v-chip",
                        {
                          key: i,
                          staticClass: "bg-shadow mr-2",
                          staticStyle: { border: "none" },
                          attrs: { outlined: "", color: "secondary" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(p["Role.name"]) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }