var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "scrollable-content",
      attrs: { flat: "" },
      on: { scroll: _vm.onScroll },
    },
    [
      _c(
        "v-toolbar",
        { staticClass: "sticky-toolbar grey lighten-5", attrs: { flat: "" } },
        [
          _c("v-toolbar-title", [
            _c("span", { staticClass: "text-h5" }, [_vm._v(_vm._s(_vm.title))]),
          ]),
          _vm._v(" "),
          _c("v-spacer"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-fade-transition",
        [
          _vm.scrolled
            ? _c("v-divider", { staticClass: "sticky-divider" })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-card-text", { staticClass: "py-0" }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }