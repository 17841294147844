var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _vm.text
        ? [
            _c("v-card-text", { staticClass: "pt-0" }, [
              _vm._v("\n      " + _vm._s(_vm.text) + "\n    "),
            ]),
          ]
        : _vm.record && _vm.tpl
        ? [
            _c(_vm.tpl, {
              tag: "component",
              staticClass: "text--primary",
              attrs: { record: _vm.record },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("v-card-text", { staticClass: "pt-0 text--primary" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("general.please_insert_code_bellow_to_confirm")) +
            ".\n  "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-center my-2" }, [
        _c(
          "div",
          { staticStyle: { "max-width": "200px" } },
          [
            _c("g-form-base", {
              attrs: {
                valid: _vm.innerValid,
                dirty: _vm.innerDirty,
                fields: _vm.fields,
                record: _vm.otpRecord,
              },
              on: {
                "update:valid": function ($event) {
                  _vm.innerValid = $event
                },
                "update:dirty": function ($event) {
                  _vm.innerDirty = $event
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-center my-4" }, [
              _vm.innerValid && _vm.innerDirty
                ? _c(
                    "div",
                    { staticClass: "success--text" },
                    [
                      _c(
                        "v-icon",
                        { attrs: { left: "", large: "", color: "success" } },
                        [
                          _vm._v(
                            "\n            mdi-check-circle-outline\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.confirmed")) +
                            "\n          "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.innerValid && _vm.innerDirty
                ? _c(
                    "div",
                    { staticClass: "error--text" },
                    [
                      _c(
                        "v-icon",
                        { attrs: { left: "", large: "", color: "error" } },
                        [
                          _vm._v(
                            "\n            mdi-close-circle-outline\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("general.code_does_not_match")) +
                            "\n          "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }