var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item-content",
    { staticStyle: { display: "block" }, on: { click: _vm.onClick } },
    [
      _c("v-list-item-title", [
        _vm._v("\n    " + _vm._s(_vm.item["Entity.name"]) + "\n  "),
      ]),
      _vm._v(" "),
      _c("v-list-item-subtitle", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("general.action")) +
            ": " +
            _vm._s(_vm.item.action) +
            " | " +
            _vm._s(_vm.$t("general.possession")) +
            ": " +
            _vm._s(_vm.item.possession) +
            "\n  "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }