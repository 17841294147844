var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-rating", {
    attrs: {
      color: _vm.color,
      "background-color": _vm.emptyColor,
      "full-icon": _vm.icon,
      "empty-icon": _vm.emptyIcon,
      length: _vm.length,
      value: _vm.value,
      size: "20",
      readonly: "",
      dense: "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }