<template>
  <v-btn
    class="bg-shadow"
    small
    :color="color"
    text
    rounded
    @click="onClick"
  >
    <v-icon v-if="icon" :left="!!text">
      {{ icon }}
    </v-icon>
    {{ text }}
  </v-btn>
</template>
<script>
import { defineComponent, toRef } from '@vue/composition-api';

export default defineComponent({
  props: {
    field: {
      type: String,
      default: null,
      required: false
    },

    item: {
      type: Object,
      required: true
    },

    text: {
      type: String,
      default: null
    },

    icon: {
      type: String,
      default: null
    },

    color: {
      type: String,
      default: 'primary'
    }
  },

  setup (props, { emit }) {
    const item = toRef(props, 'item');

    return {
      onClick
    };

    function onClick () {
      emit('click', item);
    }
  }
});
</script>
