var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        {
          style: "height: calc(" + _vm.containerH + "); padding: 0",
          attrs: { fluid: "" },
        },
        [_c("Nuxt")],
        1
      ),
      _vm._v(" "),
      _c("client-only", [_c("g-card-cookie-consent")], 1),
      _vm._v(" "),
      _vm.footer
        ? _c(
            "v-footer",
            {
              staticClass: "elevation-2",
              staticStyle: { "z-index": "1" },
              attrs: { padless: "" },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "text-center py-2 grey lighten-3",
                  attrs: {
                    flat: "",
                    tile: "",
                    width: "100%",
                    height: _vm.footerH,
                  },
                },
                [
                  _c("v-img", {
                    attrs: {
                      height: _vm.footerH - 16,
                      src: "/footer.png",
                      alt: "footer",
                      contain: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }