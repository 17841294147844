<template>
  <v-text-field
    v-model="innerModelValue"
    type="password"
    :value="innerValue"
    :label="label"
    :outlined="outlined"
    :prepend-inner-icon="prependInnerIcon"
    :error-messages="innerErrorMessages"
    loading
    @input="onInput"
    @blur="onBlur"
    @focus="onFocus"
  >
    <template #progress>
      <v-progress-linear
        :value="strength"
        :color="color"
        rounded
        absolute
        height="5"
      />
    </template>
  </v-text-field>
</template>
<script>
import { computed, defineComponent, toRef } from '@vue/composition-api';
import { useSync } from '@/composables/useSync';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },

    outlined: {
      type: Boolean,
      default: false
    },

    prependInnerIcon: {
      type: String,
      default: null
    },

    value: {
      default: null
    },

    modelValue: {
      default: null
    },

    minLength: {
      type: Number,
      default: 8
    },

    errorMessages: {
      type: Array,
      default: () => []
    }
  },

  setup (props, { emit }) {
    const minLength = toRef(props, 'minLength');

    const innerValue = useSync(props, 'value', emit);
    const innerModelValue = useSync(props, 'modelValue', emit);
    const innerErrorMessages = useSync(props, 'errorMessages', emit);

    const strength = computed(() => {
      const text = innerValue.value;

      let value = 0;

      if (text.match(/[a-z]/g)) { value += 25; }
      if (text.match(/[A-Z]/g)) { value += 25; }
      if (text.match(/[0-9]/g)) { value += 25; }
      if (text.length >= minLength.value) { value += 25; }

      return value;
    });

    const color = computed(() => {
      let value = '';

      switch (strength.value) {
        case 50:
        case 75:
          value = 'warning';
          break;
        case 100:
          value = 'success';
          break;
        case 0:
        case 25:
        default:
          value = 'error';
          break;
      }

      return value;
    });

    return {
      innerValue,
      innerModelValue,
      innerErrorMessages,

      color,
      strength,

      onInput,
      onBlur,
      onFocus
    };

    function onInput (value) {
      emit('input', value);
    }

    function onBlur (value) {
      emit('blur', value);
    }

    function onFocus (value) {
      emit('focus', value);
    }
  }

});
</script>
