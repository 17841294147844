var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    [
      _vm._l(_vm.items, function (item, i) {
        return [
          _c(
            "v-list-item",
            _vm._g(
              {
                key: "item-" + i,
                class: {
                  "v-task-list-item": true,
                  "v-task-list-item-disabled": !!item.disabled,
                },
                attrs: { "three-line": !!item.tags },
              },
              item.events
            ),
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [
                    _vm._v("\n          " + _vm._s(item.text) + "\n        "),
                  ]),
                  _vm._v(" "),
                  item.subtext
                    ? _c(
                        "v-list-item-subtitle",
                        { class: item.subtextColor + "--text" },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.subtext) + "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.tags
                    ? _c(
                        "v-list-item-subtitle",
                        _vm._l(item.tags, function (tag, t) {
                          return _c(
                            "v-tooltip",
                            {
                              key: t,
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "bg-shadow mr-1 mt-2",
                                                staticStyle: {
                                                  border: "none !important",
                                                },
                                                attrs: {
                                                  outlined: "",
                                                  small: "",
                                                  color: tag.color,
                                                },
                                              },
                                              "v-chip",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            tag.icon
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      left: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(tag.icon) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(tag.text) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(tag.tooltip))]),
                            ]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              !item.disabled
                ? _c(
                    "v-list-item-icon",
                    { staticClass: "d-flex justify-center align-center" },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: item.afterIconColor || "primary",
                            right: "",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.afterIcon) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { key: "divider-" + i }),
        ]
      }),
      _vm._v(" "),
      _c("v-list"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }