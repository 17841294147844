import { useSingleton, GET_ONLY } from '@/composables/use-stores';
import { req } from '@/stores/proxy';

const id = 'user-membership';
const route = 'user/active-membership';

export const useUserMembership = useSingleton(id, route, {
  actions: GET_ONLY,
  mutations: ['changeTo'],
  extend: {
    async changeTo (membership_id) {
      this.loading = true;

      const result = await req('PUT', 'user/active-membership', { query: { values: { membership_id } } });

      this.error = result.error;

      this.loading = false;

      return result;
    }
  }
});
