<template>
  <v-overlay
    v-model="show"
    absolute
    z-index="150"
  >
    <v-card light max-width="400px" min-width="200px">
      <v-card-title class="d-flex justify-center">
        {{ title || $t('general.loading') }}
      </v-card-title>
      <v-card-text>
        <v-progress-linear indeterminate color="primary" height="8" rounded />
      </v-card-text>
    </v-card>
  </v-overlay>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { useLoadingOverlay } from '@/composables/use-loading-overlay';

export default defineComponent({
  setup () {
    const { getShow, getTitle } = useLoadingOverlay();

    const show = getShow();
    const title = getTitle();

    return {
      title,
      show
    };
  }
});
</script>
