<template>
  <v-list dense class="transparent">
    <v-row dense>
      <v-col
        v-for="(item, i) in items"
        :key="i"
        cols="12"
        sm="12"
        md="12"
        :lg="item.cols || 12"
      >
        <v-list-item class="text--secondary d-flex align-start">
          <span class="font-weight-bold"> {{ item.text }}:&nbsp;</span>

          <!-- default to string -->
          <span v-if="!item.isList" class="text--secondary">
            {{ item.value }}
          </span>

          <!-- otherwise, try a property table -->
          <span v-else>
            <table style="list-style-type: none;">
              <template v-for="(row, j) in item.value">
                <tr :key="j">
                  <td
                    v-for="(dt, h) in row"
                    :key="h"
                    class="px-2"
                    :style="`${h !== 0 ? 'border-left: thin solid #ddd;' : ''}${j !== 0 ? 'border-top: thin solid #ddd;' : ''}`"
                  >
                    {{ dt }}
                  </td>
                </tr>
              </template>
            </table>

          </span>
        </v-list-item>
      </v-col>
    </v-row>
  </v-list>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },

  setup (props) {
    return {};
  }
});
</script>
<style scoped>
.v-list-item--dense, .v-list--dense .v-list-item {
  min-height: 36px !important;
}
</style>
