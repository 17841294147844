<template>
  <v-icon :color="color">
    {{ icon }}
  </v-icon>
</template>
<script>
import { computed, defineComponent, toRefs } from '@vue/composition-api';

export default defineComponent({
  props: {
    field: {
      type: String,
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    iconFn: {
      type: Function,
      default: () => ''
    },

    colorFn: {
      type: Function,
      default: () => 'primary'
    }
  },

  setup (props) {
    const { item, field, colorFn, iconFn } = toRefs(props);

    const innerValue = computed(() => item.value[field.value]);

    const icon = computed(() => iconFn.value(innerValue.value, item.value, field.value));
    const color = computed(() => colorFn.value(innerValue.value, item.value, field.value));

    return {
      icon,
      color
    };
  }
});
</script>
